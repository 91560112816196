import { useLocation } from 'react-router-dom';
import React, { useEffect, useState, useMemo } from 'react';
import Container from '@mui/material/Container';
import {
  Box, Card, CardContent, Checkbox, CircularProgress, FormControlLabel, Grid, Tab, Typography,
  TextField, Button,
} from '@mui/material';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import CustomHeader from '../../General/CustomHeader';
import Page from '../../Layout/Page';
import useStyles from '../styles';
import SoilMoistureChart from './SoilMoistureChart';
import useFetchApi from '../../../hooks/useFetchApi';
import {
  ConversationSchema,
  GetSectorSoilMoistureConfigResponse,
  GetSoilMoistureAvailableDepthsResponse,
  TimePointSchema,
} from '../../../requests/api/apiTypes';
import { FetchError } from '../../../views/types';
import apiClient from '../../../requests/api/apiClient';
import ConversationsTab from '../ConversationTab';
import { SOIL_MOISTURE_NAME } from '../../../constants/graphs';
import { ReduxState } from '../../../types';

interface Area {
  from: number;
  to: number;
  areaType: string;
  label: string;
  inputName: string;
  inputValue: number;
}

interface CriticalPoint {
  id: number;
  value: number;
  label: string;
  customLabel: string;
}

const SoilMoisture = () => {
  const classes = useStyles();
  const { search } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedFarm } = useSelector((state: ReduxState) => state.accountFarm);
  const selectedSector = useSelector((state: ReduxState) => state.currentSector.selectedSector);
  const [isLoading, setIsLoading] = useState(false);
  const queryParams = new URLSearchParams(search);

  // Check if we're coming from a notification and set default tab to conversations
  const source = queryParams.get('source');
  const initialTabValue = source === 'notification' ? '3' : '1';

  const [tabValue, setTabValue] = useState(initialTabValue);
  const [availableDepths, setAvailableDepths] = useState<string[]>([]);
  const [selectedLevels, setSelectedLevels] = useState<string[]>([]);
  const [modalState, setModalState] = useState<{
    open: boolean;
    conversationId: string;
    timePointData: TimePointSchema;
  }>({
    open: false,
    conversationId: '',
    timePointData: {
      hour: '',
      day: '',
      month: '',
      year: '',
    },
  });
  const [conversations, setConversations] = useState<ConversationSchema[]>([]);
  const [conversationsLoading, setConversationsLoading] = useState(false);
  const [areas, setAreas] = useState<Area[]>([]);
  const [maxValue, setMaxValue] = useState<number>(0);
  const [criticalPoints, setCriticalPoints] = useState<CriticalPoint[]>([
    {
      id: 1, value: 0, label: 'Punto Crítico 1', customLabel: 'Capacidad de Campo Sonda',
    },
    {
      id: 2, value: 0, label: 'Punto Crítico 2', customLabel: 'Alto Potencial Hídrico',
    },
    {
      id: 3, value: 0, label: 'Punto Crítico 3', customLabel: 'Bajo Potencial Hídrico',
    },
    {
      id: 4, value: 0, label: 'Punto Crítico 4', customLabel: 'Cambio de Potencial',
    },
  ]);
  const [minValue, setMinValue] = useState<number>(0);
  const [blueZoneLabel, setBlueZoneLabel] = useState('Full Point');
  const [redZoneLabel, setRedZoneLabel] = useState('Stress Point');

  const params = {
    farmId: queryParams.get('farmId') || '',
    sectorId: queryParams.get('sectorId') || '',
    wiseconnZoneId: queryParams.get('wiseconnZoneId') || '',
  };

  useEffect(() => {
    const loadConversations = async () => {
      setConversationsLoading(true);
      try {
        const { data } = await apiClient.conversation.conversationDetail(
          params.sectorId,
          SOIL_MOISTURE_NAME,
        );
        setConversations(data.conversations);
      } catch (err) {
        const { error } = err as FetchError;
        enqueueSnackbar(error.message, { variant: 'error' });
      } finally {
        setConversationsLoading(false);
      }
    };

    loadConversations();
  }, [params.farmId]);

  const {
    data: fetchedAvailableDepths,
    loading: availableDepthsLoading,
  } = useFetchApi<GetSoilMoistureAvailableDepthsResponse>({
    modelEndpoint: 'availableDepthsDetail',
    modelName: 'soilMoistureConfig',
    id: params.sectorId,
    dependencies: [params.sectorId],
  });

  const {
    data: soilMoistureConfig,
    loading: soilMoistureConfigLoading,
  } = useFetchApi<GetSectorSoilMoistureConfigResponse>({
    modelEndpoint: 'soilMoistureConfigDetail',
    modelName: 'soilMoistureConfig',
    id: params.sectorId,
    dependencies: [params.sectorId],
  });

  useEffect(() => {
    if (!availableDepthsLoading) {
      setAvailableDepths(fetchedAvailableDepths?.availableDepths || []);
    }
  }, [fetchedAvailableDepths, availableDepthsLoading]);

  useEffect(() => {
    if (!soilMoistureConfigLoading) {
      setSelectedLevels(soilMoistureConfig?.soilMoistureConfig?.levels || []);
      const safeAreas = (soilMoistureConfig?.soilMoistureConfig?.areas || []).map((area) => ({
        from: area.from ?? 0,
        to: area.to ?? 0,
        areaType: area.areaType ?? '',
        label: area.label ?? '',
        inputName: area.inputName ?? '',
        inputValue: area.inputValue ?? 0,
      }));
      setAreas(safeAreas);

      // Set min, max and critical points values from areas
      safeAreas.forEach((area) => {
        if (area.inputName === 'min') {
          setMinValue(area.inputValue);
          if (area.label) setRedZoneLabel(area.label);
        } else if (area.inputName === 'max') {
          setMaxValue(area.inputValue);
          if (area.label) setBlueZoneLabel(area.label);
        } else if (area.inputName.startsWith('pc_')) {
          const pointId = parseInt(area.inputName.split('_')[1], 10);
          setCriticalPoints((prevPoints) => prevPoints.map((point) => (
            point.id === pointId ? {
              ...point,
              value: area.inputValue,
              customLabel: area.label || point.customLabel,
            } : point
          )));
        }
      });
    }
  }, [soilMoistureConfig, soilMoistureConfigLoading]);

  useEffect(() => {
    if (!availableDepthsLoading || !soilMoistureConfigLoading) {
      setIsLoading(false);
    }
  }, [availableDepthsLoading, soilMoistureConfigLoading]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const handleLevelChange = async (newLevel: string) => {
    const newLevels = selectedLevels.includes(newLevel)
      ? selectedLevels.filter((level) => level !== newLevel)
      : [...selectedLevels, newLevel];

    setSelectedLevels(newLevels);

    try {
      const { data: response } = await apiClient.soilMoistureConfig.levelsCreate(
        params.sectorId,
        { levels: newLevels },
      );
      enqueueSnackbar(response.message, { variant: 'success' });
    } catch (err) {
      const { error } = err as FetchError;
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const validatePoints = (): boolean => {
    // Only validate that point 4 is between min and max
    const yellowPoint = criticalPoints.find((p) => p.id === 4);

    if (yellowPoint && yellowPoint.value !== 0) {
      if (minValue !== 0 && yellowPoint.value <= minValue) {
        enqueueSnackbar(`Error: ${yellowPoint.customLabel} (${yellowPoint.value}) debe ser mayor que Mínimo (${minValue})`, { variant: 'error' });
        return false;
      }
      if (maxValue !== 0 && yellowPoint.value >= maxValue) {
        enqueueSnackbar(`Error: ${yellowPoint.customLabel} (${yellowPoint.value}) debe ser menor que Máximo (${maxValue})`, { variant: 'error' });
        return false;
      }
    }

    return true;
  };

  const handleCriticalPointChange = (id: number, value: number) => {
    setCriticalPoints(criticalPoints.map((point) => (
      point.id === id ? { ...point, value } : point
    )));
  };

  const handleCustomLabelChange = (id: number, newLabel: string) => {
    setCriticalPoints(criticalPoints.map((point) => (
      point.id === id ? { ...point, customLabel: newLabel } : point
    )));
  };

  const updateAreas = async () => {
    if (!validatePoints()) {
      return;
    }

    const updatedAreas: Area[] = [];

    // Blue zone (max)
    if (maxValue > 0) {
      updatedAreas.push({
        from: maxValue,
        to: 1000,
        areaType: 'blue',
        label: blueZoneLabel,
        inputName: 'max',
        inputValue: maxValue,
      });
    }

    // Green zones and yellow zone (critical points)
    const greenPoints = criticalPoints.filter((point) => point.id !== 4);
    const yellowPoint = criticalPoints.find((point) => point.id === 4);

    // Add green zones
    greenPoints.forEach((point) => {
      if (point.value > 0) {
        updatedAreas.push({
          from: point.value - 1,
          to: point.value,
          areaType: 'green',
          label: point.customLabel,
          inputName: `pc_${point.id}`,
          inputValue: point.value,
        });
      }
    });

    // Red zone (min)
    if (minValue > 0) {
      updatedAreas.push({
        from: 0,
        to: minValue,
        areaType: 'red',
        label: redZoneLabel,
        inputName: 'min',
        inputValue: minValue,
      });
    }

    // Yellow zone
    if (yellowPoint && yellowPoint.value > 0) {
      updatedAreas.push({
        from: minValue,
        to: yellowPoint.value,
        areaType: 'yellow',
        label: yellowPoint.customLabel,
        inputName: `pc_${yellowPoint.id}`,
        inputValue: yellowPoint.value,
      });
    }

    try {
      const { data: response } = await apiClient.soilMoistureConfig.areasCreate(params.sectorId, {
        areas: updatedAreas,
      });
      setAreas(updatedAreas);
      enqueueSnackbar(response.message, { variant: 'success' });
    } catch (err) {
      const { error } = err as FetchError;
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const breadcrumb = useMemo(() => {
    const sectorName = selectedFarm?.sectors?.find(
      (sector) => sector.id === selectedSector?.id,
    )?.name || selectedSector?.name;

    return `${selectedFarm?.name}/${sectorName}/Configuración`;
  }, [selectedFarm, selectedSector]);

  if (isLoading) {
    return <CircularProgress />;
  }
  return (
    <Page
      className={classes.root}
      title='Humedad suelo'
    >
      <Container maxWidth={false}>
        <CustomHeader title={breadcrumb} />
        <Box mt={3}>
          <SoilMoistureChart
            chartView="settings"
            sectorId={params.sectorId}
            farmId={params.farmId}
            wiseconnZoneId={params.wiseconnZoneId}
            selectedDepths={selectedLevels}
            externalModalState={modalState}
            externalSetModalState={setModalState}
            externalConversations={conversations}
            externalSetConversations={setConversations}
            configuredAreas={areas}
          />
        </Box>
        <TabContext value={tabValue}>
          <Box sx={{
            borderBottom: 0,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
            <TabList onChange={handleTabChange} aria-label="sector tabs" className={classes.tabList}>
              <Tab label="Configuración" value="1" className={classes.tabLabel}/>
              <Tab label="Alertas" value="2" className={classes.tabLabel}/>
              <Tab label="Conversaciones" value="3" className={classes.tabLabel}/>
            </TabList>
          </Box>
          <TabPanel value="1" className={classes.configTabPanel}>
            <Grid container spacing={2} sx={{ width: '60%' }}>
              <Grid item xs={3}>
                <Card>
                  <CardContent>
                    <Box>
                      <Typography variant="h5" fontWeight="bold" gutterBottom>
                        Niveles a utilizar
                      </Typography>
                      {availableDepths.map((option) => (
                        <FormControlLabel
                          key={`${option}-checkbox`}
                          sx={{ display: 'block', mt: 1 }}
                          control={
                            <Checkbox
                              name={option}
                              checked={selectedLevels.includes(option)}
                              onChange={() => handleLevelChange(option)}
                            />
                          }
                          label={option}
                        />
                      ))}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={7}>
                <Card>
                  <CardContent>
                    <Box>
                      <Typography variant="h5" fontWeight="bold" gutterBottom mb={2}>
                        Configurar Líneas de Gestión
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12} container alignItems="center" spacing={2}>
                          <Grid item xs={4}>
                            <TextField
                              fullWidth
                              type="number"
                              label="Máximo"
                              value={maxValue || ''}
                              onChange={(e) => setMaxValue(Number(e.target.value))}
                              variant="standard"
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <TextField
                              fullWidth
                              label="Etiqueta"
                              value={blueZoneLabel}
                              onChange={(e) => setBlueZoneLabel(e.target.value)}
                              variant="standard"
                            />
                          </Grid>
                        </Grid>

                        {criticalPoints.map((point) => (
                          <Grid key={point.id} item xs={12} container alignItems="center" spacing={2}>
                            <Grid item xs={4}>
                              <TextField
                                fullWidth
                                type="number"
                                label={point.label}
                                value={point.value || ''}
                                onChange={(e) => handleCriticalPointChange(
                                  point.id,
                                  Number(e.target.value),
                                )}
                                variant="standard"
                              />
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                fullWidth
                                label="Etiqueta"
                                value={point.customLabel}
                                onChange={(e) => handleCustomLabelChange(point.id, e.target.value)}
                                variant="standard"
                              />
                            </Grid>
                          </Grid>
                        ))}

                        <Grid item xs={12} container alignItems="center" spacing={2}>
                          <Grid item xs={4}>
                            <TextField
                              fullWidth
                              type="number"
                              label="Mínimo"
                              value={minValue || ''}
                              onChange={(e) => setMinValue(Number(e.target.value))}
                              variant="standard"
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <TextField
                              fullWidth
                              label="Etiqueta"
                              value={redZoneLabel}
                              onChange={(e) => setRedZoneLabel(e.target.value)}
                              variant="standard"
                            />
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            fullWidth
                            onClick={updateAreas}
                          >
                            Actualizar Líneas de Gestión
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2" className={classes.configTabPanel}>
            In progress
          </TabPanel>
          <TabPanel value="3" className={classes.configTabPanel}>
            <ConversationsTab
              setModalState={setModalState}
              conversations={conversations}
              conversationsLoading={conversationsLoading}
            />
          </TabPanel>
        </TabContext>
      </Container>
    </Page>
  );
};
export default SoilMoisture;
