import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Divider,
  Card,
  Tabs,
  Tab,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ListLayout from '../../views/ClientDetail/Layout/component/ListLayout';
import { connectWebSocket, disconnectWebSocket } from '../../actions/webSocketActions';
import { ReduxState } from '../../types';
import useStyles from './styles';
import NotificationItem from './NotificationItem';
import apiClient from '../../requests/api/apiClient';
import { updateNotification, deleteNotification } from '../../actions/notificationActions';
import { setCurrentSector } from '../../actions/currentSectorActions';

const NotificationList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState(0);

  const account = useSelector((state: ReduxState) => state.account);
  const notifications = useSelector((state: ReduxState) => state.notifications.notifications);
  // Get wiseconnZoneId from Redux store at the component level
  // const { wiseconnZoneId } = useSelector((state: ReduxState) => state.farmSector);
  const selectedAccount = useSelector((state: ReduxState) => state.userAccount.selectedAccount);
  const { selectedFarm } = useSelector((state: ReduxState) => state.accountFarm);

  useEffect(() => {
    if (account.user?.id) {
      dispatch(connectWebSocket(account.user.id));
    }
    return () => {
      dispatch(disconnectWebSocket());
    };
  }, [account.user?.id, dispatch]);

  const handleMarkAsUnread = (id: string) => {
    apiClient.notifications.notificationsUpdate(id, { is_read: false });
    // Get existing notification data to include required fields
    const notification = notifications.find((n) => n.id === id);
    if (notification) {
      dispatch(updateNotification({
        ...notification,
        is_read: false,
        updatedAt: new Date().toISOString(),
      }));
    }
  };

  const handleMarkAsRead = (id: string) => {
    apiClient.notifications.notificationsUpdate(id, { is_read: true });
    // Get existing notification data to include required fields
    const notification = notifications.find((n) => n.id === id);
    if (notification) {
      dispatch(updateNotification({
        ...notification,
        is_read: true,
        updatedAt: new Date().toISOString(),
      }));
    }
  };

  const handleDeleteNotification = async (id: string) => {
    await apiClient.notifications.notificationsDelete(id);
    dispatch(deleteNotification(id));
  };

  const handleRedirectToChart = (id: string, path?: string, sectorId?: string, farmId?: string) => {
    const baseUrl = window.location.origin;

    if (farmId && sectorId) {
      // Find the farm in the user account's farms
      const farm = selectedAccount?.farms?.find((f) => f.id === farmId);

      if (farm) {
        // Find the sector in the farm
        const sector = farm.sectors?.find((s) => s.id === sectorId);

        if (sector) {
          dispatch(setCurrentSector(sector));
        }
      }
    }

    // Get the notification to access its conversationId and timePoint
    const notification = notifications.find((n) => n.id === id);
    let redirectUrl = `${baseUrl}${path}`;

    // If the notification has a conversationId with timePoint, add it to the URL
    if (typeof notification?.conversationId === 'object' && notification?.conversationId?.timePoint) {
      const { day, month, year } = notification.conversationId.timePoint;
      // Check if URL already has a date parameter
      if (!redirectUrl.includes('date=')) {
        redirectUrl += `&date=${day}-${month}-${year}`;
      }
    }

    // Append source=notification parameter to the URL
    redirectUrl += '&source=notification';

    // Mark notification as read
    handleMarkAsRead(id);

    window.location.href = redirectUrl;
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  // Filtrar notificaciones por el campo seleccionado
  const filteredNotifications = notifications.filter((notification) => {
    // Si no hay cuenta seleccionada, mostrar todas las notificaciones
    if (!selectedAccount) return true;

    // Si hay un campo seleccionado, mostrar solo las notificaciones de ese campo
    if (selectedFarm) {
      return notification.farm?.id === selectedFarm.id;
    }

    // Si no hay campo seleccionado pero hay cuenta, mostrar las notificaciones de la cuenta
    return notification.account === selectedAccount.id;
  });

  // Filtrar y ordenar notificaciones
  const unreadNotifications = filteredNotifications
    .filter((notification) => !notification.is_read)
    .sort((a, b) => new Date(b.updatedAt || '').getTime() - new Date(a.updatedAt || '').getTime());
  const readNotifications = filteredNotifications
    .filter((notification) => notification.is_read)
    .sort((a, b) => new Date(b.updatedAt || '').getTime() - new Date(a.updatedAt || '').getTime());
  const allNotifications = [...filteredNotifications]
    .sort((a, b) => new Date(b.createdAt || '').getTime() - new Date(a.createdAt || '').getTime());

  const getNotificationsForTab = () => {
    if (currentTab === 0) {
      return unreadNotifications;
    }
    if (currentTab === 1) {
      return readNotifications;
    }
    return allNotifications;
  };

  const getEmptyStateMessage = () => {
    if (currentTab === 0) {
      return 'No hay notificaciones sin leer';
    }
    if (currentTab === 1) {
      return 'No hay notificaciones leídas';
    }
    return 'No hay notificaciones';
  };

  const notificationsToShow = getNotificationsForTab();

  return (
    <Card className={classes.notificationContainer}>
      <ListLayout>
        <Typography variant="h3" color="primary">
          Notificaciones
        </Typography>
        <Divider sx={{ margin: '16px 0' }} />

        <Tabs value={currentTab} onChange={handleTabChange} sx={{ mb: 2 }}>
          <Tab label={`Nuevas (${unreadNotifications.length})`} className={classes.tab} />
          <Tab label="Leídas" className={classes.tab} />
          <Tab label="Todas" className={classes.tab} />
        </Tabs>

        {/* Render notifications based on current tab */}
        <Box className={classes.notificationContainer}>
          {notificationsToShow.map((notification, index) => (
            <NotificationItem
              key={index}
              notification={{
                id: notification.id?.toString() || '',
                title: notification.title || '',
                message: notification.message || '',
                createdAt: notification.createdAt || '',
                type: notification.type || '',
                sector: notification.sector ? {
                  name: typeof notification.sector === 'string' ? notification.sector : notification.sector.name || '',
                  wiseconnZoneId: typeof notification.sector === 'string' ? '' : notification.sector.wiseconnZoneId || '',
                  id: typeof notification.sector === 'string' ? undefined : notification.sector.id,
                } : undefined,
                farm: notification.farm ? {
                  name: notification.farm.name || '',
                  id: notification.farm.id || '',
                } : undefined,
                createdBy: notification.createdBy ? {
                  name: notification.createdBy.name || '',
                  lastname: notification.createdBy.lastname || '',
                } : undefined,
                highchartName: notification.highchartName || undefined,
                is_read: notification.is_read || false,
                path: notification.path || undefined,
                conversationId: notification.conversationId,
              }}
              handleMarkAsUnread={handleMarkAsUnread}
              handleMarkAsRead={handleMarkAsRead}
              handleDeleteNotification={handleDeleteNotification}
              handleRedirectToChart={handleRedirectToChart}
            />
          ))}
          {/* Show empty state message if no notifications */}
          {notificationsToShow.length === 0 && (
            <Typography variant="body1" sx={{ textAlign: 'center', py: 2 }}>
              {getEmptyStateMessage()}
            </Typography>
          )}
        </Box>
      </ListLayout>
    </Card>
  );
};

export default NotificationList;
