import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNotifications } from '../actions/notificationActions';
import useFetchApi from './useFetchApi';
import { GetNotificationsPerUserResponse } from '../requests/api/apiTypes';
import { ReduxState } from '../types';

const useFetchNotifications = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReduxState) => state.account);
  const userId = user?.id ?? '';

  const fetchResponse = useFetchApi<GetNotificationsPerUserResponse>({
    modelEndpoint: 'notificationsDetail',
    modelName: 'notifications',
    id: userId,
    dependencies: [userId],
  });

  useEffect(() => {
    if (fetchResponse?.data) {
      dispatch(setNotifications(fetchResponse.data.notifications ?? []));
    }
  }, [fetchResponse, dispatch]);

  return fetchResponse;
};

export default useFetchNotifications;
