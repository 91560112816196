import { openDB } from 'idb';

// Crear o abrir la base de datos
const dbPromise = openDB('ChartsDB', 1, {
  upgrade(db) {
    if (!db.objectStoreNames.contains('chartData')) {
      db.createObjectStore('chartData', { keyPath: 'id' });
    }
  },
});

// Limpiar datos antiguos para un gráfico específico
export const deleteOldChartData = async (chartName: string) => {
  const db = await dbPromise;
  const keys = await db.getAllKeys('chartData');
  const transactions = keys.map(async (key) => {
    const record = await db.get('chartData', key as string);
    if (record.chartName === chartName) {
      await db.delete('chartData', key as string);
    }
  });
  await Promise.all(transactions);
};

// Guardar datos en IndexDB (sobreescribiendo si ya existe)
export const saveChartData = async (
  chartName: string,
  startDate: string,
  endDate: string,
  sectorId: string,
  data: any,
) => {
  const cacheKey = `${chartName}_${startDate}_${endDate}_${sectorId}`;
  const db = await dbPromise;
  await deleteOldChartData(chartName);
  await db.put('chartData', {
    id: cacheKey,
    sectorId,
    data,
    chartName,
    startDate,
    endDate,
  });
};

// Obtener datos de IndexDB por cacheKey
export const getChartData = async (
  chartName: string,
  startDate: string,
  endDate: string,
  sectorId: string,
) => {
  const cacheKey = `${chartName}_${startDate}_${endDate}_${sectorId}`;
  const db = await dbPromise;
  return db.get('chartData', cacheKey);
};

// Eliminar todos los datos de chartData
export const removeIndexDBChartData = async () => {
  const db = await dbPromise;
  const keys = await db.getAllKeys('chartData');
  const transactions = keys.map(async (key) => {
    await db.delete('chartData', key as string);
  });
  await Promise.all(transactions);
};
