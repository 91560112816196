import React, { useState } from 'react';
import {
  Box,
  Card,
  Container,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RemoveRedEye } from '@mui/icons-material';
import { useHistory } from 'react-router';
import useStyles from './styles';
import useFetchApi from '../../../hooks/useFetchApi';
import Page from '../../../components/Layout/Page';
import CustomHeader from '../../../components/General/CustomHeader';
import CustomTable from '../../../components/General/CustomTable';
import { FarmSchema, GetDevicesPerPageResponse, SectorSchema } from '../../../requests/api/apiTypes';
import { ReduxState } from '../../../types';

const DEVICES_LIST_UI = [
  {
    label: 'Fecha instalación', key: 'installationDate',
  },
  {
    label: 'Fecha garantía', key: 'warrantyDate',
  },
  {
    label: 'Tipo', key: 'deviceType',
  },
  {
    label: 'ID', key: 'auxId',
  },
  {
    label: 'Nombre', key: 'name',
  },
  {
    label: 'Campo', key: 'farm',
  },
  {
    label: 'Sector', key: 'sector',
  },
  {
    label: 'Estado', key: 'status',
  },
  {
    label: 'Ver', key: 'seeMore',
  },
];

const oneButtonAction = (history: ReturnType<typeof useHistory>, id: string) => (
  <Box display='flex' alignItems='center' justifyContent='center'>
    <IconButton
      onClick={() => { history.push(`/layout/equipos/${id}`); }}>
      <RemoveRedEye />
    </IconButton>
  </Box>);

const AccountDevicesListView = () => {
  const classes = useStyles();
  const history = useHistory();
  const [paginationParameters, setPaginationParameters] = useState({
    page: 0,
    rowsPerPage: 5,
  });
  const { selectedAccount } = useSelector((state: ReduxState) => state.userAccount);

  const { data: fetchData } = useFetchApi<GetDevicesPerPageResponse>({
    modelEndpoint: 'devicesList',
    modelName: 'devices',
    dependencies: [
      paginationParameters.page, paginationParameters.rowsPerPage, selectedAccount?.id ?? '',
    ],
    inputData: {
      page: paginationParameters.page,
      limit: paginationParameters.rowsPerPage,
      accountId: selectedAccount?.id ?? '',
    },
  });

  const localData = {
    count: fetchData?.totalDevices ?? 0,
    values: fetchData?.devices?.filter((elem) => elem.id).map((elem) => ({
      id: elem.id,
      installationDate: 'N/A',
      warrantyDate: 'N/A',
      deviceType: elem.deviceType,
      auxId: elem.deviceModel?.cdtecDeviceId ?? elem.deviceModel?.codProd ?? '-',
      name: elem.deviceModel?.name,
      model: elem.deviceModel?.model,
      status: elem.status,
      farm: (elem.farm as unknown as FarmSchema)?.name,
      sector: elem.sector ? (elem.sector as unknown as SectorSchema)?.name : 'N/A',
      seeMore: oneButtonAction(history, elem.id?.toString() ?? ''),
    })) ?? [],
  };

  return (
    <Page
      className={classes.root}
      title='Equipos'
    >
      <Container maxWidth={false}>
        <CustomHeader title='Equipos' showBackButton={false} />
        <Box mt={3}>
          <Card className={classes.cardContainer}>
            <Typography
              variant="h4"
              color="text.primary"
              mb={2}
            >
              Listado de equipos
            </Typography>
            <Divider />
            <CustomTable
              rows={{ values: localData.values, count: localData.count }}
              headers={DEVICES_LIST_UI}
              paginationParameters={paginationParameters}
              setPaginationParameters={setPaginationParameters}
            />
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default AccountDevicesListView;
