/* eslint-disable no-unused-vars */
import React, {
  useState, useRef, useEffect,
} from 'react';
import {
  Box, Divider, MenuItem, TextField, Typography, IconButton,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Edit as EditIcon, Settings } from '@mui/icons-material';
import ListLayout from '../ListLayout';
import useStyles from '../../styles';
import CustomButton from '../../../../../components/General/CustomButton';
import ListTile from '../ListTile';
import UpsertFarmModal from '../Farm/UpsertFarmModal';
import { ReduxState } from '../../../../../types';
import AddSectorFlow from './AddSectorFlow';
import { setAccountFarm } from '../../../../../actions/accountFarmAction';
import { FarmSchema } from '../../../../../requests/api/apiTypes';
import { setFarmDevice } from '../../../../../actions/farmSectorActions';
import { setCurrentSector } from '../../../../../actions/currentSectorActions';

const SectorsList = (
  {
    isLayout,
  }: {
    isLayout?: boolean
  },
) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const listContainerRef = useRef<HTMLDivElement>(null);

  const { selectedFarm, farmsOptions } = useSelector((state: ReduxState) => state.accountFarm);
  const { selectedSector } = useSelector((state: ReduxState) => state.currentSector);
  const [openAddSectorModal, setOpenAddSectorModal] = useState(false);

  const [upsertFarmModalState, setUpsertFarmModalState] = useState({ open: false, toEdit: false });

  const handleFarmValueChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (farmsOptions && e.target.value !== '0') {
      const auxFarm = farmsOptions
        .find((elem) => elem.id === e.target.value);
      dispatch(setAccountFarm(auxFarm as FarmSchema));
      dispatch(setCurrentSector(null));
    }
  };

  const handleSectorClick = (sectorId: string) => {
    if (selectedSector?.id === sectorId) {
      dispatch(setCurrentSector(null));
      dispatch(setFarmDevice(''));
    } else {
      // Find the sector object in the selectedFarm
      const selectedSectorObj = selectedFarm?.sectors?.find((sector) => sector.id === sectorId);

      if (selectedSectorObj) {
        dispatch(setCurrentSector(selectedSectorObj)); // Set current sector in Redux
      }
    }
  };

  useEffect(() => {
    if (selectedSector) {
      const sectorElement = document.getElementById(`sector-${selectedSector}`);
      if (sectorElement && listContainerRef.current) {
        sectorElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  }, [selectedSector]);

  const handleSeeMore = (sectorId: string) => {
    const source = isLayout ? 'layout' : 'inicio';
    localStorage.setItem(`sector-${sectorId}-tab`, '2');
    history.push(`/sectores/${sectorId}?source=${source}`);
  };

  useEffect(() => {
    if (farmsOptions?.length && !selectedFarm) {
      const storedFarmId = localStorage.getItem('selectedFarmId');
      if (storedFarmId) {
        const storedFarm = farmsOptions.find((farm) => farm.id === storedFarmId);
        if (storedFarm) {
          dispatch(setAccountFarm(storedFarm));
          return;
        }
      }
      dispatch(setAccountFarm(farmsOptions[0]));
    }
  }, [farmsOptions, selectedFarm, dispatch]);

  return (
    <>
      <ListLayout>
        <Typography
          variant="h3"
          color="primary"
        >
          Sectores
        </Typography>
        <Divider className={classes.divider} />
        <TextField
          value={selectedFarm?.id || ''}
          onChange={handleFarmValueChange}
          variant='standard'
          fullWidth
          select
          label='Campo'
          sx={{ marginBottom: '5px' }}
        >
          {farmsOptions
            ?.map((elem) => (<MenuItem key={elem.id} value={elem.id}>
              {elem.name}
            </MenuItem>))}
          {isLayout && <>
            <Divider className={classes.divider} />
            <MenuItem value={'0'} onClick={() => setUpsertFarmModalState({ open: true, toEdit: false })}>
              Crear nuevo campo
            </MenuItem>
          </>}
        </TextField>
        {selectedFarm && isLayout && <>
          <ListTile
            tileInformation={{
              Nombre: selectedFarm?.name as string,
              Hectareas: `${selectedFarm?.theoreticalSurface} HA` || 'S/I',
              Ubicación: selectedFarm?.location as string,
              'N° Sectores': selectedFarm?.sectors?.length ?? 0,
            }}
            customSeeMoreIcon={(
              <IconButton onClick={() => setUpsertFarmModalState({ open: true, toEdit: true })}>
                <EditIcon fontSize="small" />
              </IconButton>
            )}
          />
          <Divider className={classes.divider} />
          {isLayout && (
            <Box marginBottom='12px' marginTop="12px" width='100%'>
              <CustomButton buttonText='AGREGAR SECTOR' onClick={() => setOpenAddSectorModal(true)} color='blue' />
            </Box>
          )}
        </>}
        {!selectedFarm && <Typography variant='body2'>
          Selecciona un campo para más información
        </Typography>}
        <Box className={classes.listTilesContainer} ref={listContainerRef}>
          {(selectedFarm?.sectors && selectedFarm.sectors.length > 0)
            && selectedFarm.sectors.map((sector) => {
              const {
                id, name, sectorDevice, sectorNumber, variety,
              } = sector;
              return (
                <React.Fragment key={sector.wiseconnId}>
                  <div id={`sector-${id}`}>
                    <ListTile
                      title={`E${sectorDevice || ''} - S${sectorNumber || ''} ${variety?.species ?? ''} ${variety?.name ?? ''}`}
                      tileInformation={{
                        'Nombre cliente': name,
                      }}
                      isActive={selectedSector?.id === id}
                      onClick={() => handleSectorClick(id ?? '')}
                      customContainerClassName={classes.actionableListTileContainer}
                      customSeeMoreIcon={selectedSector?.id === id ? (
                        <IconButton onClick={() => handleSeeMore(id ?? '')}>
                          <Settings sx={{ color: 'white', fontSize: '30px' }} />
                        </IconButton>
                      ) : undefined}
                    />
                    <Divider />
                  </div>
                </React.Fragment>
              );
            })}
          {(selectedFarm?.sectors && selectedFarm.sectors.length === 0)
            && <Typography variant='body2'>
              No hay sectores asociados a este campo
            </Typography>}
        </Box>
      </ListLayout>
      <AddSectorFlow
        isModalOpen={openAddSectorModal}
        setOpenModal={setOpenAddSectorModal}
        lat={selectedFarm?.center.lat ?? ''}
        lng={selectedFarm?.center.lng ?? ''}
      />
      <UpsertFarmModal
        modalState={upsertFarmModalState}
        setModalState={setUpsertFarmModalState}
      />
    </>
  );
};

export default SectorsList;
