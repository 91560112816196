/* eslint-disable @typescript-eslint/default-param-last */
/* eslint no-param-reassign: ["error", { "props": false }] */
import produce, { Draft } from 'immer';
import { SectorSchema } from '../requests/api/apiTypes';
import { SET_CURRENT_SECTOR } from '../actions/currentSectorActions';
import { CurrentSectorState } from '../types';

const initialState: CurrentSectorState = {
  selectedSector: null,
};

interface CurrentSectorAction {
  type: string;
  payload: SectorSchema;
}

const currentSectorReducer = (
  state = initialState,
  action: CurrentSectorAction,
): CurrentSectorState => {
  switch (action.type) {
    case SET_CURRENT_SECTOR: {
      return produce(state, (draft: Draft<CurrentSectorState>) => {
        draft.selectedSector = action.payload ? { ...action.payload as SectorSchema } : null;
      });
    }
    default: {
      return state;
    }
  }
};

export default currentSectorReducer;
