import axios from 'axios';
import { formatDateToIsoString } from '../utils/formatDate';

// Used for Irrigation
export async function fetchReadingsFromAPI(filters: {
  granularity?: string;
  farmId: string;
  startDate: string;
  endDate: string;
  sensorType?: string;
  sectorId?: string;
  wiseconnZoneId?: string;
  hourInterval?: number;
  name?: string;
}) {
  const {
    granularity,
    farmId,
    startDate,
    endDate,
    sensorType,
    sectorId,
    wiseconnZoneId,
    name,
    hourInterval,
  } = filters;

  let baseEndpoint = '/api/readings';

  if (granularity) {
    baseEndpoint += `/${granularity}`;
  }

  try {
    const response = await axios.get(baseEndpoint, {
      params: {
        farmId,
        startDate: formatDateToIsoString(startDate),
        endDate: formatDateToIsoString(endDate),
        sensorType,
        ...(sectorId && { sectorId }),
        ...(wiseconnZoneId && { wiseconnZoneId }),
        ...(name && { name }),
        ...(hourInterval && { hourInterval }),
      },
    });

    return response.data.readings || [];
  } catch (error) {
    console.error('Error fetching readings:', error);
    throw new Error('Failed to fetch readings');
  }
}

export default {
  fetchReadingsFromAPI,
};
