/* eslint-disable @typescript-eslint/no-use-before-define, no-use-before-define */
import React, { useEffect, useMemo } from 'react';
import { useLocation, matchPath } from 'react-router';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListItem,
  TextField,
  Autocomplete,
  MenuItem,
} from '@mui/material';

import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@mui/icons-material/';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import NavItem from './NavItem';
import { NavigationItemProps, ReduxState } from '../../../types';
import { getUpdatedSpecificNavConfig, navConfig } from './utils';
import useStyles from './styles';
import useFetchApi from '../../../hooks/useFetchApi';
import {
  AccountSchema, FarmSchema, GetUserAccountsByUserIdResponse,
} from '../../../requests/api/apiTypes';
import { setUserAccount } from '../../../actions/userAccountAction';
import { setAccountFarm } from '../../../actions/accountFarmAction';
import { setCurrentSector } from '../../../actions/currentSectorActions';
import { UserRole, UserType } from '../../../constants/user_types';

function renderNavItems(
  items: NavigationItemProps[],
  pathname: string,
  userType: UserType,
  depth = 0,
): JSX.Element {
  return (
    <List disablePadding>
      {items?.reduce(
        (acc: JSX.Element[], item) => reduceChildRoutes(acc, pathname, item, userType, depth),
        [],
      )}
    </List>
  );
}

function reduceChildRoutes(
  acc: JSX.Element[],
  pathname: string,
  item: NavigationItemProps,
  userType: UserType,
  depth = 0,
) {
  const key = `${item.title || ''}depth`;

  if (item.userTypesAllowed?.includes(userType)) {
    if (item.items && item.items.length > 0) {
      const open = matchPath(pathname, {
        path: item.href,
        exact: false,
      }) || item.items.some((subItem) => pathname.includes(subItem.href || ''));
      acc.push(
        <NavItem
          depth={depth}
          icon={item.icon}
          key={key}
          info={item.info}
          open={Boolean(open)}
          title={item.title}
          href={item.href}
          items={item.items}
        >
          {renderNavItems(item.items as NavigationItemProps[], pathname, userType, depth + 1)}
        </NavItem>,
      );
    } else {
      acc.push(
        <NavItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          key={key}
          info={item.info}
          title={item.title}
        />,
      );
    }
  }
  return acc;
}

function NavBar({
  openMobile, onMobileClose, openDrawer, onDrawerAction,
}: {
  openMobile: boolean, onMobileClose: () => void,
  openDrawer: boolean, onDrawerAction: () => void
}) {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReduxState) => state.account);
  const { selectedAccount } = useSelector((state: ReduxState) => state.userAccount);
  const { selectedFarm, farmsOptions } = useSelector((state: ReduxState) => state.accountFarm);
  const { allFarmDevices } = useSelector((state: ReduxState) => state.farmSector);
  const navigate = useHistory();

  const { data } = useFetchApi<GetUserAccountsByUserIdResponse>({
    modelName: 'userAccounts',
    modelEndpoint: 'userAccountsDetail',
    id: user?.id,
    dependencies: [selectedAccount?.id ?? ''],
  });
  const localAccounts = data?.userAccounts;

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    if (farmsOptions?.length && !selectedFarm) {
      const storedFarmId = localStorage.getItem('selectedFarmId');
      if (storedFarmId) {
        const storedFarm = farmsOptions.find((farm) => farm.id === storedFarmId);
        if (storedFarm) {
          dispatch(setAccountFarm(storedFarm));
          return;
        }
      }
      dispatch(setAccountFarm(farmsOptions[0]));
    }
  }, [farmsOptions, selectedFarm, dispatch]);

  // Select the first one on first render if there is more than 2
  if ((localAccounts && localAccounts.length >= 1)
    && !selectedAccount?.id) {
    const account = localAccounts[0];
    dispatch(setUserAccount(account));
    if (account.farms && account.farms.length > 0) {
      dispatch(setAccountFarm(account.farms[0]));
      dispatch(setCurrentSector(null));
    }
  }

  const onAccountChange = (_event: React.SyntheticEvent, newValue: AccountSchema | null) => {
    if (newValue) {
      dispatch(setUserAccount(newValue));
      if (newValue.farms && newValue.farms.length > 0) {
        dispatch(setAccountFarm(newValue.farms[0]));
        dispatch(setCurrentSector(null));
      }

      navigate.push('/inicio');
    }
  };

  const onFarmChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (farmsOptions && e.target.value !== '0') {
      const auxFarm = farmsOptions
        .find((elem) => elem.id === e.target.value);
      dispatch(setAccountFarm(auxFarm as FarmSchema));
      dispatch(setCurrentSector(null));
    }

    if (!location.pathname.includes('/inicio')) {
      navigate.push('/inicio');
    }
  };

  const updatedNavConfig = useMemo(() => getUpdatedSpecificNavConfig(
    selectedFarm?.sectors,
    allFarmDevices,
  ), [selectedFarm?.sectors, allFarmDevices]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          {user?.role === UserRole.ADMIN && navConfig.length > 0 && (
            <List>
              {renderNavItems(navConfig, location.pathname, user.type as UserType)}
            </List>
          )}
          <Divider />
          <Box key='account-container' width='100%' mt={2} mb={2}>
            {localAccounts && localAccounts.length > 0 && selectedAccount ? (
              <Autocomplete
                key='account-item'
                options={localAccounts}
                getOptionLabel={(option) => option.name}
                value={selectedAccount}
                onChange={onAccountChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Cuenta'
                    variant='standard'
                    fullWidth
                  />
                )}
                disableClearable
                disabled={localAccounts.length === 1}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            ) : (
              <TextField
                key='account-item'
                label='Cuenta'
                name='selectedAccount'
                variant='standard'
                fullWidth
                value=''
                disabled
              />
            )}
          </Box>
          <Box key='farm-container' width='100%' mt={2} mb={2}>
            <TextField
              value={selectedFarm?.id || ''}
              onChange={onFarmChange}
              variant='standard'
              fullWidth
              select
              label='Campo'
              sx={{ marginBottom: '5px' }}
            >
              {farmsOptions?.map((elem) => (
                <MenuItem key={elem.id} value={elem.id}>
                  {elem.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          {selectedAccount?.id && (
            <>
              <List>
                {renderNavItems(updatedNavConfig, location.pathname, user?.type as UserType)}
              </List>
            </>
          )}
          {!selectedAccount?.id && <ListItem disabled>
            Selecciona un cliente
          </ListItem>}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return <>
    <Hidden lgUp>
      <Drawer
        anchor="left"
        classes={{ paper: classes.mobileDrawer }}
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
      >
        {content}
      </Drawer>
    </Hidden>
    <Hidden lgDown>
      <Drawer
        anchor="left"
        classes={{ paper: classes.desktopDrawer }}
        open={openDrawer}
        variant="persistent"
      >
        {content}
      </Drawer>
      <Box
        onClick={onDrawerAction}
        className={openDrawer ? `${classes.openCloseDrawerBox} ${classes.openDrawerIcon}` : `${classes.openCloseDrawerBox} ${classes.closedDrawerIcon}`}>
        {openDrawer ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </Box>
    </Hidden>
  </>;
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  onDrawerAction: PropTypes.func,
  openDrawer: PropTypes.bool,
};

export default NavBar;
