import React from 'react';
import IrrigationChart from '../../../../../../components/Highcharts/IrrigationChart/IrrigationChart';
import StackedSoilMoistureChart from '../../../../../../components/Highcharts/StackedSoilMoistureChart/StackedSoilMoistureChart';
import SoilMoistureChart from '../../../../../../components/Highcharts/SoilMoistureChart/SoilMoistureChart';
import RootsChart from '../../../../../../components/Highcharts/RootsChart/RootsChart';
import useStyles from '../../../../../../components/Highcharts/styles';

function SoilDashboard({
  sectorId,
  farmId,
  wiseconnZoneId,
}: {
  sectorId: string,
  farmId: string,
  wiseconnZoneId: string | undefined,
}) {
  const classes = useStyles();

  return (
    <div className={classes.dashboardContainer}>
      <IrrigationChart
        chartView="dashboard"
        hideLegend={false}
        hideYValues={false}
        hideXValues={true}
        sectorId={sectorId}
        farmId={farmId}
        wiseconnZoneId={wiseconnZoneId}
        height="150px"
        yAxisTitle=""
        title="Kc y mm"
      />
      <StackedSoilMoistureChart
        chartView="dashboard"
        hideLegend={false}
        hideXValues={true}
        hideYValues={false}
        sectorId={sectorId}
        farmId={farmId}
        wiseconnZoneId={wiseconnZoneId}
        height="18%"
        title="Humedad de suelo"
        yAxisTitle=" "
      />
      <SoilMoistureChart
        chartView="dashboard"
        hideYValues={false}
        hideXValues={false}
        hideLegend={true}
        sectorId={sectorId}
        farmId={farmId}
        wiseconnZoneId={wiseconnZoneId}
        height="18%"
        title="Sumatoria humedad de suelo"
        yAxisTitle=" "
      />
        <RootsChart
        chartView="dashboard"
        hideLegend={false}
        hideXValues={true}
        hideYValues={false}
        sectorId={sectorId}
        farmId={farmId}
        wiseconnZoneId={wiseconnZoneId}
        height="150px"
        yAxisTitle=""
        title="Profundidad riego y raíces"
      />
    </div>
  );
}

export default SoilDashboard;
