// import dayjs from 'dayjs';
import {
  applyMiddleware, createStore, Middleware,
} from 'redux';
import thunkMiddleware, { ThunkMiddleware } from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';
import { createLogger } from 'redux-logger';
import { persistStore, persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from '../reducers';
import webSocketMiddleware from './middleware/webSocketMiddleware';
import { ENABLE_REDUX_LOGGER } from '../config';
import { CombinedStateType, ReduxState } from '../types';
import AppActions from '../actions/appActions';

interface FlushFunction {
  (): Promise<void>;
}

const persistConfig: PersistConfig<CombinedStateType> = {
  key: 'root',
  storage,
  whitelist: ['accountFarm', 'farmSector', 'currentSector'],
};

let flush: FlushFunction;

const loggerMiddleware = createLogger();

export function configureStore() {
  const middlewares: Middleware[] = [thunkMiddleware, webSocketMiddleware];

  if (ENABLE_REDUX_LOGGER) {
    middlewares.push(loggerMiddleware);
  }

  const thunkMiddlewareTyped: ThunkMiddleware<ReduxState, AppActions> = thunkMiddleware;
  middlewares.push(thunkMiddlewareTyped);

  const middlewareEnhancer = composeWithDevTools(
    applyMiddleware(...middlewares),
  );

  const pReducer = persistReducer(persistConfig, rootReducer);

  // Before we used composedEnhancers instead of middlewareEnhancer
  const store = createStore(pReducer, middlewareEnhancer);
  const persistor = persistStore(store);
  flush = persistor.flush;

  return { store, persistor };
}

export const persistorFlush = (): Promise<void> => flush();
