import React from 'react';
import RainfallChart from '../../../../../../components/Highcharts/RainfallChart/RainfallChart';
import Et0Chart from '../../../../../../components/Highcharts/Et0Chart/Et0Chart';
import KcChart from '../../../../../../components/Highcharts/KcChart/KcChart';

const WeatherChartsTab = ({
  sectorId, farmId, wiseconnZoneId,
}: { sectorId: string, farmId: string, wiseconnZoneId: string | undefined }) => (
  <>
    <KcChart
      chartView="graphs"
      sectorId={sectorId}
      farmId={farmId}
      wiseconnZoneId={wiseconnZoneId}
    />
    <RainfallChart
      chartView="graphs"
      sectorId={sectorId}
      farmId={farmId}
    />
    <Et0Chart
      chartView="graphs"
      sectorId={sectorId}
      farmId={farmId}
    />
  </>
);

export default WeatherChartsTab;
