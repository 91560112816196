import {
  UserType,
} from './user_types';

const fallbackRoutes:{ [key: string]: string } = {
  [UserType.CDTEC]: '/usuarios',
  [UserType.CONSULTANT]: '/usuarios',
  [UserType.CLIENT]: '/usuarios',
  [UserType.MULTI_ACCOUNT_CLIENT]: '/usuarios',
};

export default fallbackRoutes;
