import dayjs from 'dayjs';
import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import {
  Box,
  CircularProgress,
  Switch,
  FormControlLabel,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import BaseHighchart from '../BaseHighchart';
import prepareData from './builder';
import { saveChartData, getChartData } from '../../../services/indexDBService';
import { calculateDateRangeFromNotification } from '../utils';
import {
  ChartDataType,
  ChartFilters,
  ScatterPoint,
} from '../types';

import { ReduxState } from '../../../types';
import ChartFilter from '../ChartFilter';
import { navigateToChart } from '../../../utils/helpers';
import { ConversationSchema, TimePointSchema } from '../../../requests/api/apiTypes';
import apiClient from '../../../requests/api/apiClient';
import { FetchError } from '../../../views/types';
import { STACKED_SOIL_MOISTURE_NAME } from '../../../constants/graphs';
import ConversationModal from '../ConversationTab/conversationModal';
import { setStartDate, setEndDate } from '../../../actions/graphsFilterActions';

function StackedSoilMoistureChart({
  chartView,
  sectorId,
  farmId,
  wiseconnZoneId,
  hideLegend = false,
  hideXValues = false,
  hideYValues = false,
  height = 600,
  title = 'Gráfico de humedad de suelo apilado',
  yAxisTitle = 'Humedad Suelo (%)',
  externalModalState,
  externalSetModalState,
  externalConversations,
  externalSetConversations,
}: {
  chartView: 'dashboard' | 'graphs' | 'settings'
  sectorId: string
  farmId: string
  wiseconnZoneId: string | undefined
  hideLegend?: boolean
  hideXValues?: boolean
  hideYValues?: boolean
  height?: number | string
  title?: string
  yAxisTitle?: string
  externalModalState?: {
    open: boolean;
    conversationId: string;
    timePointData: TimePointSchema;
  };
  externalSetModalState?: React.Dispatch<React.SetStateAction<{
    open: boolean;
    conversationId: string;
    timePointData: TimePointSchema;
  }>>;
  externalConversations?: ConversationSchema[];
  externalSetConversations?: React.Dispatch<React.SetStateAction<ConversationSchema[]>>;
}) {
  const [chartData, setChartData] = useState<ChartDataType>({
    series: [],
    categories: [],
    absoluteCategories: [],
  });
  const [subtractDepth, setSubtractDepth] = useState(false);
  const [rawData, setRawData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dateParam = searchParams.get('date');

  const [internalModalState, setInternalModalState] = useState<{
    open: boolean;
    conversationId: string;
    timePointData: TimePointSchema;
  }>({
    open: false,
    conversationId: '',
    timePointData: {
      hour: '',
      day: '',
      month: '',
      year: '',
    },
  });
  const [internalConversations, setInternalConversations] = useState<ConversationSchema[]>([]);

  const { startDate, endDate } = useSelector((state: ReduxState) => state.graphsFilter);
  const selectedSector = useSelector((state: ReduxState) => state.currentSector.selectedSector);

  const modalState = externalModalState || internalModalState;
  const setModalState = externalSetModalState || setInternalModalState;
  const conversations = externalConversations || internalConversations;
  const setConversations = externalSetConversations || setInternalConversations;

  const transformData = useCallback((preparedData: any, shouldSubtractDepth: boolean) => {
    const FIXED_OFFSET = 5; // Fixed offset value for better visualization
    const seriesData = preparedData.series.map((item: any, index: number) => ({
      type: 'line',
      name: item.name,
      data: item.data.map((value: number, idx: number) => ({
        x: idx,
        y: shouldSubtractDepth ? value : value - (index * FIXED_OFFSET),
        custom: {
          date: preparedData.absoluteCategories[idx],
        },
      })),
    }));

    setChartData({
      series: [...seriesData],
      categories: preparedData.categories as string[],
      absoluteCategories: preparedData.absoluteCategories,
    });
  }, []);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const cachedData = await getChartData('stackedSoilMoisture', startDate, endDate, selectedSector?.id || sectorId);

      if (cachedData !== null && cachedData !== undefined) {
        setRawData(cachedData.data);
        transformData(cachedData.data, subtractDepth);
        setIsLoading(false);
        return;
      }

      const chartFilters: ChartFilters = {
        farmId,
        startDate,
        endDate,
      };

      if (wiseconnZoneId) {
        chartFilters.wiseconnZoneId = wiseconnZoneId;
      }

      const preparedData = await prepareData(chartFilters);

      // Save to IndexDB
      await saveChartData('stackedSoilMoisture', startDate, endDate, selectedSector?.id || sectorId, preparedData);

      setRawData(preparedData);
      transformData(preparedData, subtractDepth);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  }, [farmId,
    startDate,
    endDate,
    wiseconnZoneId,
    subtractDepth,
    transformData,
    selectedSector,
    sectorId,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (rawData) {
      transformData(rawData, subtractDepth);
    }
  }, [subtractDepth, rawData, transformData]);

  useEffect(() => {
    if (dateParam) {
      // Convert date from DD-MM-YYYY to YYYY-MM-DD
      const { rangeStartDate, rangeEndDate } = calculateDateRangeFromNotification(dateParam);
      // Update the date range in the store
      dispatch(setStartDate(rangeStartDate));
      dispatch(setEndDate(rangeEndDate));
    }
  }, [dateParam, dispatch]);

  const customTooltip = useMemo(() => ({
    shared: true,
    useHTML: true,
    formatter(
      this: Highcharts.TooltipFormatterContextObject,
    ) {
      if (this.series.name === 'Comentarios') {
        const point = this.point as ScatterPoint;
        const conversationId = point.custom?.conversationId;
        if (!conversations || !conversationId) {
          return '<b>Último comentario:</b> Cargando...<br/>';
        }
        const conversation = conversations.find((conv) => conv.conversationId === conversationId);
        if (!conversation) {
          return '<b>Último comentario:</b> No se encontró la conversación<br/>';
        }
        const lastComment = conversation.lastComment || 'Sin comentarios';
        return `<b>Último comentario:</b> ${lastComment}<br/>
                <span style="color: gray; font-size: 10px">${dayjs(conversation.updatedAt).format('MMM DD, YYYY HH:mm')}</span>`;
      }
      const header = '<div style="font-size: 14px; font-weight: bold; margin-bottom: 8px;">Resumen</div>';
      const subHeader = `<div style="font-size: 12px; margin-bottom: 8px;">
        ${dayjs(this.point?.custom?.date).format('MMM DD, YYYY HH:mm')}
      </div>`;
      const points = this.points?.map(
        (point, index) => {
          const displayValue = subtractDepth ? Number(point.y) + (index * 5) : Number(point.y);
          return `<div><span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${displayValue.toFixed(2)}</b></div>`;
        },
      ).join('');
      return `
        <div style="
          padding: 10px;
          background: rgba(255, 255, 255, 1);
          border-radius: 5px;">
          ${header}${subHeader}${points}
        </div>
      `;
    },
  }), [subtractDepth, conversations]);

  const chartConfig = useMemo(() => ({
    chart: {
      height,
    },
    xAxis: {
      categories: hideXValues ? [] : chartData.categories,
      labels: {
        step: 1,
        enabled: !hideXValues,
      },
      plotLines: (() => {
        const lines: any[] = [];

        chartData.categories.forEach((category, index) => {
          if (category && category.trim() !== '' && index > 0) {
            lines.push({
              color: '#999999',
              width: 1,
              dashStyle: 'Dash',
              value: index - 0.5,
            });
          }
        });

        return lines;
      })(),
    },
  }), [height, hideXValues, chartData.categories]);

  useEffect(() => {
    if (!externalConversations) {
      const loadConversations = async () => {
        try {
          const { data } = await apiClient.conversation.conversationDetail(
            sectorId,
            STACKED_SOIL_MOISTURE_NAME,
          );
          setInternalConversations(data.conversations);
        } catch (err) {
          const { error } = err as FetchError;
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      };

      loadConversations();
    }
  }, [sectorId, externalConversations]);

  const handleConfigClick = () => {
    navigateToChart('humedad-suelo-apilada', history, {
      farmId,
      sectorId,
      wiseconnZoneId: wiseconnZoneId || '',
    });
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={height}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
      <BaseHighchart
        chartView={chartView}
        filter={chartView === 'settings' ? <ChartFilter sectorId={sectorId} /> : undefined}
        title={title}
        yAxisTitle={subtractDepth ? '' : yAxisTitle}
        series={chartData.series as Highcharts.SeriesOptionsType[]}
        tooltip={customTooltip}
        hideYValues={subtractDepth || hideYValues}
        showFullScreen={true}
        legend={{ enabled: !hideLegend }}
        stackButton={{
          onClick: () => setSubtractDepth(!subtractDepth),
          isStacked: subtractDepth,
          component: (
            <FormControlLabel
              control={
                <Switch
                  checked={!subtractDepth}
                  onChange={() => setSubtractDepth(!subtractDepth)}
                  sx={{
                    '& .MuiSwitch-switchBase': {
                      color: '#a8d4f7',
                      '&.Mui-checked': {
                        color: '#3693d5',
                      },
                      '&.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: '#3693d5',
                      },
                    },
                    '& .MuiSwitch-track': {
                      backgroundColor: '#a8d4f7',
                    },
                  }}
                />
              }
              label={subtractDepth ? 'Original' : 'Apilar'}
              sx={{ mr: 2 }}
            />
          ),
        }}
        chartConfig={chartConfig}
        onConfigClick={handleConfigClick}
        absoluteCategories={chartData.absoluteCategories}
        setModalState={setModalState}
        conversations={conversations}
        height={height}
      />
      <ConversationModal
        modalState={modalState}
        setModalState={setModalState}
        farmId={farmId}
        sectorId={sectorId}
        chart={STACKED_SOIL_MOISTURE_NAME}
        setConversationsFunction={setConversations}
      />
    </div>
  );
}

export default StackedSoilMoistureChart;
