/* eslint-disable max-len */
// eslint-disable-next-line import/prefer-default-export
import { History } from 'history';

export function isKeyOfSchema<T extends object>(key: string, schema: T): key is Extract<keyof T, string> {
  return key in schema;
}

export const navigateToChart = (chart: string, history: History, params: Record<string, string>) => {
  const searchParams = new URLSearchParams(params);
  history.push(`/graficos/${chart}?${searchParams.toString()}`);
};
