import { useLocation } from 'react-router-dom';
import React, { useMemo, useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import {
  Box, Tab, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography,
} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import CustomHeader from '../../General/CustomHeader';
import Page from '../../Layout/Page';
import useStyles from '../styles';
import KcChart from './KcChart';
import { ConversationSchema, TimePointSchema } from '../../../requests/api/apiTypes';
import apiClient from '../../../requests/api/apiClient';
import { FetchError } from '../../../views/types';
import { KC_NAME } from '../../../constants/graphs';
import ConversationsTab from '../ConversationTab';
import { ReduxState } from '../../../types';

const Kc = () => {
  const classes = useStyles();
  const { search } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const queryParams = new URLSearchParams(search);

  // Check if we're coming from a notification and set default tab to conversations
  const source = queryParams.get('source');
  const initialTabValue = source === 'notification' ? '2' : '1';

  const [tabValue, setTabValue] = useState(initialTabValue);
  const { selectedFarm } = useSelector((state: ReduxState) => state.accountFarm);
  const { selectedSector } = useSelector((state: ReduxState) => state.currentSector);

  const [conversations, setConversations] = useState<ConversationSchema[]>([]);
  const [conversationsLoading, setConversationsLoading] = useState(false);
  const [modalState, setModalState] = useState<{
    open: boolean;
    conversationId: string;
    timePointData: TimePointSchema;
  }>({
    open: false,
    conversationId: '',
    timePointData: {
      hour: '',
      day: '',
      month: '',
      year: '',
    },
  });

  const params = {
    farmId: queryParams.get('farmId') || '',
    sectorId: queryParams.get('sectorId') || '',
    wiseconnZoneId: queryParams.get('wiseconnZoneId') || '',
  };

  // Load conversations when component mounts
  useEffect(() => {
    const loadConversations = async () => {
      if (!params.sectorId) return;
      setConversationsLoading(true);
      try {
        const { data } = await apiClient.conversation.conversationDetail(
          params.sectorId,
          KC_NAME,
        );
        setConversations(data.conversations);
      } catch (err) {
        const { error } = err as FetchError;
        enqueueSnackbar(error.message, { variant: 'error' });
      } finally {
        setConversationsLoading(false);
      }
    };

    loadConversations();
  }, [params.sectorId, enqueueSnackbar]);

  const handleCloseModal = () => {
    setModalState({
      open: false,
      conversationId: '',
      timePointData: {
        hour: '',
        day: '',
        month: '',
        year: '',
      },
    });
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const breadcrumb = useMemo(() => {
    const sectorName = selectedFarm?.sectors?.find(
      (sector) => sector.id === selectedSector?.id,
    )?.name || selectedSector?.name;

    return `${selectedFarm?.name}/${sectorName}/Configuración`;
  }, [selectedFarm, selectedSector]);

  return (
    <Page
      className={classes.root}
      title='Coeficiente de cultivo'
    >
      <Container maxWidth={false}>
        <CustomHeader title={breadcrumb} />
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleTabChange} aria-label="Kc chart tabs">
              <Tab label="Gráfico" value="1" />
              <Tab label="Conversaciones" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Box mt={3}>
              <KcChart
                chartView="settings"
                sectorId={params.sectorId}
                farmId={params.farmId}
                wiseconnZoneId={params.wiseconnZoneId}
              />
            </Box>
          </TabPanel>
          <TabPanel value="2">
            <ConversationsTab
              setModalState={setModalState}
              conversations={conversations}
              conversationsLoading={conversationsLoading}
            />
          </TabPanel>
        </TabContext>
      </Container>

      {/* Dialog for conversation details */}
      <Dialog open={modalState.open} onClose={handleCloseModal} maxWidth="md" fullWidth>
        <DialogTitle>Detalle de Conversación</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            {`Fecha: ${modalState.timePointData.day}/${modalState.timePointData.month}/${modalState.timePointData.year} ${modalState.timePointData.hour}`}
          </Typography>
          <Typography variant="body1">ID: {modalState.conversationId}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};
export default Kc;
