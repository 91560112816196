import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  MenuItem,
  IconButton,
  SvgIcon,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import CustomButton from '../../../../../components/General/CustomButton';
import CustomTable from '../../../../../components/General/CustomTable';

import { CULT_CYCLE_LIST_UI, FIELDS_EQ } from '../../types';
import {
  PhenologicalState,
  DeletePhenologicalState,
  CultivationCyclePopulatedSchema,
  PhenologicalStateWithId,
} from '../../../../../requests/api/apiTypes';

import useStyles from '../styles';
import CustomModal from '../../../../../components/General/CustomModal';
import UpsertPhenologicalStateModalContent from './UpsertPhenologicalStateModalContent';
import { FetchError } from '../../../../types';
import apiClient from '../../../../../requests/api/apiClient';
import { CultivationCycle } from '../types';
import { PhenologicalStateChecker } from '../../../../../utils/varieties';

interface PhenologicalStateRow {
  [key: string]: string | JSX.Element;
  id: string;
  name: string;
  startingDate: string;
  endingDate: string;
  actions: JSX.Element;
}

// TO DO: Al momento de crearse estados fenológicos deben crearse con un uuid
function PhenologicalStatesList({
  cultivationCycles,
  setCultivationCyclesFunction,
}: {
  cultivationCycles: CultivationCycle[];
  setCultivationCyclesFunction:
  React.Dispatch<React.SetStateAction<CultivationCyclePopulatedSchema[]>>;
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [upsertModal, setUpsertModal] = useState({ id: '', open: false });
  const [selectedCycle, setSelectedCycle] = useState<CultivationCycle>(cultivationCycles[0] || {
    id: '', name: '', startDate: '', endDate: '', phenologicalStates: [],
  });
  const [phenologicalStates, setPhenologicalStates] = useState<{
    count: number;
    values: PhenologicalStateRow[];
  }>({ count: 0, values: [] });

  const handleRemovePhenologicalState = async ({ id, name }: DeletePhenologicalState) => {
    try {
      const response = await apiClient.phenologicalStateCultivationCycle
        .phenologicalStateCultivationCycleDelete({ id, name });

      enqueueSnackbar(response.data.message, { variant: 'success' });

      setCultivationCyclesFunction((prevCycles) => prevCycles.map(
        (cycle) => (
          cycle.id === id
            ? {
              ...cycle,
              phenologicalStates: cycle.phenologicalStates.filter(
                (state) => state.name !== name,
              ),
            }
            : cycle
        ),
      ));
    } catch (err) {
      const { error } = err as FetchError;
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    const updatedSelectedCycle = cultivationCycles.find((cycle) => cycle.id === selectedCycle.id);

    if (updatedSelectedCycle) {
      setSelectedCycle(updatedSelectedCycle);
    } else if (cultivationCycles.length > 0) {
      setSelectedCycle(cultivationCycles[0]);
    } else {
      setSelectedCycle({
        id: '', name: '', startDate: '', endDate: '', phenologicalStates: [],
      });
    }
  }, [cultivationCycles]);

  useEffect(() => {
    const sortedPhenologicalStates = PhenologicalStateChecker
      .sortByStartDate(selectedCycle.phenologicalStates);
    const updatedLocalData = {
      count: sortedPhenologicalStates.length,
      values: sortedPhenologicalStates.map((state) => ({
        id: state.uuid,
        name: state.name,
        startingDate: state.startDate,
        endingDate: state.endDate,
        actions: (
          <>
            <IconButton size="large" onClick={() => setUpsertModal({
              id: state.uuid,
              open: true,
            })}>
              <SvgIcon fontSize="small">
                <EditIcon />
              </SvgIcon>
            </IconButton>
            <IconButton size="large" onClick={() => handleRemovePhenologicalState({
              id: selectedCycle.id, name: state.name,
            })}>
              <SvgIcon fontSize="small">
                <DeleteIcon />
              </SvgIcon>
            </IconButton>
          </>
        ),
      })),
    };

    setPhenologicalStates(updatedLocalData);
  }, [selectedCycle]);

  const handleUpsertPhenologicalState = async (phenologicalState: PhenologicalStateWithId) => {
    const hasMissingFields = Object.keys(phenologicalState).some((key) => {
      const value = phenologicalState[key as keyof PhenologicalState];
      if (!value) {
        enqueueSnackbar(`El campo ${FIELDS_EQ[key as keyof PhenologicalState]} debe ser completado`, { variant: 'error' });
        return true;
      }
      return false;
    });

    if (hasMissingFields) return;

    if (!selectedCycle) {
      enqueueSnackbar('No se pudo encontrar el ciclo de cultivo seleccionado', { variant: 'error' });
      return;
    }

    try {
      if (upsertModal.id) {
        // Si estamos editando, primero eliminamos el estado fenológico existente
        const stateToEdit = selectedCycle.phenologicalStates.find(
          (state) => state.uuid === upsertModal.id,
        );
        if (stateToEdit) {
          await apiClient.phenologicalStateCultivationCycle
            .phenologicalStateCultivationCycleDelete({
              id: selectedCycle.id,
              name: stateToEdit.name,
            });
        }
      }

      // Luego agregamos el nuevo estado fenológico
      const { uuid, ...phenologicalStateWithoutId } = phenologicalState;
      const response = await apiClient.phenologicalStateCultivationCycle
        .phenologicalStateCultivationCycleUpdate(selectedCycle.id, phenologicalStateWithoutId);

      const { updatedCycle } = response.data;

      setCultivationCyclesFunction((prevCycles) => prevCycles.map(
        (cycle) => (cycle.id === updatedCycle.id
          ? {
            ...cycle,
            phenologicalStates: updatedCycle.phenologicalStates,
          }
          : cycle
        ),
      ));

      enqueueSnackbar(response.data.message, { variant: 'success' });
      setUpsertModal({ id: '', open: false });
    } catch (err) {
      const { error } = err as FetchError;
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const getStateToEdit = () => {
    if (selectedCycle && upsertModal.id) {
      const state = selectedCycle.phenologicalStates.find(
        (phenState) => phenState.uuid === upsertModal.id,
      );
      return state || null;
    }
    return null;
  };

  return (
    <>
      <Box mt={1}>
        <Card>
          <CardContent>
            <Grid
              container
              justifyContent="space-between"
              spacing={3}
              alignItems="center"
            >
              <Grid item>
                <TextField
                  label="Cultivo"
                  select
                  fullWidth
                  value={cultivationCycles.length > 0 ? selectedCycle.name : 'No hay cultivos'}
                  variant="standard"
                  onChange={(e) => setSelectedCycle(
                    cultivationCycles.find(
                      (cycle) => cycle.name === e.target.value,
                    ) || cultivationCycles[0],
                  )
                  }
                  disabled={cultivationCycles.length === 0}
                >
                  {cultivationCycles.length > 0 ? (
                    cultivationCycles.map((cycle) => (
                      <MenuItem key={cycle.id} value={cycle.name}>
                        {cycle.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="No hay cultivos">
                      No hay cultivos
                    </MenuItem>
                  )}
                </TextField>
              </Grid>
              <Grid item>
                <CustomButton
                  buttonText='Agregar Estado fenológico'
                  color='blue'
                  size='small'
                  onClick={() => setUpsertModal({ id: '', open: true })}
                  disabled={cultivationCycles.length === 0}
                />
              </Grid>
            </Grid>

            <Divider className={classes.divider} />

            <CustomTable
              rows={phenologicalStates}
              headers={CULT_CYCLE_LIST_UI}
            />
          </CardContent>
        </Card>
      </Box>
      <CustomModal
        open={upsertModal.open}
        handleClose={() => setUpsertModal({ id: '', open: false })}
        data={{ id: upsertModal.id }}
      >
        <UpsertPhenologicalStateModalContent
          handleCloseModal={() => setUpsertModal({ id: '', open: false })}
          handleUpsertFunction={handleUpsertPhenologicalState}
          id={upsertModal.id}
          initialData={getStateToEdit() || undefined}
          cultivationCycle={selectedCycle}
        />
      </CustomModal>
    </>
  );
}

export default PhenologicalStatesList;
