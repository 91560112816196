import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Typography,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  Snackbar,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import CustomButton from '../../../../../components/General/CustomButton';
import CardHeader from '../../../../../components/General/CardHeader';
import useStyles from '../styles';
import GoogleMaps from '../../../../../components/Layout/Google/GoogleMaps';
import { ReduxState } from '../../../../../types';
import { setCurrentSector } from '../../../../../actions/currentSectorActions';
import apiClient from '../../../../../requests/api/apiClient';
import { setAccountFarm } from '../../../../../actions/accountFarmAction';
import { FarmSchema, SectorDetailSchema } from '../../../../../requests/api/apiTypes';

function SectorPolygonMap({ sectorId }: { sectorId: string }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedFarm } = useSelector((state: ReduxState) => state.accountFarm);
  const { selectedSector } = useSelector((state: ReduxState) => state.currentSector);

  const [isUpdating, setIsUpdating] = useState(false);
  const [clickedSector, setClickedSector] = useState<SectorDetailSchema | null>(null);
  const [showAddPolygonModal, setShowAddPolygonModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [showWrongSectorAlert, setShowWrongSectorAlert] = useState(false);
  const [coordinates, setCoordinates] = useState<{ lng: number, lat: number }[]>([]);

  const currentSector = selectedFarm?.sectors?.find((sector) => sector.id === sectorId);
  const hasPolygon = currentSector?.coordinates && currentSector.coordinates.length > 0;

  const sectorInfoData = clickedSector ? {
    Nombre: clickedSector.name || 'Sin nombre',
    'Superficie (Ha)': clickedSector.theoreticalSurface?.toString() || 'N/A',
    'Tipo de riego': clickedSector.typeOfIrrigation || 'N/A',
  } : null;

  const polygons = selectedFarm?.sectors?.map((sector) => ({
    id: sector.id,
    coordinates: sector?.coordinates ?? [],
    isSelected: selectedSector?.id === sector.id || sector.id === sectorId,
    isLinked: sector.id === sectorId,
    onPolygonClick: () => {
      setClickedSector(sector);
      if (selectedSector?.id === sector.id) {
        dispatch(setCurrentSector(null));
      } else {
        const sectorObj = selectedFarm?.sectors?.find((s) => s.id === sector.id);
        if (sectorObj) dispatch(setCurrentSector(sectorObj));
      }
    },
  })) ?? [];

  const farmCoordinates = selectedFarm?.coordinates
    ? selectedFarm.coordinates.map(({ lat: farmLat, lng: farmLng }) => ({
      lat: typeof farmLat === 'string' ? parseFloat(farmLat) : farmLat,
      lng: typeof farmLng === 'string' ? parseFloat(farmLng) : farmLng,
    }))
    : null;

  const handleAddPolygon = () => {
    if (selectedFarm) {
      setShowAddPolygonModal(true);
    }
  };

  const handleSavePolygon = async (newCoordinates?: { lng: number, lat: number }[] | null) => {
    if (!newCoordinates || newCoordinates.length < 3) {
      enqueueSnackbar('Debe dibujar un polígono válido', { variant: 'error' });
      return;
    }

    const processedCoordinates = newCoordinates.map((coord) => ({
      lat: typeof coord.lat === 'string' ? parseFloat(coord.lat) : Number(coord.lat),
      lng: typeof coord.lng === 'string' ? parseFloat(coord.lng) : Number(coord.lng),
    }));

    const payload = {
      name: currentSector?.name || '',
      theoreticalSurface: (currentSector?.theoreticalSurface || 0).toString(),
      typeOfSoil: currentSector?.typeOfSoil || '',
      coordinates: processedCoordinates,
    };

    try {
      setIsUpdating(true);

      const response = await apiClient.sector.sectorUpdate(
        sectorId,
        payload,
      );

      if (response.data.sector) {
        const updatedSectors = selectedFarm?.sectors?.map((sector) => (
          sector.id === sectorId
            ? { ...sector, coordinates: processedCoordinates }
            : sector
        )) || [];

        dispatch(setAccountFarm({
          ...selectedFarm,
          sectors: updatedSectors,
        } as FarmSchema));

        enqueueSnackbar('Polígono guardado correctamente', { variant: 'success' });
        setShowAddPolygonModal(false);
      } else {
        enqueueSnackbar('Error al guardar el polígono', { variant: 'error' });
      }
    } catch (error) {
      if (error instanceof Error) {
        const axiosError = error as any;
        if (axiosError.response) {
          console.log('Datos de la respuesta de error:', axiosError.response.data);
          console.log('Estado HTTP:', axiosError.response.status);
        } else if (axiosError.request) {
          console.log('La solicitud se hizo pero no se recibió respuesta:', axiosError.request);
        } else { console.log('Error al configurar la solicitud:', error.message); }
        enqueueSnackbar('Error al guardar el polígono', { variant: 'error' });
      }
    } finally {
      setIsUpdating(false);
    }
  };

  const handleShowDeleteConfirm = () => {
    // Verificar si hay un sector seleccionado que no sea el sector actual
    if (selectedSector && selectedSector.id !== sectorId) {
      // Mostrar alerta de que no se puede eliminar polígono de otro sector
      setShowWrongSectorAlert(true);
      return;
    }

    // Si no hay otro sector seleccionado o es el sector actual, mostrar confirmación
    setShowDeleteConfirmModal(true);
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirmModal(false);
  };

  const handleCloseWrongSectorAlert = () => {
    setShowWrongSectorAlert(false);
  };

  const handleDeletePolygon = async () => {
    try {
      setIsUpdating(true);

      if (!currentSector) {
        enqueueSnackbar('No se pudo encontrar el sector', { variant: 'error' });
        return;
      }

      const payload = {
        name: currentSector.name || '',
        theoreticalSurface: (currentSector.theoreticalSurface || 0).toString(),
        typeOfSoil: currentSector.typeOfSoil || '',
        coordinates: [],
      };

      const response = await apiClient.sector.sectorUpdate(
        sectorId,
        payload,
      );

      if (response.data.sector) {
        const updatedSectors = selectedFarm?.sectors?.map((sector) => (
          sector.id === sectorId
            ? { ...sector, coordinates: [] }
            : sector
        )) || [];

        dispatch(setAccountFarm({
          ...selectedFarm,
          sectors: updatedSectors,
        } as FarmSchema));

        enqueueSnackbar('Polígono eliminado correctamente', { variant: 'success' });
        setShowDeleteConfirmModal(false);
      } else {
        enqueueSnackbar('Error al eliminar el polígono', { variant: 'error' });
      }
    } catch (error) {
      console.error('Error al eliminar el polígono:', error);
      enqueueSnackbar('Error al eliminar el polígono', { variant: 'error' });
    } finally {
      setIsUpdating(false);
    }
  };

  const handleCloseModal = () => {
    setShowAddPolygonModal(false);
  };

  return (
    <>
      <Box mt={3} mb={3}>
        <Card>
          <CardContent>
            <CardHeader
              title="Polígonos"
              ActionButton={
                <Box width="20em">
                  {!hasPolygon ? (
                    <CustomButton
                      buttonText="Agregar polígono"
                      color="blue"
                      size="small"
                      onClick={handleAddPolygon}
                      disabled={!selectedFarm || isUpdating}
                    />
                  ) : (
                    <CustomButton
                      buttonText="Eliminar polígono"
                      color="red"
                      size="small"
                      onClick={handleShowDeleteConfirm}
                      disabled={!selectedFarm || isUpdating}
                    />
                  )}
                </Box>
              }
            />
            <Divider className={classes.divider} />
            <Box sx={{
              height: '800px',
              width: '100%',
              aspectRatio: '1/1',
              maxHeight: '800px',
              margin: '0 auto',
            }}>
              {selectedFarm && (
                <GoogleMaps
                  lat={currentSector?.coordinates?.[0]?.lat || selectedFarm.center.lat}
                  lng={currentSector?.coordinates?.[0]?.lng || selectedFarm.center.lng}
                  drawable="none"
                  farm={{
                    id: selectedFarm?.id ?? '',
                    coordinates: farmCoordinates,
                    zoom: selectedFarm?.zoom ?? 18,
                  }}
                  polygons={polygons}
                  sectorInfo={sectorInfoData ? { data: sectorInfoData } : null}
                />
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>

      {showAddPolygonModal && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1300,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px',
          }}
        >
          <Box
            sx={{
              backgroundColor: 'background.paper',
              borderRadius: 1,
              width: '90%',
              height: '90%',
              maxWidth: '1400px',
              maxHeight: '900px',
              display: 'flex',
              flexDirection: 'column',
              boxShadow: 24,
              position: 'relative',
              padding: '0 24px 24px 24px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                overflow: 'hidden',
              }}
            >
              <Box
                sx={{
                  padding: '16px 0',
                  borderBottom: '1px solid',
                  borderColor: 'divider',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                <Typography variant="h5" component="h2">
                  Agregar nuevo polígono al sector
                </Typography>
                <IconButton
                  onClick={handleCloseModal}
                  sx={{
                    position: 'absolute',
                    right: 0,
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              </Box>

              <Box
                sx={{
                  flex: 1,
                  margin: '16px 0',
                  border: '1px solid',
                  borderColor: 'divider',
                  borderRadius: 1,
                  overflow: 'hidden',
                }}
              >
                <GoogleMaps
                  farm={{
                    id: selectedFarm?.id ?? '',
                    coordinates: farmCoordinates,
                    zoom: selectedFarm?.zoom ?? 18,
                  }}
                  lat={selectedFarm?.center.lat?.toString() || ''}
                  lng={selectedFarm?.center.lng?.toString() || ''}
                  drawable='polygon'
                  polygons={selectedFarm?.sectors ?? []}
                  setCoordinates={
                    setCoordinates as React.Dispatch<
                    React.SetStateAction<{ lng: number; lat: number; }[] | null>
                    >
                  }
                />
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 2,
                }}
              >
                <Button
                  variant="contained"
                  color="inherit"
                  onClick={handleCloseModal}
                >
                  Atrás
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleSavePolygon(coordinates)}
                  disabled={isUpdating}
                >
                  Agregar
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      <Dialog
        open={showDeleteConfirmModal}
        onClose={handleCancelDelete}
        maxWidth="xs"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 1,
            padding: 0,
            margin: 0,
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: 'center',
            fontSize: '1.5rem',
            fontWeight: 500,
            borderBottom: '2px solid #3f51b5',
            padding: '16px 24px',
            position: 'relative',
          }}
        >
          Eliminar Polígono
          <IconButton
            aria-label="close"
            onClick={handleCancelDelete}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'grey.500',
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: '24px', paddingTop: '24px' }}>
          <Typography variant="body1" sx={{ textAlign: 'center', margin: '20px 0' }}>
            ¿Estás seguro de que quieres eliminar este polígono?
          </Typography>
        </DialogContent>
        <DialogActions sx={{
          padding: '16px 24px',
          justifyContent: 'space-between',
          '& > button': {
            width: '45%',
            textTransform: 'uppercase',
            padding: '10px 0',
            fontWeight: 'bold',
          },
        }}>
          <Button
            onClick={handleCancelDelete}
            variant="contained"
            sx={{
              backgroundColor: '#455a79',
              '&:hover': {
                backgroundColor: '#324158',
              },
            }}
          >
            CANCELAR
          </Button>
          <Button
            onClick={handleDeletePolygon}
            variant="contained"
            color="error"
            disabled={isUpdating}
            sx={{
              backgroundColor: '#e63757',
              '&:hover': {
                backgroundColor: '#d32f2f',
              },
            }}
          >
            ELIMINAR
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={showWrongSectorAlert}
        autoHideDuration={6000}
        onClose={handleCloseWrongSectorAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseWrongSectorAlert}
          severity="warning"
          variant="filled"
          sx={{ width: '100%' }}
        >
          No puedes eliminar el polígono de otro sector. Por favor, selecciona el del sector actual.
        </Alert>
      </Snackbar>
    </>
  );
}

export default SectorPolygonMap;
