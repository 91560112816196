import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles: () => Record<string, string> = makeStyles((theme: Theme) => ({
  mobileDrawer: {
    top: 64,
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
  logo: {
    maxWidth: '300px',
    maxHeight: '100px',
  },
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
  },
  buttonLeaf: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  title: {
    marginRight: 'auto',
  },
  active: {
    '& $title': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  activeParent: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '& $title': {
      color: theme.palette.primary.white,
    },
    '& $icon': {
      color: theme.palette.primary.white,
    },
  },
  activeText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  openCloseDrawerBox: {
    width: '24px',
    height: '24px',
    position: 'absolute',
    transition: 'left 0.2s ease',
    backgroundColor: 'white',
    border: '1px solid lightgray',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  openDrawerIcon: {
    top: '64px',
    left: '255px',
  },
  closedDrawerIcon: {
    top: '64px',
    left: '0px',
  },
}));

export default useStyles;
