import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';
import { ConversationSchema, TimePointSchema } from '../../../server/schemas/apiTypes';
import { ScatterPoint } from './types';

dayjs.extend(utc);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);

const checkExistingConversation = (
  timePoint: TimePointSchema,
  existingConversations?: Array<ConversationSchema>,
) => {
  if (!existingConversations || !timePoint) return false;

  return existingConversations.some((conversation) => {
    const existingTimePoint = conversation.timePoint;
    return (
      existingTimePoint.year === timePoint.year
      && existingTimePoint.month === timePoint.month
      && existingTimePoint.day === timePoint.day
      && (!timePoint.hour || existingTimePoint.hour === timePoint.hour)
    );
  });
};

function getGranularity(startDate: string, endDate: string): 'hourly' | 'daily' | 'weekly' {
  const start = dayjs(startDate);
  const end = dayjs(endDate);

  // Calculate difference in days
  const diffInDays = end.diff(start, 'day', true);

  if (diffInDays <= 1) {
    return 'hourly';
  }
  if (diffInDays > 33) {
    return 'weekly';
  }
  return 'daily';
}

function mapDates(data: any, granularity: 'hourly' | 'daily' | 'weekly', itemKey: string, dateKey = 'date') {
  return new Map(
    data.map((item: any) => {
      const key = item[dateKey];
      return [key, item[itemKey]];
    }),
  );
}

function buildCategories(
  granularity: 'hourly' | 'daily' | 'weekly',
  categories: any[],
): string[] {
  if (granularity === 'hourly') {
    return categories.map((category) => {
      if (category === '') {
        return '';
      }
      return dayjs(category).utc().format('HH:mm');
    });
  }

  if (granularity === 'daily' || granularity === 'weekly') {
    return categories.map((category) => {
      if (category === '') {
        return '';
      }
      return dayjs(category).utc().format('MMM DD');
    });
  }

  return categories;
}

const buildLinearCategories = (
  timestamps: string[],
): string[] => timestamps.map((timestamp, index, arr) => {
  const currentDate = dayjs(timestamp);

  if (index === 0 || currentDate.date() !== dayjs(arr[index - 1]).date()) {
    return currentDate.format('MMM DD');
  }

  return ''; // Return empty string for all other timestamps
});

const buildCategoriesFromDates = (
  granularity: 'hourly' | 'daily' | 'weekly',
  startDate: string,
  endDate: string,
) => {
  const start = dayjs(startDate).utc();
  const end = dayjs(endDate).utc();
  const uniqueDates: string[] = [];

  let current = start;

  if (granularity === 'daily') {
    while (current.isSameOrBefore(end)) {
      uniqueDates.push(current.format('YYYY-MM-DD HH:mm:ss'));
      current = current.add(1, 'day');
    }
  } else {
    while (current.isSameOrBefore(end)) {
      uniqueDates.push(current.format('YYYY-MM-DD'));
      current = current.add(1, 'day');
    }
  }

  return uniqueDates;
};

interface WeeklyDataEntry {
  date: string;
  startWeekDay: string;
  weekEnd: string;
  weekAverage: number;
}

function getWeeklyAverageData(data: Map<string, number>): Map<string, number> {
  const weeklyData: WeeklyDataEntry[] = [];
  const entries = Array.from(data.entries());

  for (let i = 0; i < entries.length; i += 7) {
    const weekEntries = entries.slice(i, i + 7);
    if (weekEntries.length > 0) {
      const startWeekDay = weekEntries[0][0];
      const weekEnd = weekEntries[weekEntries.length - 1]?.[0] || startWeekDay;

      const sum = weekEntries.reduce((acc, [, value]) => acc + value, 0);
      const average = sum / weekEntries.length;

      weeklyData.push({
        date: startWeekDay,
        startWeekDay,
        weekEnd,
        weekAverage: Number(average.toFixed(2)),
      });
    }
  }
  const response = mapDates(weeklyData, 'weekly', 'weekAverage') as Map<string, number>;
  return response;
}

interface WeeklyAddedDataEntry {
  date: string;
  startWeekDay: string;
  weekEnd: string;
  weekTotal: number;
}

function getWeeklyAddedData(data: Map<string, number>): Map<string, number> {
  const weeklyData: WeeklyAddedDataEntry[] = [];
  const entries = Array.from(data.entries());

  for (let i = 0; i < entries.length; i += 7) {
    const weekEntries = entries.slice(i, i + 7);
    if (weekEntries.length > 0) {
      const startWeekDay = weekEntries[0][0];
      const weekEnd = weekEntries[weekEntries.length - 1]?.[0] || startWeekDay;

      const total = weekEntries.reduce((acc, [, value]) => acc + value, 0);

      weeklyData.push({
        date: startWeekDay,
        startWeekDay,
        weekEnd,
        weekTotal: Number(total.toFixed(2)),
      });
    }
  }
  const response = mapDates(weeklyData, 'weekly', 'weekTotal') as Map<string, number>;
  return response;
}

function getWeekInterval(category: string) {
  const startDate = dayjs(category);
  const endDate = startDate.add(6, 'day');
  return `${startDate.format('MMM DD')} - ${endDate.format('MMM DD')}`;
}

function getTimePoint(pointIndex: number, absoluteCategories: string[]) {
  if (!absoluteCategories[pointIndex]) return null;

  const category = absoluteCategories[pointIndex];

  // Formato esperado: YYYY-MM-DD HH:mm:ss o YYYY-MM-DD HH:mm
  const dateMatch = category.match(/^(\d{4})-(\d{2})-(\d{2})\s+(\d{2}):(\d{2})(?::(\d{2}))?/);

  if (dateMatch) {
    const [, year, month, day, hour, minutes, seconds] = dateMatch;
    return {
      year,
      month,
      day,
      hour: `${hour}:${minutes}${seconds ? `:${seconds}` : ''}`,
    };
  }

  // Si por alguna razón no viene con hora, intentamos el formato simple YYYY-MM-DD
  const simpleDateMatch = category.match(/^(\d{4})-(\d{2})-(\d{2})/);
  if (simpleDateMatch) {
    const [, year, month, day] = simpleDateMatch;
    return {
      year,
      month,
      day,
      hour: '00:00:00',
    };
  }

  return null;
}

// Creo que acá hay que hacer el agroup en caso de que haya varios comentarios en el mismo punto
const getScatterConversations = (
  conversations: Array<ConversationSchema>,
  granularity: 'hourly' | 'daily' | 'weekly',
  absoluteCategories: Array<string>,
  yAxisRange: { min: number, max: number },
): ScatterPoint[] => {
  if (!conversations || !absoluteCategories.length) return [];

  const scatterConversations = conversations
    .map((conversation) => {
      const { timePoint } = conversation;
      let searchDate: string;

      if (granularity === 'hourly' || granularity === 'daily') {
        // Para hourly y daily necesitamos todos los campos
        if (!timePoint.hour || !timePoint.day || !timePoint.month || !timePoint.year) return null;
        searchDate = `${timePoint.year}-${timePoint.month}-${timePoint.day} ${timePoint.hour}`;
      } else { // cambiar a else if cuando se agregue el formato monthly
        // Para weekly necesitamos tres de los campos
        if (!timePoint.day || !timePoint.month || !timePoint.year) return null;
        searchDate = `${timePoint.year}-${timePoint.month}-${timePoint.day}`;
      }

      // Encontrar el índice en absoluteCategories
      const categoryIndex = absoluteCategories.findIndex(
        (category) => category === searchDate,
      );
      if (categoryIndex === -1) return null;

      return {
        x: categoryIndex,
        y: yAxisRange.min + (yAxisRange.max - yAxisRange.min) * 0.5,
        custom: {
          conversationId: conversation.conversationId,
          timePointData: timePoint,
        },
      };
    })
    .filter((point) => point !== null);

  return scatterConversations as ScatterPoint[];
};

export const formatDate = (isoDate: string, includeSeconds = true) => {
  const date = new Date(isoDate);

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  if (includeSeconds) {
    return `${day}-${month}-${year} / ${hours}:${minutes}:${seconds}`;
  }
  return `${day}-${month}-${year} ${hours}:${minutes}`;
};

export const stringToColor = (string: string) => {
  let hash = 0;
  for (let i = 0; i < string.length; i += 1) {
    hash = Math.abs((hash * 31) + string.charCodeAt(i));
  }

  const colors = [
    '#385576',
    '#1450A4',
    '#3498D1',
    '#1B6CB4',
    '#70BF07',
    '#C2E84D',
    '#429655',
    '#1A3B21',
    '#82480A',
    '#3A7B9E',
  ];

  return colors[hash % colors.length];
};

export const calculateDateRangeFromNotification = (dateParam: string) => {
  const [day, month, year] = dateParam.split('-').map(Number);
  const targetDate = dayjs().year(year).month(month - 1).date(day);
  const rangeStartDate = targetDate.subtract(3, 'day').format('YYYY-MM-DD');
  const rangeEndDate = targetDate.add(3, 'day').format('YYYY-MM-DD');
  return {
    rangeStartDate,
    rangeEndDate,
  };
};

export {
  getGranularity,
  buildCategories,
  buildLinearCategories,
  buildCategoriesFromDates,
  mapDates,
  getWeeklyAverageData,
  getWeeklyAddedData,
  getWeekInterval,
  getTimePoint,
  getScatterConversations,
  checkExistingConversation,
};
