import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  MenuItem,
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../styles';
import { ReduxState } from '../../../../types';
import { setCreateUserData } from '../../../../actions/userActions';
import validateEmail from '../../../../utils/validator';
import { FIELDS_EQ, REQUIRED_TEXT_INPUT, RequiredFields } from '../types';
import {
  UserType, UserRole,
  translateUserTypeRole,
} from '../../../../constants/user_types';

function UserUpsertForm() {
  const [errors, setErrors] = useState({
    name: { active: false, message: '' },
    lastname: { active: false, message: '' },
    email: { active: false, message: '' },
    type: { active: false, message: '' },
    role: { active: false, message: '' },
  });
  const [isEdit, setIsEdit] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReduxState) => state.user);

  React.useEffect(() => {
    setIsEdit(!!user);
  }, []);

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    source: string,
  ) => {
    setErrors((prevState) => ({ ...prevState, [source]: { active: false, message: '' } }));
    dispatch(setCreateUserData({ [event.target.name]: event.target.value }));
  };

  const handleBlur = (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>,
    source: string,
  ) => { // cambiar despues
    if (REQUIRED_TEXT_INPUT.includes(source) && e.target.value.trim() === '') {
      setErrors((prevState) => ({ ...prevState, [source]: { active: true, message: `${FIELDS_EQ[source as RequiredFields]} no puede estar vacío` } }));
    } else if (source === 'email') {
      const isValidEmail = validateEmail(e.target.value);
      if (!isValidEmail) {
        setErrors((prevState) => ({ ...prevState, [source]: { active: true, message: `${FIELDS_EQ[source as RequiredFields]} no es válido` } }));
      }
    }
  };

  return (
    <Card className={classes.cardContainer}>
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={3}
            xs={12}
          >
            <TextField
              error={errors.name.active}
              fullWidth
              helperText={errors.name.message}
              label={'Nombre'}
              name="name"
              onBlur={(e) => handleBlur(e, 'name')}
              onChange={(e) => handleChange(e, 'name')}
              required
              value={user?.name || ''}
              variant="standard"
            />
          </Grid>
          <Grid
            item
            md={3}
            xs={12}
          >
            <TextField
              error={errors.lastname.active}
              fullWidth
              helperText={errors.lastname.message}
              label={'Apellidos'}
              name="lastname"
              onBlur={(e) => handleBlur(e, 'lastname')}
              onChange={(e) => handleChange(e, 'lastname')}
              required
              value={user?.lastname || ''}
              variant="standard"
            />
          </Grid>
          <Grid
            item
            md={3}
            xs={12}
          >
            <TextField
              error={errors.email.active}
              fullWidth
              helperText={errors.email.message}
              label={'Correo'}
              name="email"
              onBlur={(e) => handleBlur(e, 'email')}
              onChange={(e) => handleChange(e, 'email')}
              required
              value={user?.email || ''}
              variant="standard"
            />
          </Grid>
          <Grid
            item
            md={3}
            xs={12}
          >
            <TextField
              fullWidth
              label={'Teléfono'}
              name="phoneNumber"
              onChange={(e) => handleChange(e, 'phoneNumber')}
              value={user?.phoneNumber || ''}
              variant="standard"
            />
          </Grid>
          <Grid
            item
            md={3}
            xs={12}
          >
            <TextField
              fullWidth
              label={'Tipo'}
              name="type"
              onChange={(e) => handleChange(e, 'type')}
              select
              required
              value={user?.type || ''}
              variant="standard"
            >
              <MenuItem
                value={UserType.CDTEC}
              >
                CDTEC
              </MenuItem>
              <MenuItem
                value={UserType.CONSULTANT}
              >
                Asesor
              </MenuItem>
              <MenuItem
                value={UserType.CLIENT}
              >
                Cliente
              </MenuItem>
              <MenuItem
                value={UserType.MULTI_ACCOUNT_CLIENT}
              >
                Cliente Multicuenta
              </MenuItem>
            </TextField>
          </Grid>
          <Grid
            item
            md={3}
            xs={12}
          >
            <TextField
              fullWidth
              label={'Rol'}
              name="role"
              onChange={(e) => handleChange(e, 'role')}
              select
              required
              value={user?.role || ''}
              variant="standard"
            >
              {/* Iterate over UserRole enum values to create MenuItems */}
              {Object.values(UserRole).map((role) => (
                <MenuItem key={role} value={role}>
                  {/* Capitalize the first letter for display */}
                  {translateUserTypeRole[role]}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {isEdit && <Grid
            item
            md={3}
            xs={12}
          >
            <TextField
              fullWidth
              label={'Activo'}
              name="active"
              onChange={(e) => handleChange(e, 'active')}
              select
              required
              value={user?.active?.toString() || 'false'}
              variant="standard"
            >
              <MenuItem
                value='true'
              >
                Si
              </MenuItem>
              <MenuItem
                value='false'
              >
                No
              </MenuItem>
            </TextField>
          </Grid>}
        </Grid>
      </CardContent >
    </Card>
  );
}

export default UserUpsertForm;
