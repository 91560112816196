import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginLeft: '20px',
  },
  chartCard: {
    marginTop: theme.spacing(2),
  },
  dashboardContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    height: '100%',
  },
  dashboardChartContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  dashboardAccordion: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    gap: '0px',
    overflow: 'hidden',
  },
  dashboardChart: {
    width: '100%',
    height: '100%',
    display: 'flex',
    backgroundColor: 'blue',
  },
  dashboardChartTitleContainerClosed: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    height: '43px',
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  dashboardChartTitleContainer: {
    flex: '0 0 30px', // Ancho fijo de 30px para el título
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dashboardChartTitle: {
    writingMode: 'vertical-rl', // Texto en orientación vertical
    transform: 'rotate(180deg)', // Ajusta el texto para que sea legible
    textAlign: 'center',
    marginLeft: '10px',
    fontSize: '14px',
    fontFamily: 'Roboto',
    color: theme.palette.primary.main,
  },
  dashboardChartTitleClosed: {
    display: 'block',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '15px',
    marginLeft: '20px',
  },
  iconButtonsContainer: {
    position: 'absolute',
    top: '12px',
    right: '20px',
    display: 'flex',
    gap: '6px',
    zIndex: 1,
  },
  iconButtonsContainerDashboard: {
    position: 'absolute',
    top: '5px',
    right: '20px',
    display: 'flex',
    gap: '6px',
    zIndex: 2, // Asegura que esté sobre el gráfico
    backgroundColor: 'transparent',
  },
  accordionSummary: {
    padding: 0,
  },
  accordionDetails: {
    marginLeft: '10px',
    padding: 0,
  },
  selectFieldsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    width: '100%',
  },
  divider: {
    margin: '5px 0px',
    width: '100%',
  },
  layoutMapChip: {
    margin: '2px',
  },
  selectField: {
    flex: 1,
  },
  chipsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '8px',
    padding: '4px 0',
  },
  chipsLabel: {
    color: theme.palette.text.secondary,
    fontSize: '0.7rem',
  },
  filterButton: {
    fontSize: '10px',
    padding: '4px',
  },
  button: {
    marginBottom: '5px',
    marginRight: '15px',
    marginLeft: '15px',
    padding: '8px 16px',
    backgroundColor: '#3693d5',
    color: 'white',
    border: 'none',
    borderRadius: '2px',
    cursor: 'pointer',
  },

  // CONFIGS
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  tabList: {
    paddingTop: theme.spacing(1),
  },
  tabLabel: {
    textTransform: 'none',
  },
  configCard: {
    maxWidth: '35%',
  },
  configTabPanel: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: theme.spacing(1),
  },
  configCardRoots: {
    maxWidth: '20%',
  },

  // ConversationTab
  conversationModalContainer: {
    width: '600px',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  conversationModalHeader: {
    display: 'flex',
    width: '100%',
    marginTop: '12px',
  },
  conversationModalHeaderTitle: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  activePageUnderlined: {
    borderBottom: '2px solid',
    borderBottomColor: theme.palette.secondary.main,
  },
  conversationTabContainer: {
    position: 'relative',
  },
  addCommentButton: {
    position: 'absolute',
    top: `-${theme.spacing(6)}`,
    right: 0,
    zIndex: 1,
  },
  // Comments
  commentsList: {
    maxHeight: '400px',
    overflowY: 'auto',
  },
  commentContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
  },
  commentHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    gap: theme.spacing(1),
  },
  commentAuthor: {
    fontWeight: 500,
  },
  commentDate: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
  },
  commentSeparator: {
    color: theme.palette.text.secondary,
  },
  commentMessage: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  newCommentSection: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

export default useStyles;
