import { useLocation } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import Container from '@mui/material/Container';
import {
  Box, Card, CardContent, Tab, TextField, CircularProgress,
  Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography,
} from '@mui/material';
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import CustomHeader from '../../General/CustomHeader';
import Page from '../../Layout/Page';
import useStyles from '../styles';
import Et0Chart from './Et0Chart';
import useFetchApi from '../../../hooks/useFetchApi';
import { GetSectorEt0ConfigResponse, ConversationSchema, TimePointSchema } from '../../../requests/api/apiTypes';
import { FetchError } from '../../../views/types';
import apiClient from '../../../requests/api/apiClient';
import { ET0_NAME } from '../../../constants/graphs';
import ConversationsTab from '../ConversationTab';
import { ReduxState } from '../../../types';

const Et0 = () => {
  const classes = useStyles();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  // Check if we're coming from a notification and set default tab to conversations
  const source = queryParams.get('source');
  const initialTabValue = source === 'notification' ? '2' : '1';

  const [tabValue, setTabValue] = useState(initialTabValue);
  const { selectedFarm } = useSelector((state: ReduxState) => state.accountFarm);
  const { selectedSector } = useSelector((state: ReduxState) => state.currentSector);
  const [isLoading, setIsLoading] = useState(true);

  const [conversations, setConversations] = useState<ConversationSchema[]>([]);
  const [conversationsLoading, setConversationsLoading] = useState(false);
  const [modalState, setModalState] = useState<{
    open: boolean;
    conversationId: string;
    timePointData: TimePointSchema;
  }>({
    open: false,
    conversationId: '',
    timePointData: {
      hour: '',
      day: '',
      month: '',
      year: '',
    },
  });

  const params = {
    farmId: queryParams.get('farmId') || '',
    sectorId: queryParams.get('sectorId') || '',
  };

  const { enqueueSnackbar } = useSnackbar();
  const [accSinceDate, setAccSinceDate] = useState('');

  // Load conversations when component mounts
  useEffect(() => {
    const loadConversations = async () => {
      if (!params.sectorId) return;
      setConversationsLoading(true);
      try {
        const { data } = await apiClient.conversation.conversationDetail(
          params.sectorId,
          ET0_NAME,
        );
        setConversations(data.conversations);
      } catch (err) {
        const { error } = err as FetchError;
        enqueueSnackbar(error.message, { variant: 'error' });
      } finally {
        setConversationsLoading(false);
      }
    };

    loadConversations();
  }, [params.sectorId, enqueueSnackbar]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const handleDateChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setAccSinceDate(event.target.value);
    try {
      const { data: response } = await apiClient.et0Config.et0ConfigCreate(
        params.sectorId,
        { accumulateFrom: event.target.value },
      );
      enqueueSnackbar(response.message, { variant: 'success' });
    } catch (err) {
      const { error } = err as FetchError;
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const handleCloseModal = () => {
    setModalState({
      open: false,
      conversationId: '',
      timePointData: {
        hour: '',
        day: '',
        month: '',
        year: '',
      },
    });
  };

  const { data, loading } = useFetchApi<GetSectorEt0ConfigResponse>({
    modelEndpoint: 'et0ConfigDetail',
    modelName: 'et0Config',
    id: params.sectorId,
    dependencies: [params.sectorId],
  });

  useEffect(() => {
    if (!loading) {
      setAccSinceDate(data?.et0Config?.accumulateFrom || new Date().toISOString().split('T')[0]);
      setIsLoading(false);
    }
  }, [data, loading]);

  const breadcrumb = useMemo(() => {
    const sectorName = selectedFarm?.sectors?.find(
      (sector) => sector.id === selectedSector?.id,
    )?.name || selectedSector?.name;

    return `${selectedFarm?.name}/${sectorName}/Configuración`;
  }, [selectedFarm, selectedSector]);

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Page
      className={classes.root}
      title='Evapotranspiración'
    >
      <Container maxWidth={false}>
        <CustomHeader title={breadcrumb} />
        <Box mt={3}>
          <Et0Chart
            chartView="settings"
            sectorId={params.sectorId}
            farmId={params.farmId}
          />
        </Box>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 0 }}>
            <TabList onChange={handleTabChange} aria-label="sector tabs">
              <Tab label="Configuración" value="1" className={classes.tabLabel}/>
              <Tab label="Conversaciones" value="2" className={classes.tabLabel}/>
            </TabList>
          </Box>
          <TabPanel value="1" className={classes.configTabPanel}>
            <Card className={classes.configCard}>
              <CardContent>
                <Box className={classes.selectFieldsContainer}>
                  <TextField
                    className={classes.selectField}
                    label="Acumular desde"
                    type="date"
                    value={accSinceDate}
                    onChange={handleDateChange}
                    fullWidth
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
              </CardContent>
            </Card>
          </TabPanel>
          <TabPanel value="2">
            <ConversationsTab
              setModalState={setModalState}
              conversations={conversations}
              conversationsLoading={conversationsLoading}
            />
          </TabPanel>
        </TabContext>
      </Container>

      {/* Dialog for conversation details */}
      <Dialog open={modalState.open} onClose={handleCloseModal} maxWidth="md" fullWidth>
        <DialogTitle>Detalle de Conversación</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            {`Fecha: ${modalState.timePointData.day}/${modalState.timePointData.month}/${modalState.timePointData.year} ${modalState.timePointData.hour}`}
          </Typography>
          <Typography variant="body1">ID: {modalState.conversationId}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};
export default Et0;
