import React from 'react';
import {
  Grid,
  Typography,
  Box,
  IconButton,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import { useHistory } from 'react-router-dom';

import useStyles from './styles';

function CustomHeader({
  title,
  ActionButton,
  TabsComponent,
  showBackButton = true,
}: {
  title: string,
  ActionButton?: JSX.Element,
  TabsComponent?: JSX.Element,
  showBackButton?: boolean,
}) {
  const classes = useStyles();
  const history = useHistory();

  const shouldShowBackButton = showBackButton
    && !TabsComponent;

  const parsedTitle = title.split('/').map((elem, index, arr) => {
    if (index === (arr.length - 1)) {
      return <React.Fragment key={index}>
        {' '}
        <span className={classes.activePageUnderlined}>
          {elem.trim()}
        </span>
      </React.Fragment>;
    }
    return (
      <React.Fragment key={index}>
        {elem.trim()} /
      </React.Fragment>
    );
  });

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      spacing={3}
      alignItems="center"
    >
      <Grid item container alignItems="center" xs>
        {shouldShowBackButton && (
          <Grid item>
            <IconButton onClick={handleGoBack} aria-label="go back">
              <ArrowBackIcon />
            </IconButton>
          </Grid>
        )}
        <Grid item>
          <Typography
            variant="h3"
            color="textPrimary"
          >
            {parsedTitle}
          </Typography>
        </Grid>
      </Grid>
      {ActionButton && <Grid item>
        {ActionButton}
      </Grid>}
      {TabsComponent && (
        <Grid item>
          <Box>
            {TabsComponent}
          </Box>
        </Grid>
      )}
    </Grid>
  );
}

export default CustomHeader;
