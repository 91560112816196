import { NotificationSchema } from '../requests/api/apiTypes';

export const SET_NOTIFICATIONS = '@notifications/set-notifications';
export const ADD_NOTIFICATION = '@notifications/add-notification';
export const DELETE_NOTIFICATION = '@notifications/delete-notification';
export const UPDATE_NOTIFICATION = '@notifications/update-notification';

export const setNotifications = (notifications: NotificationSchema[]) => ({
  type: SET_NOTIFICATIONS,
  payload: [...notifications],
});

export const addNotification = (notification: NotificationSchema) => ({
  type: ADD_NOTIFICATION,
  payload: notification,
});

export const deleteNotification = (notificationId: string) => ({
  type: DELETE_NOTIFICATION,
  payload: notificationId,
});

export const updateNotification = (notification: NotificationSchema) => ({
  type: UPDATE_NOTIFICATION,
  payload: notification,
});
