import React, { useState } from 'react';
import {
  Box,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Divider,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'; // Círculo de notificación
import { convertUTCToLocalSantiago } from '../../utils/formatDate';
import useStyles from './styles';

interface NotificationItemProps {
  notification: {
    id: string;
    title: string;
    message?: string;
    createdAt: string;
    type: string;
    sector?: {
      name: string;
      wiseconnZoneId: string;
      id?: string;
    } | string;
    farm?: {
      name: string;
      id: string;
    };
    createdBy?: {
      name: string;
      lastname: string;
    };
    highchartName?: string;
    is_read: boolean;
    path?: string;
    conversationId?: {
      _id: string;
      timePoint?: {
        day: number;
        month: number;
        year: number;
      };
    } | string;
  };
  handleMarkAsRead: (id: string) => void;
  handleMarkAsUnread: (id: string) => void;
  handleDeleteNotification: (id: string) => void;
  handleRedirectToChart: (id: string, path?: string, sectorId?: string, farmId?: string) => void;
}

const notificationTypesMap: { [key: string]: string } = {
  comment: 'Comentario',
  alert: 'Alerta',
  info: 'Información',
};

const NotificationItem: React.FC<NotificationItemProps> = (
  {
    notification,
    handleMarkAsRead,
    handleMarkAsUnread,
    handleDeleteNotification,
    handleRedirectToChart,
  },
) => {
  const classes = useStyles();
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  const getBackgroundColor = (type: string) => {
    switch (type) {
      case 'comment':
        return '#EBF4FB';
      case 'alert':
        return '#FD1E4A1A';
      default:
        return 'transparent';
    }
  };

  return (
    <React.Fragment>
      <Box
        className={classes.notificationItem}
        sx={{ backgroundColor: getBackgroundColor(notification.type || 'transparent') }}
      >
        <Box className={classes.notificationContent}>
          {/* Información de la notificación a la izquierda */}
          <Box className={classes.notificationText}>
            <Typography variant="h6" className={classes.notificationTitle}>
              {notification.title}
            </Typography>
            <Typography className={classes.notificationBody}>
              <strong>Fecha:</strong> {convertUTCToLocalSantiago(notification.createdAt || '')}
            </Typography>
            {notification.type === 'alert' && (
              <Typography className={classes.notificationBody}>
                <strong>Tipo de alerta:</strong> {notificationTypesMap[notification.type]}
              </Typography>
            )}
            {notification.sector && (
              <Typography className={classes.notificationBody}>
                <strong>Sector:</strong> {typeof notification.sector === 'string' ? notification.sector : notification.sector.name}
              </Typography>
            )}
            {notification.highchartName && (
              <Typography className={classes.notificationBody}>
                <strong>Gráfico:</strong> {`${notification.highchartName}-${convertUTCToLocalSantiago(notification.createdAt || '')}`}
              </Typography>
            )}
            {notification.message && (
              <Typography className={classes.notificationBody}>
                {notification.type === 'comment' && notification.createdBy ? (
                  <>
                    <strong>
                      {`${notification.createdBy.name} ${notification.createdBy.lastname}: `}
                    </strong>
                    {notification.message}
                  </>
                ) : (
                  notification.message
                )}
              </Typography>
            )}
          </Box>

          {/* Contenedor para los botones alineados verticalmente a la derecha */}
          <Box className={classes.notificationMenuContainer}>
            {/* Menú de opciones (tres puntos) */}
            <IconButton onClick={handleOpenMenu} className={classes.menuButton}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={menuAnchor}
              open={Boolean(menuAnchor)}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={() => {
                const sectorId = typeof notification.sector === 'object' ? notification.sector.id : undefined;
                const farmId = notification.farm?.id;
                handleRedirectToChart(notification.id, notification.path, sectorId, farmId);
                handleCloseMenu();
              }}>
                Ir a gráfico
              </MenuItem>
              <MenuItem onClick={() => {
                handleDeleteNotification(notification.id);
                handleCloseMenu();
              }}>
                Eliminar
              </MenuItem>
              <MenuItem onClick={() => {
                handleMarkAsUnread(notification.id);
                handleCloseMenu();
              }}>
                Marcar como no leída
              </MenuItem>
            </Menu>

            {/* Círculo para marcar como leído (debajo del menú) */}
            {!notification.is_read && (
              <IconButton
                className={classes.readCircle}
                onClick={() => handleMarkAsRead(notification.id)}
              >
                <FiberManualRecordIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
      <Divider />
    </React.Fragment>
  );
};

export default NotificationItem;
