import React, { useState, useRef, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Fullscreen from 'highcharts/modules/full-screen';
import Draggable from 'highcharts/modules/draggable-points';
import ZoomModule from 'highcharts/modules/drag-panes';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  Typography,
  Box,
  IconButton,
  Button,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CommentIcon from '@mui/icons-material/Comment';
import SettingsIcon from '@mui/icons-material/Settings';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import useStyles from './styles';
import {
  getGranularity, getTimePoint, getScatterConversations, checkExistingConversation,
} from './utils';
import { ReduxState } from '../../types';
import { ConversationSchema, TimePointSchema } from '../../../server/schemas/apiTypes';
import { ScatterPoint } from './types';
import commentIcon from '../../assets/svg/graphComment.svg';
// Initialize the modules
Fullscreen(Highcharts);
Draggable(Highcharts);
ZoomModule(Highcharts);

type BaseHighchartProps = {
  chartView: 'dashboard' | 'graphs' | 'settings'
  chartType?: string,
  title?: string,
  series?: Array<Highcharts.SeriesOptionsType>,
  categories?: Array<string>,
  yAxisTitle?: string,
  xAxisTitle?: string,
  colors?: Array<string>,
  legend?: Highcharts.LegendOptions,
  tooltip?: Highcharts.TooltipOptions,
  chartConfig?: any,
  showFullScreen?: boolean,
  showMarkers?: boolean,
  xPlotBands?: Array<object>,
  yPlotBands?: Array<object>,
  hideYValues?: boolean,
  hideXValues?: boolean,
  button?: React.ReactNode,
  onConfigClick?: () => void,
  setModalState?: React.Dispatch<React.SetStateAction<{
    open: boolean,
    conversationId: string,
    timePointData: TimePointSchema,
  }>>,
  conversations?: Array<ConversationSchema>,
  absoluteCategories?: string[],
  height?: number | string,
  setHideDepthsText?: React.Dispatch<React.SetStateAction<boolean>>,
  stackButton?: {
    onClick: () => void;
    isStacked: boolean;
    component?: React.ReactNode;
  },
} & (
  | {
    chartView: 'settings'
    filter: React.ReactNode // filtro debe ir si estamos en settings
  }
  | {
    chartView: 'dashboard' | 'graphs'
    filter?: React.ReactNode // Opcional para otros casos
  }
);

function BaseHighchart({
  chartView,
  chartType = 'line',
  title = '',
  series = [],
  categories = [],
  yAxisTitle = '',
  xAxisTitle = '',
  colors = ['#536e7b', '#35a0d7', '#77d863', '#f7a35c', '#8085e9', '#f15c80', '#FEBF23', '#2b908f', '#f45b5b', '#91e8e1'],
  legend = { enabled: true },
  tooltip = {},
  chartConfig = {}, // Configuraciones personalizadas adicionales
  showFullScreen = true,
  showMarkers = false,
  xPlotBands = [],
  yPlotBands = [],
  hideYValues = false,
  hideXValues = false,
  button = null,
  filter,
  onConfigClick,
  setModalState,
  conversations,
  absoluteCategories,
  height = 300,
  setHideDepthsText,
  stackButton,
  ...props
}: BaseHighchartProps) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  const chartRef = useRef<HighchartsReact.RefObject>(null);
  const [isCommentModeActive, setIsCommentModeActive] = useState(false);
  const { startDate, endDate } = useSelector((state: ReduxState) => state.graphsFilter);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (chartRef.current?.chart) {
      chartRef.current.chart.reflow();
    }
  }, [expanded]);

  const handleCommentButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsCommentModeActive(!isCommentModeActive);
  };

  const handleFullScreenClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (chartRef.current?.chart) {
      chartRef.current.chart.fullscreen.toggle();
    }
  };

  const granularity = getGranularity(startDate, endDate);

  const renderCommentMarkers = () => {
    if (!conversations || !absoluteCategories?.length) return [];
    const yAxis = chartRef.current?.chart.yAxis[0];

    return [{
      type: 'scatter',
      name: 'Comentarios',
      data: getScatterConversations(
        conversations,
        granularity,
        absoluteCategories,
        { min: yAxis?.min ?? 0, max: yAxis?.max ?? 1 },
      ) as ScatterPoint[],
      showInLegend: false,
      marker: {
        symbol: `url(${commentIcon})`,
        width: 30,
        height: 30,
      },
      stickyTracking: false,
      events: {
        click(e: Highcharts.PointClickEventObject) {
          const point = e.point as ScatterPoint;
          if (setModalState && point.custom) {
            setModalState({
              open: true,
              conversationId: point.custom.conversationId,
              timePointData: point.custom.timePointData,
            });
          }
        },
      },
    }];
  };

  const defaultFontStyles = {
    fontSize: '11px',
    fontWeight: 'normal',
    color: '#385576',
    fontFamily: "'Roboto', sans-serif",
    letterSpacing: '0.25px',
  };

  const chartOptions = {
    chart: {
      type: chartType,
      alignTicks: true,
      marginLeft: chartView === 'dashboard' ? 30 : 60,
      height,
      spacingTop: legend.enabled ? 10 : 20,
      zoomType: 'x',
      panning: true,
      panKey: 'shift',
      resetZoomButton: {
        position: {
          align: 'right',
          verticalAlign: 'top',
          x: -10,
          y: 10,
        },
        theme: {
          fill: '#f7f7f7',
          stroke: '#cccccc',
          r: 4,
          states: {
            hover: {
              fill: '#e6e6e6',
              stroke: '#cccccc',
              style: {
                color: '#333333',
              },
            },
          },
        },
      },
    },
    title: {
      text: '',
    },
    xAxis: {
      categories,
      title: {
        text: xAxisTitle,
        style: {
          ...defaultFontStyles,
        },
      },
      alignTicks: true,
      tickmarkPlacement: 'on',
      gridLineWidth: 1,
      gridLineColor: '#E0E0E0',
      gridLineDashStyle: 'Dot',
      labels: {
        enabled: !hideXValues,
        style: {
          color: '#385576',
        },
      },
      plotBands: xPlotBands?.map((band: any) => ({
        from: band.from,
        to: band.to,
        color: band.color,
        label: {
          text: band.name || '',
          verticalAlign: 'bottom',
          align: 'right',
          y: -2,
          x: -10,
          useHTML: true,
          style: {
            color: '#000',
            fontSize: '11px',
          },
        },
      })),
      events: {
        afterSetExtremes(e: any) {
          console.log('Nuevo rango de zoom:', e.min, e.max);
        },
      },
    },
    yAxis: {
      title: {
        text: yAxisTitle,
        style: {
          ...defaultFontStyles,
          fontSize: '11px',
        },
        margin: 14,
      },
      labels: {
        enabled: !hideYValues,
        style: {
          color: '#385576',
        },
      },
      plotBands: yPlotBands?.map((band: any) => ({
        from: band.from,
        to: band.to,
        color: band.color,
        label: {
          text: band.name || '',
          verticalAlign: 'bottom',
          align: 'right',
          y: -2,
          x: -10,
          useHTML: true,
          style: {
            color: '#000',
            fontSize: '11px',
          },
        },
      })),
    },
    legend: {
      ...legend,
      itemStyle: {
        ...defaultFontStyles,
      },
      verticalAlign: 'top',
      layout: 'horizontal',
      align: 'left',
      itemDistance: 20,
      itemMarginBottom: chartView === 'dashboard' ? 0 : 36,
      x: 10,
    },
    tooltip: {
      ...tooltip,
      outside: true,
      style: {
        ...defaultFontStyles,
      },
      positioner(this: Highcharts.TooltipPositionerPointObject,
        labelWidth: number,
        labelHeight: number,
        point: Highcharts.Point) {
        const { chart } = this as unknown as { chart: Highcharts.Chart };
        let x = (point.plotX ?? 0) + chart.plotLeft;
        let y = (point.plotY ?? 0) + chart.plotTop;

        // Ensure tooltip doesn't go off the right side
        if (x + labelWidth > chart.chartWidth) {
          x = chart.chartWidth - labelWidth;
        }

        // Ensure tooltip doesn't go off the left side
        if (x < 0) {
          x = 0;
        }

        // Position above the point if there's room, otherwise below
        if (y - labelHeight > 0) {
          y -= labelHeight;
        } else {
          y += 20; // Add some padding when showing below
        }

        return { x, y };
      },
      valueDecimals: 2,
      shared: true,
    },
    colors,
    series: [...series, ...renderCommentMarkers()],
    credits: {
      enabled: false,
    },
    plotOptions: {
      scatter: {
        marker: {
          enabled: true,
          zIndex: 1000,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
      bar: {
        centerInCategory: true,
      },
      line: {
        lineWidth: 2,
        marker: {
          enabled: showMarkers,
        },
      },
      column: {
        stacking: 'normal',
      },
      series: {
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: true,
              radius: 5,
            },
          },
        },
        states: {
          hover: {
            lineWidthPlus: 1,
          },
        },
      },
    },
    lang: {
      noData: 'No hay datos para mostrar',
    },
    noData: {
      style: {
        fontSize: '18px',
        fontWeight: '400px',
      },
    },
  };

  const options = {
    ...chartOptions,
    ...chartConfig,
    ...props,
    plotOptions: {
      ...chartOptions.plotOptions,
      series: {
        point: {
          events: {
            click(this: Highcharts.Point, e: Highcharts.PointerEventObject) {
              if (isCommentModeActive && absoluteCategories) {
                const pointIndex = this.index;
                const timePoint = getTimePoint(pointIndex, absoluteCategories);

                if (setModalState && timePoint) {
                  const hasExistingConversation = checkExistingConversation(
                    timePoint,
                    conversations,
                  );

                  if (!hasExistingConversation) {
                    setModalState({ open: true, conversationId: '', timePointData: timePoint });
                  } else {
                    enqueueSnackbar('Ya existe un comentario en este punto', { variant: 'warning' });
                  }
                }

                setIsCommentModeActive(false);
                e.stopPropagation();
              }
            },
          },
        },
      },
    },
    chart: {
      ...chartOptions.chart,
      events: {
        click(this: Highcharts.Chart, e: Highcharts.PointerEventObject) {
          if (isCommentModeActive && absoluteCategories) {
            const xAxis = this.xAxis[0];
            const pointIndex = Math.round(xAxis.toValue(e.chartX));

            const timePoint = getTimePoint(pointIndex, absoluteCategories);

            if (setModalState && timePoint) {
              const hasExistingConversation = checkExistingConversation(timePoint, conversations);

              if (!hasExistingConversation) {
                setModalState({ open: true, conversationId: '', timePointData: timePoint });
              } else {
                enqueueSnackbar('Ya existe un comentario en este punto', { variant: 'warning' });
              }
            }

            setIsCommentModeActive(false);
          }
        },
      },
    },
  };

  return chartView === 'dashboard' ? (
    <div className={classes.dashboardChartContainer}>
      <Box className={expanded
        ? classes.dashboardChartTitleContainer : classes.dashboardChartTitleContainerClosed}
      >
        <Typography className={expanded
          ? classes.dashboardChartTitle
          : classes.dashboardChartTitleClosed} variant="h4"color="primary">
          {title}
        </Typography>
      </Box>
      <Accordion className={classes.dashboardAccordion}
        expanded={expanded}
        onChange={() => {
          if (setHideDepthsText) {
            setHideDepthsText(expanded);
          }
          setExpanded(!expanded);
        }}
        elevation={0}
        sx={{
          '& .Mui-expanded': {
            minHeight: '0px !important',
          },
        }}
      >
        <AccordionSummary className={classes.accordionSummary}
          sx={{
            '& .MuiAccordionSummary-content': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              margin: '0px !important',
            },
          }}
        >
          <Box className={classes.iconButtonsContainerDashboard}>
            {stackButton && (
              stackButton.component || (
                <Button
                  onClick={stackButton.onClick}
                  size="small"
                  variant="outlined"
                  style={{
                    backgroundColor: '#3693d5',
                    color: 'white',
                    border: '1px solid #3693d5',
                    marginRight: '8px',
                    textTransform: 'none',
                    padding: '4px 12px',
                  }}
                >
                  {stackButton.isStacked ? 'Apilar' : 'Valores Originales'}
                </Button>
              )
            )}
            <IconButton
              size="small"
              onClick={handleCommentButtonClick}
              sx={{ padding: '4px' }}
            >
              <CommentIcon sx={{
                color: isCommentModeActive ? '#3eab59' : 'primary.main',
                fontSize: '18px',
              }} />
            </IconButton>
            {onConfigClick && (
              <IconButton
                size="small"
                onClick={onConfigClick}
                sx={{ padding: '4px' }}
              >
                <SettingsIcon sx={{ color: '#385576', fontSize: '18px' }} />
              </IconButton>
            )}
            {showFullScreen && (
              <IconButton
                size="small"
                onClick={handleFullScreenClick}
                sx={{ padding: '4px' }}
              >
                <FullscreenIcon sx={{ color: '#385576', fontSize: '18px' }} />
              </IconButton>
            )}
            <IconButton
              size="small"
              onClick={() => setExpanded(!expanded)}
              sx={{ padding: '4px' }}
            >
              {expanded ? (
                <RemoveIcon sx={{ color: '#385576', fontSize: '18px' }} />
              ) : (
                <AddIcon sx={{ color: '#385576', fontSize: '18px' }} />
              )}
            </IconButton>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              cursor: isCommentModeActive ? 'crosshair' : 'default',
              width: '100%',
            }}
          >
            {filter}
            <HighchartsReact
              className={classes.dashboardChart}
              ref={chartRef}
              highcharts={Highcharts}
              options={options}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  ) : (
    <Card className={classes.chartCard}>
      <CardContent>
        {chartView === 'graphs' || chartView === 'settings' ? (
          <Accordion
            expanded={expanded}
            onChange={() => setExpanded(!expanded)}
            elevation={0}
          >
            <AccordionSummary className={classes.accordionSummary}
              sx={{
                '& .MuiAccordionSummary-content': {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  margin: 0,
                },
              }}
            >
              <Box className={classes.titleContainer}>
                <Typography variant="h4" color="primary">
                  {title}
                </Typography>
              </Box>
              <Box className={classes.iconButtonsContainer}>
                {stackButton && (
                  stackButton.component || (
                    <Button
                      onClick={stackButton.onClick}
                      size="small"
                      variant="outlined"
                      style={{
                        backgroundColor: '#3693d5',
                        color: 'white',
                        border: '1px solid #3693d5',
                        marginRight: '8px',
                        textTransform: 'none',
                        padding: '4px 12px',
                      }}
                    >
                      {stackButton.isStacked ? 'Apilar' : 'Valores Originales'}
                    </Button>
                  )
                )}
                <IconButton
                  size="small"
                  onClick={handleCommentButtonClick}
                  sx={{ padding: '4px' }}
                >
                  <CommentIcon sx={{
                    color: isCommentModeActive ? '#3eab59' : 'primary.main',
                    fontSize: '18px',
                  }} />
                </IconButton>
                {onConfigClick && chartView !== 'settings' && (
                  <IconButton
                    size="small"
                    onClick={onConfigClick}
                    sx={{ padding: '4px' }}
                  >
                    <SettingsIcon sx={{ color: '#385576', fontSize: '18px' }} />
                  </IconButton>
                )}
                {showFullScreen && (
                  <IconButton
                    size="small"
                    onClick={handleFullScreenClick}
                    sx={{ padding: '4px' }}
                  >
                    <FullscreenIcon sx={{ color: '#385576', fontSize: '18px' }} />
                  </IconButton>
                )}
                <IconButton
                  size="small"
                  onClick={() => setExpanded(!expanded)}
                  sx={{ padding: '4px' }}
                >
                  {expanded ? (
                    <RemoveIcon sx={{ color: '#385576', fontSize: '18px' }} />
                  ) : (
                    <AddIcon sx={{ color: '#385576', fontSize: '18px' }} />
                  )}
                </IconButton>
              </Box>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {button && <Box>{button}</Box>}
              {filter}
              <HighchartsReact
                ref={chartRef}
                highcharts={Highcharts}
                options={options}
              />
            </AccordionDetails>
          </Accordion>
        ) : (
          <Box>
            <Box className={classes.titleContainer}>
              <Typography variant="h4" color="primary">
                {title}
              </Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
              {filter}
              {button && <Box>{button}</Box>}
              <HighchartsReact
                ref={chartRef}
                highcharts={Highcharts}
                options={options}
              />
            </Box>
          </Box>
        )}
      </CardContent>
    </Card>
  );
}

export default BaseHighchart;
