import React from 'react';
import StackedSoilMoistureChart from '../../../../../../components/Highcharts/StackedSoilMoistureChart/StackedSoilMoistureChart';
import SoilMoistureChart from '../../../../../../components/Highcharts/SoilMoistureChart/SoilMoistureChart';
import RootsChart from '../../../../../../components/Highcharts/RootsChart/RootsChart';

const SoilChartsTab = ({
  sectorId, farmId, wiseconnZoneId,
}: {
  sectorId: string,
  farmId: string,
  wiseconnZoneId: string | undefined,
}) => (
    <>
      <StackedSoilMoistureChart
        chartView="graphs"
        sectorId={sectorId}
        farmId={farmId}
        wiseconnZoneId={wiseconnZoneId}
        height={450}
      />
      <RootsChart
        chartView="graphs"
        sectorId={sectorId}
        farmId={farmId}
        wiseconnZoneId={wiseconnZoneId}
        height={300}
      />
      <SoilMoistureChart
        chartView="graphs"
        sectorId={sectorId}
        farmId={farmId}
        wiseconnZoneId={wiseconnZoneId}
        height={450}
      />
    </>
);

export default SoilChartsTab;
