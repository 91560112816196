import React from 'react';
import {
  Box,
  Container,
} from '@mui/material';
import { useJsApiLoader } from '@react-google-maps/api';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../types';
import Page from '../../../components/Layout/Page';
import useStyles from './styles';
import CustomHeader from '../../../components/General/CustomHeader';
import useFetchFarms from '../../../hooks/useFetchFarms';
import useFetchNotifications from '../../../hooks/useFetchNotifications';
import AccountHomeMap from './components/AccountHomeMap';
import WeatherTabs from './components/WeatherTabs';
import NotificationList from '../../../components/Notifications/NotificationList';

const AVAILABLE_LIBRARIES: ('drawing' | 'places')[] = ['drawing', 'places'];

const AccountHomeContent = () => {
  const classes = useStyles({});
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '';

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey,
    libraries: AVAILABLE_LIBRARIES,
  });

  useFetchFarms();
  useFetchNotifications();
  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  const SectorBreadcrumb = () => {
    const { selectedFarm } = useSelector((state: ReduxState) => state.accountFarm);
    const title = selectedFarm ? `Sectores/${selectedFarm.name}` : 'Sectores';
    return <CustomHeader title={title} showBackButton={false} />;
  };

  return (
    <Page className={classes.root} title='Inicio'>
      <Container maxWidth={false} className={classes.containerContainer}>
        <SectorBreadcrumb />
        <Box height='100%' display='flex' pt={3}>
          <Box display='flex' flexDirection='column' flex={1}>
            <Box display='flex' flexDirection='column' flex={1} height='100%'>
              <Box flex='4' minHeight='500px' mb={2} overflow='hidden'>
                <AccountHomeMap />
              </Box>
              <Box flex='0 0 auto' minHeight='200px' maxHeight='250px' overflow='auto'>
                <WeatherTabs />
              </Box>
            </Box>
          </Box>
          <Box width="300px" ml={2} overflow='scroll'>
            <NotificationList />
          </Box>
        </Box>
      </Container>
    </Page>
  );
};

const AccountHome = () => {
  const { selectedAccount } = useSelector((state: ReduxState) => state.userAccount);

  if (!selectedAccount) {
    return null;
  }

  return <AccountHomeContent />;
};

export default AccountHome;
