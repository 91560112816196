import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';

import React, { useState } from 'react';
import { Close as CloseIcon } from '@mui/icons-material';

import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import CustomButton from '../../../../../components/General/CustomButton';
import useStyles from '../../styles';
import CustomModal from '../../../../../components/General/CustomModal';
import AddPolygons from '../AddPolygons';
import apiClient from '../../../../../requests/api/apiClient';
import { ReduxState } from '../../../../../types';
import { FetchError } from '../../../../types';
import SOIL_TYPES from '../../../../../constants/soil_types';
import { setAccountFarm, setAccountFarmsOptions } from '../../../../../actions/accountFarmAction';
import {
  FarmSchema, GetSectorsWiseconnIdsResponse, SectorSchema, UpdateSectorMeasuresSchema,
} from '../../../../../requests/api/apiTypes';
import useFetchApi from '../../../../../hooks/useFetchApi';
import { setCurrentSector } from '../../../../../actions/currentSectorActions';

type ErrorKeys = 'name' | 'sectorDevice' | 'theoreticalSurface' | 'typeOfSoil';

type ErrorObject = {
  name: string,
  sectorDevice: string,
  theoreticalSurface: string,
  typeOfSoil: string,
  wiseconnId?: string,
  sectorNumber?: string,
}
;

const SECTOR_NAME_KEY_CONV = {
  name: 'Nombre',
  sectorDevice: 'Equipo',
  theoreticalSurface: 'Superficie Teórica',
  typeOfSoil: 'Tipo de suelo',
};

const AddSectorFlow = ({
  isModalOpen, setOpenModal, lat, lng,
}: {
  isModalOpen: boolean,
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>,
  lat: string | number,
  lng: string | number,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { selectedFarm, farmsOptions } = useSelector((state: ReduxState) => state.accountFarm);
  const { enqueueSnackbar } = useSnackbar();
  const [openSecondModalSectorFlow, setOpenSecondModalSectorFlow] = useState(false);
  const [sectorInformation, setSectorInformation] = useState({
    name: '',
    sectorDevice: '',
    theoreticalSurface: '',
    typeOfSoil: '',
    wiseconnId: '',
    sectorNumber: '',
  });
  const [disabledButton, setDisabledButton] = useState(false);

  const { data: wiseconnIdsData } = useFetchApi<GetSectorsWiseconnIdsResponse>({
    modelEndpoint: 'wiseconnIdsList',
    modelName: 'sectors',
  });

  const allSectorsWiseconnIds = wiseconnIdsData?.allSectorsWiseconnIds ?? [];

  const [error, setError] = useState<ErrorObject>({
    name: '',
    sectorDevice: '',
    theoreticalSurface: '',
    typeOfSoil: '',
    wiseconnId: '',
    sectorNumber: '',
  });

  const handleCloseAndResetData = () => {
    setSectorInformation({
      name: '',
      sectorDevice: '',
      theoreticalSurface: '',
      typeOfSoil: '',
      wiseconnId: '',
      sectorNumber: '',
    });
    setError({
      name: '',
      sectorDevice: '',
      theoreticalSurface: '',
      typeOfSoil: '',
      wiseconnId: '',
      sectorNumber: '',
    });
    setOpenModal(false);
    setOpenSecondModalSectorFlow(false);
  };

  const handleValueChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    source: string,
  ) => {
    const { value } = e.target;
    setSectorInformation((prevState) => ({ ...prevState, [source]: value }));
    setError((prevState) => ({ ...prevState, [source]: '' }));
  };

  const handleStep = (step: string) => {
    if (step === 'next') {
      let counter = 0;
      const errorObj = {
        name: '',
        sectorDevice: '',
        theoreticalSurface: '',
        typeOfSoil: '',
      };
      Object.entries(sectorInformation).forEach(([key, value]) => {
        const typedKey = key as ErrorKeys;
        if (Object.keys(errorObj).includes(typedKey)) {
          if (value === '') {
            counter += 1;
            errorObj[typedKey] = `${SECTOR_NAME_KEY_CONV[typedKey]} no puede estar vacio`;
          } else {
            errorObj[typedKey] = '';
          }
        }
      });

      if (counter > 0) {
        setError(errorObj);
        return;
      }

      if (sectorInformation.wiseconnId
        && allSectorsWiseconnIds.includes(sectorInformation.wiseconnId)) {
        setError((prevState) => ({ ...prevState, wiseconnId: 'El Wiseconn ID ya está en uso' }));
        return;
      }

      setOpenSecondModalSectorFlow(true);
      setOpenModal(false);
    } else {
      setOpenSecondModalSectorFlow(false);
      setOpenModal(true);
    }
  };

  const insertFunction = async (coordinates?: { lat: number, lng: number }[] | null) => {
    setDisabledButton(true);
    try {
      const sectorDataToCreate: SectorSchema = {
        name: sectorInformation.name,
        sectorDevice: sectorInformation.sectorDevice,
        theoreticalSurface: sectorInformation.theoreticalSurface,
        typeOfSoil: SOIL_TYPES[sectorInformation.typeOfSoil],
        farm: selectedFarm?.id ?? '',
        wiseconnId: sectorInformation.wiseconnId,
        sectorNumber: sectorInformation.sectorNumber,
      };

      if (coordinates && Array.isArray(coordinates)) {
        sectorDataToCreate.coordinates = coordinates;
      }
      const { data } = await apiClient.sectors.sectorsCreate(sectorDataToCreate);
      if (data.sector) {
        try {
          await apiClient.sectors.individualSectorMeasuresUpdate(
            {
              farmId: selectedFarm?.id ?? '',
              wiseconnZoneId: data.sector.wiseconnId ?? '',
            },
            {} as UpdateSectorMeasuresSchema,
          );
        } catch (err) {
          console.log('Error adding measures', err);
        }

        // Set the newly created sector as the current sector
        console.log('Newly Created Sector:', data.sector); // Log the new sector
        dispatch(setCurrentSector(data.sector));
      }

      const relevantFarm = farmsOptions?.find((farm: FarmSchema) => farm.id === selectedFarm?.id)
        ?? {};
      const auxFarmOptions = farmsOptions
        ?.filter((farm: FarmSchema) => farm.id !== selectedFarm?.id) ?? [];
      dispatch(setAccountFarmsOptions([
        ...auxFarmOptions,
        {
          ...relevantFarm as FarmSchema,
          sectors: selectedFarm?.sectors
            ? [...selectedFarm.sectors, data.sector as SectorSchema]
            : [data.sector as SectorSchema],
        },
      ]));
      const relevantFarmToModify = selectedFarm as FarmSchema;
      dispatch(setAccountFarm({
        ...relevantFarmToModify,
        sectors: selectedFarm?.sectors
          ? [...selectedFarm.sectors, data.sector as SectorSchema]
          : [data.sector as SectorSchema],
      }));
      dispatch(setCurrentSector(data.sector ?? null));
      handleCloseAndResetData();
      enqueueSnackbar(data.message, { variant: 'success' });
    } catch (err) {
      const { error: fetchError } = err as FetchError;
      if (fetchError?.message) {
        enqueueSnackbar(fetchError?.message, { variant: 'error' });
      } else {
        const auxError = err as Error;
        enqueueSnackbar(auxError.message, { variant: 'error' });
      }
    } finally {
      setDisabledButton(false);
    }
  };
  return (
    <CustomModal
      open={isModalOpen || openSecondModalSectorFlow}
      handleClose={handleCloseAndResetData}
      cardClassName={openSecondModalSectorFlow ? classes.cardModalContainer : undefined}
    ><>
        {isModalOpen
          && <Box display='flex' flexDirection='column' flex='1'>
            <Box className={classes.uploadAddDevicesHeader}>
              <Typography
                variant="h3"
                color="textPrimary"
                className={classes.uploadAddDevicesHeaderTitle}
              >
                <span className={classes.activePageUnderlined}>
                  Agregar sector
                </span>
              </Typography>
              <IconButton onClick={handleCloseAndResetData}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider className={classes.divider} />
            <Box className={classes.textFieldsContainer}>
              <TextField
                label='Identificación cliente (*)'
                variant='standard'
                fullWidth
                className={classes.singleLeftTextfield}
                error={error.name !== ''}
                helperText={error.name !== '' && error.name}
                value={sectorInformation.name}
                onChange={(e) => handleValueChange(e, 'name')}
              />
              <TextField
                label='Equipo (*)'
                variant='standard'
                fullWidth
                value={sectorInformation.sectorDevice}
                error={error.sectorDevice !== ''}
                helperText={error.sectorDevice !== '' && error.sectorDevice}
                onChange={(e) => handleValueChange(e, 'sectorDevice')}
              />
            </Box>
            <Box className={classes.textFieldsContainer}>
              <TextField
                label='Superficie teórica (hectáreas) (*)'
                variant='standard'
                fullWidth
                className={classes.singleLeftTextfield}
                value={sectorInformation.theoreticalSurface}
                error={error.theoreticalSurface !== ''}
                helperText={error.theoreticalSurface !== '' && error.theoreticalSurface}
                onChange={(e) => handleValueChange(e, 'theoreticalSurface')}
                InputProps={{ endAdornment: sectorInformation.theoreticalSurface !== '' && <InputAdornment position="end">ha</InputAdornment> }}
              />
              <TextField
                label='Tipo de suelo (*)'
                select
                variant='standard'
                fullWidth
                value={sectorInformation.typeOfSoil}
                error={error.typeOfSoil !== ''}
                helperText={error.typeOfSoil !== '' && error.typeOfSoil}
                onChange={(e) => handleValueChange(e, 'typeOfSoil')}
              >
                {Object.entries(SOIL_TYPES).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box className={classes.textFieldsContainer}>
              <TextField
                label='Wiseconn ID'
                variant='standard'
                fullWidth
                className={classes.singleLeftTextfield}
                value={sectorInformation.wiseconnId}
                onChange={(e) => handleValueChange(e, 'wiseconnId')}
                error={error.wiseconnId !== ''}
                helperText={error.wiseconnId !== '' && error.wiseconnId}
              />
              <TextField
                label='Número de sector (*)'
                variant='standard'
                fullWidth
                value={sectorInformation.sectorNumber}
                onChange={(e) => handleValueChange(e, 'sectorNumber')}
                error={error.sectorNumber !== ''}
                helperText={error.sectorNumber !== '' && error.sectorNumber}
              />
            </Box>
            <Box className={classes.buttonContainer}>
              <Box className={classes.singleButtonContainer} marginRight='20px'>
                <CustomButton buttonText='cancelar' onClick={handleCloseAndResetData} />
              </Box>
              <Box className={classes.singleButtonContainer}>
                <CustomButton buttonText='siguiente' onClick={() => handleStep('next')} />
              </Box>
            </Box>
          </Box>}
        {openSecondModalSectorFlow
          && <AddPolygons
            title='Agregar polígono sector'
            detailedInfo={{ 'Superficie Teórica': sectorInformation.theoreticalSurface, 'Tipo de suelo': SOIL_TYPES[sectorInformation.typeOfSoil] }}
            detailedInfoTitle='Info nuevo campo'
            handleCloseModal={handleCloseAndResetData}
            handleGoBack={() => handleStep('prev')}
            lat={lat as string}
            lng={lng as string}
            insertFunction={insertFunction}
            enforcePolygon={false}
            buttonDisabled={disabledButton}
          />
        }
      </>
    </CustomModal>
  );
};

export default AddSectorFlow;
