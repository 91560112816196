import dayjs from 'dayjs';
import {
  fetchReadingsFromAPI,
} from '../../services/readingsService';
import {
  calculateEsTmax,
  calculateEsTmin,
  calculateEa,
  calculateRnl,
  calculateRns,
  calculateRn,
  calculateEs,
  calculateS,
  calculateET0,
} from './formulas';

export const getReadings = async (filters: {
  granularity?: string;
  farmId: string;
  startDate: string;
  endDate: string;
  sensorType?: string;
  wiseconnZoneId?: string;
  sectorId?: string;
  hourInterval?: number;
  name?: string;
}) => {
  // Fetch readings from API
  const readings = await fetchReadingsFromAPI({
    granularity: filters.granularity,
    farmId: filters.farmId,
    startDate: filters.startDate,
    endDate: filters.endDate,
    wiseconnZoneId: filters.wiseconnZoneId,
    sensorType: filters.sensorType,
    sectorId: filters.sectorId,
    hourInterval: filters.hourInterval,
    name: filters.name,
  });

  return readings;
};

export const getET0 = async (filters: {
  farmId: string;
  startDate: string;
  endDate: string;
  sectorId?: string;
  wiseconnZoneId?: string;
  hourInterval?: number;
  granularity: string;
}) => {
  const [
    minAndMaxTemperature,
    minAndMaxHumidity,
    hourlySolarRadiation,
    hourlyWindVelocity,
  ] = await Promise.all([
    getReadings({
      ...filters,
      sensorType: 'Temperature',
      granularity: filters.granularity,
    }),
    getReadings({
      ...filters,
      sensorType: 'Humidity',
      granularity: filters.granularity,
    }),
    getReadings({
      ...filters,
      sensorType: 'Solar Radiation',
      granularity: filters.granularity,
    }),
    getReadings({
      ...filters,
      sensorType: 'Wind Velocity',
      granularity: filters.granularity,
    }),
  ]);

  const calculatedET0 = minAndMaxTemperature.map((item: any, index: number) => {
    const maxTemperature = item.maxValue;
    const minTemperature = item.minValue;
    const maxHumidity = minAndMaxHumidity[index].maxValue;
    const minHumidity = minAndMaxHumidity[index].minValue;
    const solarRadiation = hourlySolarRadiation[index].averageValue;
    const windVelocity = hourlyWindVelocity[index].averageValue;
    // Validamos que todos los valores estén presentes
    if (
      maxTemperature === undefined
      || minTemperature === undefined
      || maxHumidity === undefined
      || minHumidity === undefined
      || solarRadiation === undefined
      || windVelocity === undefined
    ) {
      return 0; // Si falta algún dato, asignamos ET0 como 0
    }

    // Cálculos intermedios
    const esTmax = calculateEsTmax(maxTemperature);
    const esTmin = calculateEsTmin(minTemperature);
    const ea = calculateEa(esTmax, esTmin, minHumidity, maxHumidity);
    const rns = calculateRns(solarRadiation);
    const rnl = calculateRnl(maxTemperature, minTemperature, ea, solarRadiation);
    const rn = calculateRn(rns, rnl);
    const es = calculateEs(esTmax, esTmin);
    const averageTemperature = (maxTemperature + minTemperature) / 2;
    const s = calculateS(es, averageTemperature);

    // Cálculo final de ET0
    return calculateET0(s, rn, averageTemperature, windVelocity, es, ea);
  });

  // Calcular el acumulado progresivo de ET0
  let cumulativeET0 = 0;
  const accumulatedEt0 = calculatedET0.map((et0: number, index: number) => {
    cumulativeET0 += et0;
    const date = minAndMaxTemperature[index].dateTime;

    return {
      et0,
      cumulativeET0,
      date,
    };
  });

  return accumulatedEt0;
};

// eslint-disable-next-line max-len
// Esta funcion se usa para obtener el ultimo riego previo al startDate, se necesita para calcular el Kc
export const getLastIrrigationFromDate = async (filters: {
  farmId: string;
  wiseconnZoneId?: string;
  sectorId?: string;
  startDate: string;
  endDate: string;
}) => {
  let lastNonZeroIrrigationDate = filters.startDate;
  let lastNonZeroIrrigationValue = 0;

  const previousData = await getReadings({
    ...filters,
    name: 'Irrigation Precipitation',
    startDate: dayjs(filters.startDate).subtract(30, 'days').toISOString(), // Search up to 30 days back
    endDate: filters.startDate,
    granularity: 'daily',
  });

  // Find most recent non-zero irrigation value
  const lastNonZeroIrrigation = previousData.reverse().find((item: any) => item.lastValue > 0);
  if (lastNonZeroIrrigation) {
    lastNonZeroIrrigationDate = dayjs.utc(lastNonZeroIrrigation.date).startOf('day').toISOString();
    lastNonZeroIrrigationValue = lastNonZeroIrrigation.maxValue;
  }

  return {
    lastNonZeroIrrigationDate,
    lastNonZeroIrrigationValue,
  };
};

// Esta función se usa para calcular el Et0 acumulado entre riegos
export const et0ForKc = (et0Data: any, irrigationDataForKc: any) => {
  let et0Accumulated = 0;
  const et0DataForKc: any = [];

  for (let i = 0; i < irrigationDataForKc.length; i += 1) {
    if (Number(irrigationDataForKc[i]) !== 0) {
      et0Accumulated = et0Data[i].et0;
      et0DataForKc.push({ et0: et0Accumulated, date: et0Data[i].date });
    } else {
      et0Accumulated += et0Data[i].et0;
      et0DataForKc.push({ et0: et0Accumulated, date: et0Data[i].date });
    }
  }

  return et0DataForKc;
};

export const calculateKc = async ({
  normalizedIrrigationData,
  lastNonZeroIrrigationDate,
  et0Data,
  filters,
}: {
  normalizedIrrigationData: any;
  lastNonZeroIrrigationDate: string;
  et0Data: any;
  filters: any;
}) => {
  let irrigationDataForKc = [...normalizedIrrigationData];
  const irrigationOffset = et0Data.length - normalizedIrrigationData.length;

  // Esta condicion quiere decir que hay que usar un riego previo
  if (et0Data.length > normalizedIrrigationData.length) {
    const newIrrigationData = await getReadings({
      ...filters,
      name: 'Irrigation Precipitation',
      startDate: lastNonZeroIrrigationDate,
      granularity: 'daily',
    });
    irrigationDataForKc = newIrrigationData.map((item: any) => item.lastValue);
  }

  // Construir los datos de et0 alineados con irrigación
  const et0DataForKc = et0ForKc(et0Data, irrigationDataForKc);

  // Reemplazar ceros en irrigación con el último valor no nulo
  let lastNonZero = irrigationDataForKc[0];
  for (let i = 0; i < irrigationDataForKc.length; i += 1) {
    if (irrigationDataForKc[i] === 0) {
      irrigationDataForKc[i] = lastNonZero;
    } else {
      lastNonZero = irrigationDataForKc[i];
    }
  }

  // Calcular Kc
  const auxKcData = irrigationDataForKc.map((irrigation: any, index: number) => {
    const et0Value = et0DataForKc[index].et0;
    return {
      kc: Number(irrigation) / et0Value,
      date: et0DataForKc[index].date,
      startWeekDay: et0DataForKc[index].date,
    };
  });

  const kcData = auxKcData.slice(irrigationOffset);
  // Normalizar Kc eliminando los elementos agregados anteriormente
  return {
    irrigationDataForKc,
    accumulatedEt0ForKc: et0DataForKc,
    kcData, // Devuelve los datos normalizados de Kc
  };
};
