import React, { useState } from 'react';
import {
  Box, CircularProgress, MenuItem, TextField,
} from '@mui/material';
import useStyles from '../../styles';
import ChartFilter from '../../../../../../components/Highcharts/ChartFilter';
import useFetchApi from '../../../../../../hooks/useFetchApi';
import { SectorByIdSchemaResponse } from '../../../../../../requests/api/apiTypes';
import SoilDashboard from './soilDashboard';

const Dashboards = ({
  sectorId,
  wiseconnZoneId,
}: {
  sectorId: string,
  wiseconnZoneId: string | undefined,
}) => {
  const classes = useStyles({});
  const [sensorType, setSensorType] = useState('suelo');
  const showDashboardSelector = false;

  const { data: fetchData, loading } = useFetchApi<SectorByIdSchemaResponse>({
    modelEndpoint: 'sectorDetail',
    modelName: 'sector',
    id: sectorId,
  });

  const farmId = fetchData?.sector?.farm || '';

  return (
    <>
      <Box className={classes.textFieldsContainer}>
        {showDashboardSelector && (
          <TextField
            label='Dashboard'
            variant='standard'
            select
            sx={{ minWidth: 200 }}
            value={sensorType}
            onChange={(e) => setSensorType(e.target.value)}
          >
            <MenuItem value={'suelo'}>Suelo</MenuItem>
          </TextField>
        )}
      </Box>
      <ChartFilter sectorId={sectorId} />
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <CircularProgress />
        </Box>
      ) : ((() => {
        switch (sensorType) {
          case 'suelo':
            return <SoilDashboard
              sectorId={sectorId}
              farmId={farmId}
              wiseconnZoneId={wiseconnZoneId}
            />;
          default:
            return null;
        }
      })()
      )}
    </>
  );
};
export default Dashboards;
