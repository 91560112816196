import React, {
  useState,
  useEffect,
} from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Redirect, useHistory } from 'react-router';
import { Box, CircularProgress, Container } from '@mui/material';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../../../components/Layout/Page';
import UserUpsertForm from './UserUpsertForm';
import Header from './Header';

import useStyles from '../styles';
import { FetchError, MatchParams } from '../../../types';
import apiClient from '../../../../requests/api/apiClient';
import { ReduxState } from '../../../../types';
import UserAccountAssociation from './UserAccountAssociation';
import { resetCreateUserData, setCreateUserData } from '../../../../actions/userActions';
import {
  FIELDS_EQ,
  REQUIRED_SELECT_INPUT, REQUIRED_TEXT_INPUT,
  RequiredFields,
  UserSchemaReduced,
} from '../types';
import { PostUserSchema } from '../../../../requests/api/apiTypes';
import { UserType } from '../../../../constants/user_types';

function UpsertView(props: RouteComponentProps<MatchParams>) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state: ReduxState) => state.user);

  const [notFound, setNotFound] = useState(false);

  const [headerLoading, setHeaderLoading] = useState(false);
  const [getUserLoading, setGetUserLoading] = useState(false);

  useEffect(() => {
    const getUser = async (relUserId: string) => {
      try {
        setGetUserLoading(true);
        const { data } = await apiClient.users.usersDetail(relUserId);
        const userData = data.user as UserSchemaReduced;

        dispatch(setCreateUserData({
          name: userData.name,
          lastname: userData.lastname,
          email: userData.email,
          accounts: userData.accounts?.map((account) => ({
            accountId: account.id,
            farms: account.farms,
            name: account.name,
          })) || [],
          company: userData.company,
          phoneNumber: userData.phoneNumber,
          role: userData.role,
          type: userData.type,
          active: userData.active,
        }));
      } catch (err) {
        const { error } = err as FetchError;
        enqueueSnackbar(error.message, { variant: 'error' });
        setNotFound(true);
      } finally {
        setGetUserLoading(false);
      }
    };

    const userId = props.match.params.id;
    if (userId) {
      getUser(userId);
    }
  }, []);

  const handleSubmit = async (userToModify: string | undefined) => {
    const requiredFields = [...REQUIRED_TEXT_INPUT, ...REQUIRED_SELECT_INPUT];

    const hasMissingFields = requiredFields.some((elem) => {
      if (!user || !user[elem as RequiredFields]) {
        enqueueSnackbar(`El campo ${FIELDS_EQ[elem as RequiredFields]} debe ser completado`, { variant: 'error' });
        return true;
      }
      return false;
    });

    if (hasMissingFields) return;

    try {
      setHeaderLoading(true);
      let response;
      if (userToModify) {
        response = await apiClient.users.usersUpdate(userToModify, { ...user });
      } else {
        response = await apiClient.users.usersCreate({ ...user as PostUserSchema });
      }
      const { data } = response;
      enqueueSnackbar(data.message, { variant: 'success' });
      dispatch(resetCreateUserData());
      history.push('/usuarios');
    } catch (err) {
      const { error } = err as FetchError;
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setHeaderLoading(false);
    }
  };

  if (notFound) {
    return <Redirect to="/usuarios" />;
  }

  return (
    <Page
      className={classes.root}
      title={`Usuarios | ${props.match.params.id ? 'Editar Usuario' : 'Crear Usuario'}`}
    >
      <Container maxWidth={false}>
        {getUserLoading ? (
          <Box display="flex" justifyContent="center" mt={3}>
            <CircularProgress
              size={'3rem'}
              sx={{
                position: 'absolute',
                top: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          </Box>
        ) : (
          <>
            <Header
              handleSubmit={handleSubmit}
              loading={headerLoading}
              userToModify={props.match.params.id}
            />
            <UserUpsertForm />
            {[
              UserType.CONSULTANT, UserType.CLIENT, UserType.MULTI_ACCOUNT_CLIENT,
            ].includes(user?.type as UserType) && <
                UserAccountAssociation userType={user?.type as UserType} />}
          </>
        )}
      </Container>
    </Page>
  );
}

UpsertView.propTypes = {
  match: PropTypes.any,
};

export default withRouter(UpsertView);
