import React, { useState } from 'react';
import { Box, Tab } from '@mui/material';

import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import useStyles from '../styles';
import WeatherForecast from './WeatherForecast';
import WeatherReport from './WeatherReport';

const WeatherTabs = () => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  return (
    <TabContext value={selectedTab}>
      <Box sx={{
        borderBottom: 0,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}>
        <Box sx={{ flexShrink: 0 }}>
          <TabList onChange={handleChange} aria-label="sector tabs">
            <Tab label="Clima Actual" value="1" className={classes.tabLabel}/>
            <Tab label="Pronóstico" value="2" className={classes.tabLabel}/>
          </TabList>
        </Box>
        <Box sx={{ flex: 1, overflow: 'auto', minHeight: 0 }}>
          <TabPanel value="1" className={classes.tabPanel}>
            <WeatherReport />
          </TabPanel>
          <TabPanel value="2" className={classes.tabPanel}>
            <WeatherForecast />
          </TabPanel>
        </Box>
      </Box>
    </TabContext>
  );
};

export default WeatherTabs;
