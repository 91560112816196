import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    // minHeight: '100%',
    height: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  tabLabel: {
    textTransform: 'none',
  },
  selectField: {
    flex: 1,
  },
  divider: {
    margin: '10px 0px',
    width: '100%',
  },
  activePageUnderlined: {
    borderBottom: '2px solid',
    borderBottomColor: theme.palette.secondary.main,
  },
  upsertCropCycleModalContainer: {
    width: '500px',
    display: 'flex',
    flexDirection: 'column',
  },
  upsertCropCycleModalHeader: {
    display: 'flex',
    width: '100%',
    marginTop: '12px',
  },
  upsertCropCycleModalHeaderTitle: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dividerStyle: {
    margin: '24px 0px',
  },
  upsertCropCycleModalAutocomplete: {
    marginTop: '40px',
  },
  upsertCropCycleModalButtonsContainer: {
    marginTop: '40px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  textFieldsContainer: {
    minWidth: 'fit-content',
    width: 'auto',
  },
}));

export default useStyles;
