enum UserType {
  CDTEC = 'cdtec',
  CONSULTANT = 'consultant',
  CLIENT = 'client',
  MULTI_ACCOUNT_CLIENT = 'multiAccountClient',
}

enum UserRole {
  ADMIN = 'admin',
  OPERATOR = 'operator',
  AGRO = 'agro',
}

const translateUserTypeRole: { [key: string]: string } = {
  cdtec: 'CDTEC',
  consultant: 'Asesor',
  client: 'Cliente',
  multiAccountClient: 'Cliente Multicuenta',
  admin: 'Administrador',
  agro: 'Agrónomo',
  operator: 'Operador',
};

export {
  UserType,
  UserRole,
  translateUserTypeRole,
};
