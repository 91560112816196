import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, CircularProgress } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';
import BaseHighchart from '../BaseHighchart';
import prepareData from './builder';
import { ReduxState } from '../../../types';
import ChartFilter from '../ChartFilter';
import { navigateToChart } from '../../../utils/helpers';
import { saveChartData, getChartData } from '../../../services/indexDBService';
import { ConversationSchema, TimePointSchema } from '../../../requests/api/apiTypes';
import { ScatterPoint } from '../types';
import apiClient from '../../../requests/api/apiClient';
import { KC_NAME } from '../../../constants/graphs';
import { FetchError } from '../../../views/types';
import ConversationModal from '../ConversationTab/conversationModal';
import { setStartDate, setEndDate } from '../../../actions/graphsFilterActions';
import { calculateDateRangeFromNotification } from '../utils';
// To Do: Actualizar types
interface ChartDataType {
  series: { type: string; name: string; data: any[] }[];
  categories: string[];
  absoluteCategories: string[];
}

function KcChart({
  chartView,
  sectorId,
  farmId,
  hideTitle = false,
  hideLegend = false,
  wiseconnZoneId,
  height = 300,
  title = 'Gráfico Kc',
  externalModalState,
  externalSetModalState,
  externalConversations,
  externalSetConversations,
}: {
  chartView: 'dashboard' | 'graphs' | 'settings'
  sectorId: string
  farmId: string
  hideTitle?: boolean
  hideLegend?: boolean
  wiseconnZoneId: string | undefined
  height?: number
  title?: string
  externalModalState?: {
    open: boolean;
    conversationId: string;
    timePointData: TimePointSchema;
  };
  externalSetModalState?: React.Dispatch<React.SetStateAction<{
    open: boolean;
    conversationId: string;
    timePointData: TimePointSchema;
  }>>;
  externalConversations?: ConversationSchema[];
  externalSetConversations?: React.Dispatch<React.SetStateAction<ConversationSchema[]>>;
}) {
  const [chartData, setChartData] = useState<ChartDataType>({
    series: [],
    categories: [],
    absoluteCategories: [],
  });
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dateParam = searchParams.get('date');
  const { enqueueSnackbar } = useSnackbar();
  const [internalModalState, setInternalModalState] = useState<{
    open: boolean;
    conversationId: string;
    timePointData: TimePointSchema;
  }>({
    open: false,
    conversationId: '',
    timePointData: {
      hour: '',
      day: '',
      month: '',
      year: '',
    },
  });
  const [internalConversations, setInternalConversations] = useState<ConversationSchema[]>([]);

  const modalState = externalModalState || internalModalState;
  const setModalState = externalSetModalState || setInternalModalState;
  const conversations = externalConversations || internalConversations;
  const setConversations = externalSetConversations || setInternalConversations;

  const { startDate, endDate } = useSelector((state: ReduxState) => state.graphsFilter);
  const dispatch = useDispatch();

  const preparedFilters = useMemo(() => ({
    farmId,
    startDate,
    endDate,
  }), [farmId, startDate, endDate]);

  const createSeriesData = useCallback((preparedData: any) => [
    {
      type: 'column',
      name: 'Kc',
      data: preparedData.kc,
      yAxis: 0,
      stack: 'kc',
      zones: [
        {
          value: 0.5,
          color: '#BF3F23', // Rojo
        },
        {
          value: 0.6,
          color: '#FEBF23', // Amarillo
        },
        {
          color: '#6FBF23', // Verde para mayor a 2
        },
      ],
    },
  ], []);

  // To Do: Actualizar types
  const fetchData = useCallback(async () => {
    setIsLoading(true);

    try {
      const cachedData = await getChartData('kcChart', startDate, endDate, sectorId);

      if (cachedData !== null && cachedData !== undefined) {
        const seriesData = createSeriesData(cachedData.data);
        setChartData({
          series: seriesData,
          categories: cachedData.data.categories,
          absoluteCategories: cachedData.data.absoluteCategories,
        });
        setIsLoading(false);
        return;
      }

      const preparedData = await prepareData(preparedFilters);
      const seriesData = createSeriesData(preparedData);

      // Guardar en IndexDB
      await saveChartData('kcChart', startDate, endDate, sectorId, preparedData);

      setChartData({
        series: seriesData,
        categories: preparedData.categories as string[],
        absoluteCategories: preparedData.absoluteCategories as string[],
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  }, [preparedFilters, createSeriesData, sectorId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (!dateParam) return;
    const { rangeStartDate, rangeEndDate } = calculateDateRangeFromNotification(dateParam);
    dispatch(setStartDate(rangeStartDate));
    dispatch(setEndDate(rangeEndDate));
  }, [dateParam, dispatch]);

  const customTooltip = useMemo(() => ({
    shared: true,
    useHTML: true,
    formatter(this: Highcharts.TooltipFormatterContextObject) {
      if (this.series.name === 'Comentarios') {
        const point = this.point as ScatterPoint;
        const conversationId = point.custom?.conversationId;
        if (!conversations || !conversationId) {
          return '<b>Último comentario:</b> Cargando...<br/>';
        }
        const conversation = conversations.find((conv) => conv.conversationId === conversationId);
        if (!conversation) {
          return '<b>Último comentario:</b> No se encontró la conversación<br/>';
        }
        const lastComment = conversation.lastComment || 'Sin comentarios';
        return `<b>Último comentario:</b> ${lastComment}<br/>
                <span style="color: gray; font-size: 10px">${dayjs(conversation.updatedAt).format('MMM DD, YYYY HH:mm')}</span>`;
      }
      const header = '<div style="font-size: 14px; font-weight: bold; margin-bottom: 8px;">Resumen</div>';
      const subHeader = `<div style="font-size: 12px; margin-bottom: 8px;">${this.x}</br></div>`;
      const points = this.points?.map(
        (point) => `<div style="margin: 4px 0"><span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${Number(point.y).toFixed(2)}</b></div>`,
      ).join('');
      return `<div style="padding: 8px">${header}${subHeader}${points}</div>`;
    },
  }), [conversations]);

  useEffect(() => {
    if (!externalConversations) {
      const loadConversations = async () => {
        try {
          const { data } = await apiClient.conversation.conversationDetail(
            sectorId,
            KC_NAME,
          );
          setInternalConversations(data.conversations);
        } catch (err) {
          const { error } = err as FetchError;
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      };

      loadConversations();
    }
  }, [sectorId, externalConversations]);

  const handleConfigClick = useCallback(() => {
    navigateToChart('kc', history, {
      farmId,
      sectorId,
      wiseconnZoneId: wiseconnZoneId || '',
    });
  }, [history, farmId, sectorId, wiseconnZoneId]);

  return (
    <>
      <Box style={{ width: '100%' }}>
        {isLoading && (
          <Box display="flex" justifyContent="center" my={2}>
          <CircularProgress />
        </Box>
        )}
        <div style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
          <BaseHighchart
            chartView={chartView}
            filter={chartView === 'settings' ? <ChartFilter sectorId={sectorId} /> : undefined}
            title={hideTitle ? '' : title}
            categories={chartData.categories}
            series={chartData.series as Highcharts.SeriesOptionsType[]}
            tooltip={customTooltip}
            chartConfig={{
              chart: {
                height,
              },
            }}
            hideXValues={false}
            setModalState={setModalState}
            conversations={conversations}
            absoluteCategories={chartData.absoluteCategories}
            legend={{ enabled: !hideLegend }}
            onConfigClick={handleConfigClick}
          />
        </div>
      </Box>
      <ConversationModal
        modalState={modalState}
        setModalState={setModalState}
        farmId={farmId}
        sectorId={sectorId}
        chart={KC_NAME}
        setConversationsFunction={setConversations}
      />
    </>
  );
}

export default KcChart;
