import React, { useEffect, useState } from 'react';
import {
  Box, Chip, Divider, Grid, MenuItem, TextField, Typography, Button,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import useStyles from './styles';
import { ReduxState } from '../../types';
import { setStartDate, setEndDate, setSelectedInterval } from '../../actions/graphsFilterActions';
import { CultivationCyclePopulatedSchema, GetCultivationCyclesBySectorResponse, PhenologicalStateWithId } from '../../requests/api/apiTypes';
import useFetchApi from '../../hooks/useFetchApi';

type TimeInterval = '1-day' | '1-week' | '2-weeks' | '1-month' | '3-months' | '6-months' | null;

type CultivationCycleWithUUID = Omit<CultivationCyclePopulatedSchema, 'phenologicalStates'> & {
  phenologicalStates: PhenologicalStateWithId[];
};

function ChartFilter({ sectorId }: { sectorId: string }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { startDate, endDate } = useSelector((state: ReduxState) => state.graphsFilter);
  const selectedInterval = useSelector((state: ReduxState) => state.graphsFilter.selectedInterval);
  const [cultivationCycles, setCultivationCycles] = useState<CultivationCycleWithUUID[]>([]);
  const [selectedCycleId, setSelectedCycleId] = useState<string>('default');
  const [selectedStateId, setSelectedStateId] = useState<string>('default');
  const [isInitialized, setIsInitialized] = useState(false);
  const [tempStartDate, setTempStartDate] = useState(startDate.split('T')[0]);
  const [tempEndDate, setTempEndDate] = useState(endDate.split('T')[0]);

  const { data } = useFetchApi<GetCultivationCyclesBySectorResponse>({
    modelEndpoint: 'cultivationCycleSectorDetail',
    modelName: 'cultivationCycleSector',
    id: sectorId,
    dependencies: [sectorId],
  });

  // Set default dates on mount
  useEffect(() => {
    if (!isInitialized) {
      const storedStartDate = sessionStorage.getItem('chartFilterStartDate');
      const storedEndDate = sessionStorage.getItem('chartFilterEndDate');
      const storedInterval = sessionStorage.getItem('chartFilterInterval');

      if (storedStartDate && storedEndDate) {
        dispatch(setStartDate(storedStartDate));
        dispatch(setEndDate(storedEndDate));
        setTempStartDate(storedStartDate.split('T')[0]);
        setTempEndDate(storedEndDate.split('T')[0]);
        dispatch(setSelectedInterval(null));
        if (storedInterval) {
          dispatch(setSelectedInterval(storedInterval as TimeInterval));
        }
      } else {
        // Get the current date in LOCAL time
        const currentDate = dayjs();

        // End of today in local time (23:59:59)
        const intervalEndDate = currentDate.endOf('day');

        // Start of the day 14 days ago in local time (00:00:00)
        const intervalStartDate = intervalEndDate.subtract(14, 'day').startOf('day');

        // Convert to ISO format (LOCAL TIME, NOT UTC)
        const startDateIso = intervalStartDate.format('YYYY-MM-DDTHH:mm:ss');
        const endDateIso = intervalEndDate.format('YYYY-MM-DDTHH:mm:ss');

        dispatch(setStartDate(startDateIso));
        dispatch(setEndDate(endDateIso));
        setTempStartDate(startDateIso.split('T')[0]);
        setTempEndDate(endDateIso.split('T')[0]);
        dispatch(setSelectedInterval('2-weeks'));
      }
      setIsInitialized(true);
    }
  }, [dispatch, isInitialized]);

  useEffect(() => {
    if (data) {
      const cyclesWithStateIds = data.cultivationCycles.map((cycle) => ({
        ...cycle,
        phenologicalStates: cycle.phenologicalStates.map((state) => ({
          ...state,
          uuid: uuidv4(),
        })),
      }));
      setCultivationCycles(cyclesWithStateIds);
    }
  }, [data]);

  const resetSelectors = () => {
    setSelectedCycleId('default');
    setSelectedStateId('default');
  };

  const handleApplyDates = () => {
    dispatch(setSelectedInterval(null));
    resetSelectors();
    const formattedStartDate = dayjs(tempStartDate).format('YYYY-MM-DDTHH:mm:ss');
    const formattedEndDate = dayjs(tempEndDate).format('YYYY-MM-DDTHH:mm:ss');
    dispatch(setStartDate(formattedStartDate));
    dispatch(setEndDate(formattedEndDate));
  };

  const handleIntervalSelect = (interval: TimeInterval) => {
    dispatch(setSelectedInterval(interval));
    resetSelectors();

    // Always set the end date to today at 23:59:59 in LOCAL TIME
    const intervalEndDate = dayjs().endOf('day');

    // Determine the start date based on the selected interval
    let intervalStartDate = intervalEndDate.clone(); // Clone to avoid mutation

    switch (interval) {
      case '1-day':
        intervalStartDate = intervalEndDate.startOf('day');
        break;
      case '1-week':
        intervalStartDate = intervalEndDate.subtract(7, 'day').startOf('day');
        break;
      case '2-weeks':
        intervalStartDate = intervalEndDate.subtract(14, 'day').startOf('day');
        break;
      case '1-month':
        intervalStartDate = intervalEndDate.subtract(1, 'month').startOf('day');
        break;
      case '3-months':
        intervalStartDate = intervalEndDate.subtract(3, 'month').startOf('day');
        break;
      case '6-months':
        intervalStartDate = intervalEndDate.subtract(6, 'month').startOf('day');
        break;
      default:
        return;
    }

    // Convert to ISO strings while keeping local time
    const startDateIso = intervalStartDate.format('YYYY-MM-DDTHH:mm:ss');
    const endDateIso = intervalEndDate.format('YYYY-MM-DDTHH:mm:ss');

    sessionStorage.setItem('chartFilterStartDate', startDateIso);
    sessionStorage.setItem('chartFilterEndDate', endDateIso);
    dispatch(setStartDate(startDateIso));
    dispatch(setEndDate(endDateIso));

    // Update the TextField dates
    setTempStartDate(intervalStartDate.format('YYYY-MM-DD'));
    setTempEndDate(intervalEndDate.format('YYYY-MM-DD'));
  };

  const setDatesOnCultivationCycle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const cycleId = event.target.value;
    setSelectedCycleId(cycleId);
    setSelectedStateId('default');
    dispatch(setSelectedInterval(null));

    if (cycleId === 'default') return;

    const selectedCycle = cultivationCycles.find((cycle) => cycle.id === cycleId);
    if (selectedCycle) {
      dispatch(setStartDate(selectedCycle.startDate));
      dispatch(setEndDate(selectedCycle.endDate));
      setTempStartDate(selectedCycle.startDate.split('T')[0]);
      setTempEndDate(selectedCycle.endDate.split('T')[0]);
    }
  };

  const setDatesOnPhenologicalState = (event: React.ChangeEvent<HTMLInputElement>) => {
    const stateId = event.target.value;
    setSelectedStateId(stateId);
    dispatch(setSelectedInterval(null));

    if (stateId === 'default') return;

    const selectedCycle = cultivationCycles.find((cycle) => cycle.id === selectedCycleId);
    const selectedState = selectedCycle?.phenologicalStates.find((state) => state.uuid === stateId);

    if (selectedState) {
      const today = new Date();
      const currentYear = today.getFullYear();

      const [startMonth, startDay] = selectedState.startDate.split('-').map(Number);
      const startDateToSet = new Date(currentYear, startMonth - 1, startDay);

      if (startDateToSet > today) {
        startDateToSet.setFullYear(currentYear - 1);
      }

      const [endMonth, endDay] = selectedState.endDate.split('-').map(Number);
      const endDateToSet = new Date(startDateToSet.getFullYear(), endMonth - 1, endDay);

      if (endDateToSet < startDateToSet) {
        endDateToSet.setFullYear(endDateToSet.getFullYear() + 1);
      }

      const startDateIso = startDateToSet.toISOString();
      const endDateIso = endDateToSet.toISOString();

      dispatch(setStartDate(startDateIso));
      dispatch(setEndDate(endDateIso));
      setTempStartDate(dayjs(startDateIso).format('YYYY-MM-DD'));
      setTempEndDate(dayjs(endDateIso).format('YYYY-MM-DD'));
    }
  };

  const selectedCycle = cultivationCycles.find((cycle) => cycle.id === selectedCycleId);
  const today = dayjs().format('YYYY-MM-DD');

  return (
    <Box>
      <Divider className={classes.divider}/>
      <Grid container spacing={2}>
        <Grid item sm={12} md={3}>
          <Box className={classes.selectFieldsContainer}>
            <TextField
              className={classes.selectField}
              label="Ciclo de cultivo"
              value={selectedCycleId}
              select
              onChange={setDatesOnCultivationCycle}
              variant="standard"
              disabled={cultivationCycles.length === 0}
            >
              <MenuItem value="default">Seleccionar</MenuItem>
              {cultivationCycles.map((cycle) => (
                <MenuItem key={cycle.id} value={cycle.id}>
                  {cycle.name}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              className={classes.selectField}
              label="Estado fenológico"
              value={selectedStateId}
              select
              onChange={setDatesOnPhenologicalState}
              variant="standard"
              disabled={!selectedCycle || selectedCycleId === 'default'}
            >
              <MenuItem value="default">Seleccionar</MenuItem>
              {selectedCycle?.phenologicalStates.map((state) => (
                <MenuItem key={state.uuid} value={state.uuid}>
                  {state.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Grid>

        <Grid item sm={12} md={5}>
          <Typography className={classes.chipsLabel}>
            Intervalos de tiempo
          </Typography>
          <Box className={classes.chipsContainer}>
            <Chip
              key='1-day'
              color={selectedInterval === '1-day' ? 'primary' : undefined}
              label="1 día"
              onClick={() => handleIntervalSelect('1-day')}
              size='small'
              className={classes.layoutMapChip}
            />
            <Chip
              key='1-week'
              color={selectedInterval === '1-week' ? 'primary' : undefined}
              label="1 semana"
              onClick={() => handleIntervalSelect('1-week')}
              size='small'
              className={classes.layoutMapChip}
            />
            <Chip
              key='2-weeks'
              color={selectedInterval === '2-weeks' ? 'primary' : undefined}
              label="2 semanas"
              onClick={() => handleIntervalSelect('2-weeks')}
              size='small'
              className={classes.layoutMapChip}
            />
            <Chip
              key='1-month'
              color={selectedInterval === '1-month' ? 'primary' : undefined}
              label="1 mes"
              onClick={() => handleIntervalSelect('1-month')}
              size='small'
              className={classes.layoutMapChip}
            />
            <Chip
              key='3-months'
              color={selectedInterval === '3-months' ? 'primary' : undefined}
              label="3 meses"
              onClick={() => handleIntervalSelect('3-months')}
              size='small'
              className={classes.layoutMapChip}
            />
            <Chip
              key='6-months'
              color={selectedInterval === '6-months' ? 'primary' : undefined}
              label="6 meses"
              onClick={() => handleIntervalSelect('6-months')}
              size='small'
              className={classes.layoutMapChip}
            />
          </Box>
        </Grid>

        <Grid item sm={12} md={4}>
          <Box className={classes.selectFieldsContainer}>
            <TextField
              className={classes.selectField}
              label="Fecha de inicio"
              type="date"
              value={tempStartDate}
              onChange={(e) => setTempStartDate(e.target.value)}
              variant="standard"
              InputLabelProps={{ shrink: true }}
              inputProps={{
                max: today,
              }}
            />
            <TextField
              className={classes.selectField}
              label="Fecha de fin"
              type="date"
              value={tempEndDate}
              onChange={(e) => setTempEndDate(e.target.value)}
              variant="standard"
              InputLabelProps={{ shrink: true }}
              inputProps={{
                max: today,
              }}
            />
            <Button
              className={classes.filterButton}
              variant="contained"
              color="primary"
              onClick={handleApplyDates}
              style={{ marginTop: '16px' }}
            >
              Aplicar
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Divider className={classes.divider}/>
    </Box>
  );
}

export default ChartFilter;
