import { PhenologicalStateWithId } from '../requests/api/apiTypes';

type LocalPhenologicalStates = {
  id: string,
  name: string,
  startDate: string,
  endDate: string,
};

type SimpleInterval = [number, number];

// Encapsulate the logic within a class
class PhenologicalStateChecker {
  /**
   * Parses a date string ("YYYY-MM-DD" or "MM-DD") into a numerical representation (MMDD).
   * @param dateStr - The date string to parse.
   * @returns The numerical representation of the date.
   */
  private static parseDate(dateStr: string): number {
    let date: Date;

    if (dateStr.length === 10) {
      // YYYY-MM-DD format
      date = new Date(dateStr);
    } else {
      // MM-DD format - use current year to create valid date
      date = new Date(`${new Date().getFullYear()}-${dateStr}`);
    }

    const month = date.getMonth() + 1; // getMonth() returns 0-11
    const day = date.getDate();
    return month * 100 + day;
  }

  /**
   * Normalizes a date range, potentially handling wrap-around cases (e.g., Nov 1st to Feb 28th).
   * @param range - The date range object.
   * @returns An array of simple intervals [start, end].
   * Handles wrap-around by splitting into two intervals.
   */
  private static normalizeRange(
    range: LocalPhenologicalStates | PhenologicalStateWithId,
  ): SimpleInterval[] {
    const s = PhenologicalStateChecker.parseDate(range.startDate);
    const e = PhenologicalStateChecker.parseDate(range.endDate);

    if (s <= e) {
      return [[s, e]];
    }
    // Wrap-around range
    return [
      [s, 1231], // From start date to Dec 31st
      [101, e], // From Jan 1st to end date
    ];
  }

  /**
   * Checks if two simple intervals overlap.
   * Intervals are considered overlapping if they share any day *except*
   * when the end day of one is the start day of the other.
   * @param int1 - The first interval [start1, end1].
   * @param int2 - The second interval [start2, end2].
   * @returns True if the intervals overlap, false otherwise.
   */
  private static intervalsOverlap(int1: SimpleInterval, int2: SimpleInterval): boolean {
    const [s1, e1] = int1;
    const [s2, e2] = int2;
    // Overlap occurs if (StartA < EndB) and (StartB < EndA)
    // Changed from <= to < to exclude adjacent intervals where end1 === start2 or end2 === start1
    return s1 < e2 && s2 < e1;
  }

  /**
   * Checks if any date ranges within an array overlap with each other.
   * @param data - An array of phenological state objects, each with a start and end date.
   * @returns True if any two date ranges in the array overlap, false otherwise.
   */
  public static isDateOverlap(
    data: LocalPhenologicalStates[] | PhenologicalStateWithId[],
  ): boolean {
    if (data.length < 2) { // No overlap possible with fewer than 2 states
      return false;
    }

    const hasOverlap = data.some((range1, i) => {
      // Compare range1 with all subsequent ranges (range2)
      const overlapsWithSubsequent = data.slice(i + 1).some((range2) => {
        // Normalize each range into one or two simple intervals
        const intervals1 = PhenologicalStateChecker.normalizeRange(range1);
        const intervals2 = PhenologicalStateChecker.normalizeRange(range2);

        // Check if any interval from range1 overlaps with any interval from range2
        const internalOverlap = intervals1.some(
          (int1) => intervals2.some(
            (int2) => PhenologicalStateChecker.intervalsOverlap(int1, int2),
          ),
        );
        return internalOverlap;
      });
      return overlapsWithSubsequent;
    });

    return hasOverlap;
  }

  /**
   * Sorts phenological states by start date
   * @param states - Array of phenological states to sort
   * @returns A new sorted array of phenological states
   */
  public static sortByStartDate(
    states: PhenologicalStateWithId[],
  ): (PhenologicalStateWithId)[] {
    return [...states].sort((a, b) => {
      const dateA = new Date(a.startDate);
      const dateB = new Date(b.startDate);
      return dateA.getTime() - dateB.getTime();
    });
  }
}

// Export the class and the necessary type
export { PhenologicalStateChecker, LocalPhenologicalStates };
