import React, { useState, useEffect } from 'react';
import {
  Box, Typography, FormControl, InputLabel, Select, MenuItem,
} from '@mui/material';

const MonthDayPicker = ({
  value,
  onChange,
  label,
  error = false,
  helperText = '',
  includeYear = false,
  startYear = new Date().getFullYear(),
}: {
  value: string,
  onChange: (value: string) => void,
  label: string,
  error?: boolean,
  helperText?: string,
  includeYear?: boolean,
  startYear?: number,
}) => {
  const [month, setMonth] = useState<number | ''>('');
  const [day, setDay] = useState<number | ''>('');
  const [year, setYear] = useState<number | ''>('');

  const months = Array.from({ length: 12 }, (_, i) => i + 1);
  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const years = Array.from({ length: 2 }, (_, i) => startYear + i);

  useEffect(() => {
    if (value) {
      if (includeYear && value.includes('-', 3)) {
        // Format: YYYY-MM-DD
        const [yearStr, monthStr, dayStr] = value.split('-');
        setYear(parseInt(yearStr, 10));
        setMonth(parseInt(monthStr, 10));
        setDay(parseInt(dayStr, 10));
      } else {
        // Format: MM-DD
        const [monthStr, dayStr] = value.split('-');
        setMonth(parseInt(monthStr, 10));
        setDay(parseInt(dayStr, 10));
      }
    }
  }, [value, includeYear]);

  const updateValue = (m: number | '', d: number | '', y: number | '') => {
    if (!m || !d) return;

    const monthStr = m.toString().padStart(2, '0');
    const dayStr = d.toString().padStart(2, '0');

    if (includeYear && y) {
      onChange(`${y}-${monthStr}-${dayStr}`);
    } else {
      onChange(`${monthStr}-${dayStr}`);
    }
  };

  const handleMonthChange = (newMonth: number) => {
    setMonth(newMonth);
    updateValue(newMonth, day, year);
  };

  const handleDayChange = (newDay: number) => {
    setDay(newDay);
    updateValue(month, newDay, year);
  };

  const handleYearChange = (newYear: number) => {
    setYear(newYear);
    updateValue(month, day, newYear);
  };

  return (
    <Box>
      <Typography variant="caption" color={error ? 'error' : 'textSecondary'}>
        {label}
      </Typography>
      <Box display="flex" alignItems="flex-end">
        {includeYear && (
          <FormControl variant="standard" error={error} sx={{ minWidth: 60, mr: 1 }}>
            <InputLabel>Año</InputLabel>
            <Select
              value={year || ''}
              onChange={(e) => handleYearChange(e.target.value as number)}
            >
              {years.map((y) => (
                <MenuItem key={y} value={y}>{y}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <FormControl variant="standard" error={error} sx={{ minWidth: 80, mr: 1 }}>
          <InputLabel>Mes</InputLabel>
          <Select
            value={month || ''}
            onChange={(e) => handleMonthChange(e.target.value as number)}
          >
            {months.map((m) => (
              <MenuItem key={m} value={m}>{m}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="standard" error={error} sx={{ minWidth: 80 }}>
          <InputLabel>Día</InputLabel>
          <Select
            value={day || ''}
            onChange={(e) => handleDayChange(e.target.value as number)}
          >
            {days.map((d) => (
              <MenuItem key={d} value={d}>{d}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {error && helperText && (
        <Typography color="error" variant="caption">{helperText}</Typography>
      )}
    </Box>
  );
};

export default MonthDayPicker;
