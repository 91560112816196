import React, { useEffect, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ListLayout from '../ListLayout';
import useStyles from '../../styles';
import ListTile from '../ListTile';
import CustomButton from '../../../../../components/General/CustomButton';
import AddDevicesFlow from './AddDevicesFlow';
import { ReduxState } from '../../../../../types';
import useFetchApi from '../../../../../hooks/useFetchApi';
import { GetDevicesByFarmIdResponse } from '../../../../../requests/api/apiTypes';
import { setAllFarmDevices, setFarmDevice } from '../../../../../actions/farmSectorActions';
import { filteredDevices } from '../../helpers';

const DevicesList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openAddDevicesModal, setOpenAddDevicesModal] = useState(false);
  const { selectedFarm } = useSelector((state: ReduxState) => state.accountFarm);
  const { selectedSector } = useSelector((state: ReduxState) => state.currentSector);

  const {
    selectedDevice,
    sensorNodeFilter,
    allFarmDevices,
  } = useSelector((state: ReduxState) => state.farmSector);
  const history = useHistory();

  const validFarmId = selectedFarm?.id ?? undefined;

  const { data } = useFetchApi<GetDevicesByFarmIdResponse>({
    modelEndpoint: 'farmsDetail',
    modelName: 'devices',
    id: validFarmId || '',
    dependencies: [validFarmId ?? ''],
  });

  useEffect(() => {
    if (data?.devices) {
      dispatch(setAllFarmDevices(data.devices));
    }
  }, [data?.devices]);

  const handleDeviceClick = (deviceId: string) => {
    if (selectedDevice === deviceId) {
      dispatch(setFarmDevice(''));
    } else {
      dispatch(setFarmDevice(deviceId));
    }
  };

  // TO DO: Adaptar lo que devuelve el upsert si es necesario
  return (
    <>
      <ListLayout>
        <Typography
          variant="h3"
          color="primary"
        >
          Equipos
        </Typography>
        <Divider className={classes.divider} />
        {selectedFarm
          && <>
            <Box marginBottom='12px' marginTop="12px" width='100%'>
              <CustomButton buttonText='AGREGAR EQUIPO' onClick={() => setOpenAddDevicesModal(true)} color='blue' />
            </Box>
            <Box className={classes.listTilesContainer}>
              {(selectedFarm && allFarmDevices && allFarmDevices.length > 0)
                && filteredDevices(allFarmDevices, sensorNodeFilter)
                  .filter((device) => (selectedSector ? device.sector === selectedSector.id : true))
                  .map((elem) => {
                    const {
                      id, deviceModel, isGatewayNode, sensorType, nodeId,
                    } = elem;
                    return (
                      <React.Fragment key={id}>
                        <ListTile
                          key={id}
                          title={deviceModel?.model}
                          tileInformation={{
                            Marca: deviceModel?.brand ?? 'Sin marca',
                            Código: deviceModel?.cdtecDeviceId ?? 'Sin código',
                            ...(sensorNodeFilter.deviceType === 'sensor' && {
                              'Tipo de sensor': sensorType ?? 'Sin tipo',
                              'Nodo asociado': typeof nodeId === 'object'
                                ? nodeId?.deviceModel?.cdtecDeviceId ?? 'Sin nodo asociado'
                                : nodeId ?? 'Sin nodo asociado',
                            }),
                            ...(sensorNodeFilter.deviceType === 'node' && {
                              Gateaway: isGatewayNode ? 'Si' : 'No',
                            }),
                          }}
                          isActive={selectedDevice === id}
                          onClick={() => handleDeviceClick(id ?? '')}
                          customContainerClassName={classes.actionableListTileContainer}
                          seeMore={() => history.push(`/layout/equipos/${id}`)}
                        />
                        <Divider />
                      </React.Fragment>
                    );
                  })}
              {(selectedFarm && !(allFarmDevices && allFarmDevices.length > 0)) && <Typography variant='body2'>
                No hay equipos asociados a este campo
              </Typography>}
            </Box>
          </>}
        {!selectedFarm && <Typography variant='body2'>
          Elige un campo para ver los equipos asociados
        </Typography>}

      </ListLayout>
      <AddDevicesFlow
        isModalOpen={openAddDevicesModal}
        setModalOpen={setOpenAddDevicesModal}
      />
    </>

  );
};

export default DevicesList;
