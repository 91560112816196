import dayjs from 'dayjs';
import React, {
  useEffect, useState, useMemo, useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { CircularProgress, Box } from '@mui/material';
import BaseHighchart from '../BaseHighchart';
import prepareData from '../StackedSoilMoistureChart/builder';
import { navigateToChart } from '../../../utils/helpers';
import apiClient from '../../../requests/api/apiClient';
import { saveChartData, getChartData } from '../../../services/indexDBService';
import { calculateDateRangeFromNotification } from '../utils';
import {
  ChartDataType,
  ChartFilters,
  ScatterPoint,
} from '../types';

import type PreparedData from './types';
import { ReduxState } from '../../../types';
import ChartFilter from '../ChartFilter';
import { ConversationSchema, TimePointSchema } from '../../../requests/api/apiTypes';
import ConversationModal from '../ConversationTab/conversationModal';
import { FetchError } from '../../../views/types';
import { SOIL_MOISTURE_NAME } from '../../../constants/graphs';
import { setStartDate, setEndDate } from '../../../actions/graphsFilterActions';

function SoilMoistureChart({
  chartView,
  sectorId,
  farmId,
  wiseconnZoneId,
  hideYValues = false,
  hideLegend = false,
  hideXValues = false,
  height = 450,
  title = 'Gráfico de humedad suelo',
  yAxisTitle = 'Humedad Suelo Acumulada (%)',
  selectedDepths = undefined,
  externalModalState,
  externalSetModalState,
  externalConversations,
  externalSetConversations,
  configuredAreas = undefined,
}: {
  chartView: 'dashboard' | 'graphs' | 'settings'
  sectorId: string
  farmId: string
  wiseconnZoneId: string | undefined
  hideYValues?: boolean
  hideLegend?: boolean
  hideXValues?: boolean
  height?: number | string
  title?: string
  yAxisTitle?: string
  selectedDepths?: string[]
  externalModalState?: {
    open: boolean;
    conversationId: string;
    timePointData: TimePointSchema;
  };
  externalSetModalState?: React.Dispatch<React.SetStateAction<{
    open: boolean;
    conversationId: string;
    timePointData: TimePointSchema;
  }>>;
  externalConversations?: ConversationSchema[];
  externalSetConversations?: React.Dispatch<React.SetStateAction<ConversationSchema[]>>;
  configuredAreas?: any[]
}) {
  const [chartData, setChartData] = useState<ChartDataType>({
    series: [],
    categories: [],
    categoriesByIndex: [],
    absoluteCategories: [],
  });
  const [yAxisRange, setYAxisRange] = useState({ min: 0, max: 100 });
  const [isLoading, setIsLoading] = useState(true);

  const [internalSelectedDepths, setInternalSelectedDepths] = useState<string[]>([]);
  const [internalModalState, setInternalModalState] = useState<{
    open: boolean;
    conversationId: string;
    timePointData: TimePointSchema;
  }>({
    open: false,
    conversationId: '',
    timePointData: {
      hour: '',
      day: '',
      month: '',
      year: '',
    },
  });
  const [internalConversations, setInternalConversations] = useState<ConversationSchema[]>([]);
  const [areas, setAreas] = useState<any[]>([]);
  const [plotBands, setPlotBands] = useState<any[]>([]);
  const [plotLines, setPlotLines] = useState<any[]>([]);
  const [hideDepthsText, setHideDepthsText] = useState(true);

  const { startDate, endDate } = useSelector((state: ReduxState) => state.graphsFilter);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dateParam = searchParams.get('date');

  const modalState = externalModalState || internalModalState;
  const setModalState = externalSetModalState || setInternalModalState;
  const conversations = externalConversations || internalConversations;
  const setConversations = externalSetConversations || setInternalConversations;

  const defaultArea = {
    from: 0,
    to: 1000,
    color: 'rgba(128, 245, 192, 0.5)',
    label: {
      text: '',
      align: 'right',
      verticalAlign: 'top',
      x: -10,
      y: 20,
    },
  };

  const getAreaColor = (areaType: string) => {
    switch (areaType) {
      case 'blue':
        return 'rgba(127, 192, 255, 0.4)';
      case 'green':
        return 'rgba(128, 245, 192, 0)';
      case 'red':
        return 'rgba(255, 192, 192, 0.3)';
      case 'yellow':
        return 'rgba(255, 255, 0, 0.3)';
      default:
        return 'rgba(128, 255, 192, 0.4)';
    }
  };

  const generatePlotBands = useCallback(() => {
    if (areas.length === 0) {
      return {
        plotBands: [defaultArea],
        plotLines: [{
          value: defaultArea.to,
          color: '#000000',
          width: 1,
          zIndex: 0,
        }],
      };
    }

    const blueArea = areas.find((area) => area.areaType === 'blue');
    const yellowArea = areas.find((area) => area.areaType === 'yellow');
    const redArea = areas.find((area) => area.areaType === 'red');

    const updatedDefaultArea = {
      ...defaultArea,
      from: (() => {
        if (yellowArea) return yellowArea.to;
        if (redArea) return redArea.to;
        return 0;
      })(),
      to: blueArea ? blueArea.from : 1000,
    };

    const bands = [
      updatedDefaultArea,
      ...areas?.map((area) => {
        let labelValue;
        if (area.areaType === 'blue') {
          labelValue = area.from;
        } else if (area.areaType === 'green') {
          labelValue = area.to;
        } else {
          labelValue = area.to;
        }

        return {
          from: area.from,
          to: area.to,
          color: getAreaColor(area.areaType),
          label: {
            text: `${area.label} (${labelValue})`,
            style: {
              fontSize: '12px',
              color: '#666',
            },
            align: 'right',
            verticalAlign: area.areaType === 'blue' ? 'bottom' : 'top',
            x: -10,
            y: -5,
          },
        };
      }),
    ];

    const lines = bands.map((band) => ({
      value: band.to,
      color: '#000000',
      width: 1,
      zIndex: 1,
    }));

    return {
      plotBands: bands,
      plotLines: lines,
    };
  }, [areas]);

  // Update plotBands whenever areas change
  useEffect(() => {
    const { plotBands: newPlotBands, plotLines: newPlotLines } = generatePlotBands();
    setPlotBands(newPlotBands);
    setPlotLines(newPlotLines);
  }, [areas, generatePlotBands]);

  useEffect(() => {
    const loadInitialConfig = async () => {
      if (selectedDepths === undefined && configuredAreas === undefined && sectorId) {
        try {
          const config = await apiClient.soilMoistureConfig.soilMoistureConfigDetail(sectorId);
          setInternalSelectedDepths(config.data.soilMoistureConfig?.levels || []);
          setAreas(config.data.soilMoistureConfig?.areas || []);
        } catch (error) {
          setInternalSelectedDepths([]);
          setAreas([]);
        }
      } else {
        setInternalSelectedDepths(selectedDepths || []);
        setAreas(configuredAreas || []);
      }
    };

    loadInitialConfig();
  }, [wiseconnZoneId, selectedDepths, configuredAreas, sectorId]);

  const calculateSummedSeries = useCallback((data: PreparedData, depthsToSum: string[]) => {
    const depthSeries = data.series.filter(
      (item: { name: string; data: number[] }) => depthsToSum.includes(item.name),
    );
    // Find minimum length among all series
    const minLength = Math.min(...depthSeries.map((series) => series.data.length));

    const summedData = depthSeries.reduce(
      (acc: number[], curr: { name: string; data: number[] }) => {
        // Only iterate up to minLength
        for (let i = 0; i < minLength; i += 1) {
          acc[i] = (acc[i] || 0) + curr.data[i];
        }
        return acc;
      },
      [],
    );
    return summedData.map((y: number, x: number) => ({
      x,
      y,
    }));
  }, []);

  const calculateYAxisRange = useCallback((summedData: { x: number; y: number }[]) => {
    const values = summedData.map((point) => point.y);
    const dataMin = Math.min(...values);
    const dataMax = Math.max(...values);

    const redArea = areas.find((area) => area.areaType === 'red');
    const blueArea = areas.find((area) => area.areaType === 'blue');
    const greenAreas = areas.filter((area) => area.areaType === 'green');
    const yellowArea = areas.find((area) => area.areaType === 'yellow');

    let min = dataMin;
    let max = dataMax;

    // Check red area for min
    if (redArea) {
      min = Math.min(redArea.to - 1, min);
    }

    if (yellowArea) {
      min = Math.min(yellowArea.to, min);
    }

    // Check blue area for max
    if (blueArea) {
      max = Math.max(blueArea.from + 1, max);
    }

    // Check all green areas for both min and max
    greenAreas.forEach((greenArea) => {
      min = Math.min(greenArea.from, min);
      max = Math.max(greenArea.to, max);
    });

    // Add padding of 2 units if data extends beyond areas
    if (dataMin < min) min = dataMin;
    if (dataMax > max) max = dataMax;

    return {
      min,
      max,
    };
  }, [areas]);

  useEffect(() => {
    let isMounted = true;

    const safeFetchData = async () => {
      setIsLoading(true);
      try {
        const cachedData = await getChartData('soilMoistureChart', startDate, endDate, sectorId);

        let preparedData;
        if (cachedData) {
          preparedData = cachedData.data;
        } else {
          const chartFilters: ChartFilters = {
            farmId,
            startDate,
            endDate,
          };

          if (wiseconnZoneId) {
            chartFilters.wiseconnZoneId = wiseconnZoneId;
          }

          preparedData = await prepareData(chartFilters);
          await saveChartData('soilMoistureChart', startDate, endDate, sectorId, preparedData);
        }

        const summedSeries = calculateSummedSeries(preparedData, internalSelectedDepths);
        const range = calculateYAxisRange(summedSeries);

        if (isMounted) {
          setYAxisRange(range);
          setChartData({
            series: internalSelectedDepths.length > 0
              ? [{
                type: 'line',
                name: 'Suma de Profundidades',
                data: summedSeries,
              }]
              : [{
                type: 'line',
                name: 'Sin datos seleccionados',
                data: [],
              }],
            categories: preparedData.categories,
            absoluteCategories: preparedData.absoluteCategories,
          });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    safeFetchData();

    return () => {
      isMounted = false;
    };
  }, [
    startDate,
    endDate,
    farmId,
    wiseconnZoneId,
    internalSelectedDepths,
    calculateSummedSeries,
    calculateYAxisRange,
    sectorId,
  ]);

  const customTooltip = useMemo(() => ({
    shared: true,
    useHTML: true,
    formatter(
      this: Highcharts.TooltipFormatterContextObject,
    ) {
      if (this.series.name === 'Comentarios') {
        const point = this.point as ScatterPoint;
        const conversationId = point.custom?.conversationId;
        if (!conversations || !conversationId) {
          return '<b>Último comentario:</b> Cargando...<br/>';
        }
        const conversation = conversations.find((conv) => conv.conversationId === conversationId);
        if (!conversation) {
          return '<b>Último comentario:</b> No se encontró la conversación<br/>';
        }
        const lastComment = conversation.lastComment || 'Sin comentarios';
        return `<b>Último comentario:</b> ${lastComment}<br/>
                <span style="color: gray; font-size: 10px">${dayjs(conversation.updatedAt).format('MMM DD, YYYY HH:mm')}</span>`;
      }
      const pointIndex = this.points?.[0].point.index;
      const category = dayjs(chartData?.absoluteCategories?.[pointIndex as number]).format('MMM DD, YYYY HH:mm');
      const header = '<div style="font-size: 14px; font-weight: bold; margin-bottom: 8px;">Resumen</div>';
      const subHeader = `<div style="font-size: 12px; margin-bottom: 8px;">${category}</div>`;
      const points = this.points?.map(
        (point) => `<div style="margin: 4px 0"><span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${Number(point.y).toFixed(2)}</b></div>`,
      ).join('');
      return `<div style="padding: 8px">${header}${subHeader}${points}</div>`;
    },
  }), [chartData.absoluteCategories, conversations]);

  useEffect(() => {
    if (!externalConversations) {
      const loadConversations = async () => {
        try {
          const { data } = await apiClient.conversation.conversationDetail(
            sectorId,
            SOIL_MOISTURE_NAME,
          );
          setInternalConversations(data.conversations);
        } catch (err) {
          const { error } = err as FetchError;
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      };

      loadConversations();
    }
  }, [sectorId, externalConversations]);

  const handleConfigClick = useCallback(() => {
    navigateToChart('humedad-suelo', history, {
      farmId,
      sectorId,
      wiseconnZoneId: wiseconnZoneId || '',
    });
  }, [history, farmId, sectorId, wiseconnZoneId]);

  const chartConfig = useMemo(() => ({
    chart: {
      height,
    },
    xAxis: {
      categories: hideXValues ? [] : chartData.categories,
      labels: {
        step: 1,
        enabled: !hideXValues,
      },
      plotLines: (() => {
        const lines: any[] = [];

        chartData.categories.forEach((category, index) => {
          if (category && category.trim() !== '' && index > 0) {
            lines.push({
              color: '#999999',
              width: 1,
              dashStyle: 'Dash',
              value: index - 0.5,
            });
          }
        });

        return lines;
      })(),
    },
    yAxis: {
      min: yAxisRange.min,
      max: yAxisRange.max,
      plotBands,
      plotLines,
      labels: {
        enabled: !hideYValues,
      },
      title: {
        text: yAxisTitle,
      },
      gridLineWidth: 0,
    },
  }), [
    height,
    hideXValues,
    chartData.categories,
    yAxisRange.min,
    yAxisRange.max,
    hideYValues,
    yAxisTitle,
    plotBands,
    plotLines,
  ]);

  useEffect(() => {
    if (dateParam) {
      // Convert date from DD-MM-YYYY to YYYY-MM-DD
      const { rangeStartDate, rangeEndDate } = calculateDateRangeFromNotification(dateParam);
      // Update the date range in the store
      dispatch(setStartDate(rangeStartDate));
      dispatch(setEndDate(rangeEndDate));
    }
  }, [dateParam, dispatch]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={height}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <div>
        {(chartView === 'dashboard' && !hideDepthsText) && (
          <p style={{ marginLeft: '80px', fontSize: '12px', color: '#666' }}>
            Profundidades seleccionadas: {internalSelectedDepths.length > 0 ? internalSelectedDepths.join(', ') : 'Ninguna'}
          </p>
        )}
        <BaseHighchart
          setHideDepthsText={setHideDepthsText}
          chartView={chartView}
          title={title}
          yAxisTitle={yAxisTitle}
          series={chartData.series as Highcharts.SeriesOptionsType[]}
          tooltip={customTooltip}
          chartConfig={chartConfig}
          filter={chartView === 'settings' ? <ChartFilter sectorId={sectorId} /> : undefined}
          onConfigClick={handleConfigClick}
          hideYValues={hideYValues}
          legend={{ enabled: !hideLegend }}
          setModalState={setModalState}
          conversations={conversations}
          absoluteCategories={chartData.absoluteCategories}
          height={height}
          showFullScreen={true}
        />
      </div>
      <ConversationModal
        modalState={modalState}
        setModalState={setModalState}
        farmId={farmId}
        sectorId={sectorId}
        chart={SOIL_MOISTURE_NAME}
        setConversationsFunction={setConversations}
      />
    </>
  );
}

export default SoilMoistureChart;
