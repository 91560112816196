import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const monthNames = [
  'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio',
  'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre',
];

export const formatDateToIsoString = (dateString: string): string => {
  if (dateString.endsWith('Z')) {
    return dateString;
  }
  const formattedDate = dayjs(dateString).format('YYYY-MM-DDTHH:mm:ss');
  return `${formattedDate}.000Z`;
};

export const ignoreDateTimezone = (dateString: string): Date => {
  const minuteInMs = 60 * 1000;
  const date = new Date(dateString);
  const userTimezoneOffset = date.getTimezoneOffset() * minuteInMs;
  return new Date(date.getTime() + userTimezoneOffset);
};

export const formatLocaleDate = (dateString: string): string => {
  const auxDate = new Date(dateString);
  return auxDate.toLocaleDateString('es-ES', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })
    .replace(/\//g, '-');
};

function formatDate(dateString: string): string {
  const date = new Date(dateString);

  const day = date.getUTCDate().toString().padStart(2, '0');
  const month = monthNames[date.getUTCMonth()];
  const year = date.getUTCFullYear();

  return `${day} de ${month} del ${year}`;
}

/**
 * Converts an ISO date string to YYYY-MM-DD HH:mm format by simple string manipulation
 * @param isoDateString - ISO date string (e.g. 2025-02-14T00:15:00.000Z)
 * @returns Formatted date string (e.g. 2025-02-14 00:15)
 */
export const formatISOToSimpleDateTime = (isoDateString: string): string => {
  // Extract date and time parts before the decimal point or Z
  const dateTimeParts = isoDateString.split('T');
  if (dateTimeParts.length !== 2) return isoDateString;

  const datePart = dateTimeParts[0]; // YYYY-MM-DD
  const timePart = dateTimeParts[1].split(/[.Z]/)[0]; // HH:mm:ss

  // Return date + time truncated to minutes
  return `${datePart} ${timePart.substring(0, 5)}`;
};

/**
 * Converts a UTC date string to local time in Santiago, Chile.
 * @param utcDateString - The UTC date string (ISO format recommended).
 * @returns Formatted local date-time string in Chilean time.
 */
export const convertUTCToLocalSantiago = (utcDateString: string): string => dayjs.utc(utcDateString).tz('America/Santiago').format('YYYY-MM-DD HH:mm:ss');

export default formatDate;
/**
 * Converts an ISO date string to YYYY-MM-DD HH:mm format
 * @param isoDateString - ISO date string (e.g. 2025-02-14T00:15:00.000Z)
 * @returns Formatted date string (e.g. 2025-02-14 00:15)
 */
