import React, { useEffect, useState } from 'react';
import {
  IconButton, Box, Typography, Divider, TextField,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import CustomModal from '../../General/CustomModal';
import useStyles from '../styles';
import { FetchError } from '../../../views/types';
import {
  CommentSchema, ConversationSchema, PostCommentOnNewConversationSchema, PostCommentSchema,
  PostNotificationSchema,
  TimePointSchema,
} from '../../../requests/api/apiTypes';
import apiClient from '../../../requests/api/apiClient';
import { ReduxState } from '../../../types';
import { formatDate, stringToColor } from '../utils';
import { HIGHCHARTS_ROUTES_MAP } from '../constants';

const ConversationModal = ({
  modalState,
  setModalState,
  farmId,
  sectorId,
  chart,
  setConversationsFunction,
}: {
  modalState: { open: boolean; conversationId: string; timePointData: TimePointSchema };
  setModalState: React.Dispatch<React.SetStateAction<{
    open: boolean;
    conversationId: string;
    timePointData: TimePointSchema;
  }>>;
  farmId: string;
  sectorId: string;
  chart: string;
  setConversationsFunction: React.Dispatch<React.SetStateAction<ConversationSchema[]>>;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [currentComment, setCurrentComment] = useState({
    message: '',
  });
  const [sendCommentButtonDisabled, setSendCommentButtonDisabled] = useState(false);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [comments, setComments] = useState<CommentSchema[]>([]);
  const currentState = useSelector((state: ReduxState) => state);
  const { user } = currentState.account;
  const { selectedAccount } = currentState.userAccount;
  const { selectedSector } = currentState.currentSector;

  const classes = useStyles();

  const truncateMessage = (
    message: string,
    maxLength = 20,
  ) => (message.length > maxLength ? `${message.substring(0, maxLength)}...` : message);

  const createCommentNotification = (
    userId: string,
    commentMessage: string,
    conversationIdOverride?: string,
  ): PostNotificationSchema => {
    // Construir la fecha del timePoint
    const timePointDate = modalState.timePointData
      ? `${modalState.timePointData.day}-${modalState.timePointData.month}-${modalState.timePointData.year}`
      : '';

    let path = `/graficos/${HIGHCHARTS_ROUTES_MAP[chart]}?farmId=${farmId}&sectorId=${sectorId}`;
    if (selectedSector?.wiseconnId) {
      path += `&wiseconnZoneId=${selectedSector.wiseconnId}`;
    }
    if (timePointDate) {
      path += `&date=${timePointDate}`;
    }

    const notification = {
      title: 'Nuevo comentario',
      message: truncateMessage(commentMessage),
      userId,
      type: 'comment',
      createdBy: user?.id || '',
      sector: sectorId,
      farm: farmId,
      account: selectedAccount?.id || '',
      highchartName: chart,
      path,
      conversationId: conversationIdOverride || modalState.conversationId || '',
    };

    return notification;
  };

  const notifyAccountUsers = async (commentMessage: string, conversationIdOverride?: string) => {
    if (!selectedAccount?.id) return;

    try {
      const { data } = await apiClient.users.accountDetail(selectedAccount.id);
      const accountUsers = (data.users || []).filter((accountUser) => accountUser.id !== user?.id);

      const notifications = accountUsers.map((accountUser) => {
        const notification = createCommentNotification(accountUser.id || '', commentMessage, conversationIdOverride);
        return notification;
      });

      await Promise.all(notifications.map(
        (notification) => apiClient.createNotification.createNotificationCreate(notification),
      ));
    } catch (err) {
      const { error } = err as FetchError;
      enqueueSnackbar(`Error notifying users: ${error.message}`, { variant: 'error' });
    }
  };

  const handleClose = () => {
    setModalState({
      open: false,
      conversationId: '',
      timePointData: {
        hour: '',
        day: '',
        month: '',
        year: '',
      },
    });
    setCurrentComment({ message: '' });
    setComments([]);
  };

  useEffect(() => {
    const loadComments = async () => {
      setCommentsLoading(true);
      try {
        const { data } = await apiClient.comment.commentDetail(modalState.conversationId);
        setComments(data.comments);
      } catch (err) {
        const { error } = err as FetchError;
        enqueueSnackbar(error.message, { variant: 'error' });
      } finally {
        setCommentsLoading(false);
      }
    };

    if (modalState.conversationId) {
      loadComments();
    }
  }, [modalState.conversationId]);

  const handleSubmit = async () => {
    if (!currentComment.message.trim()) return;
    setSendCommentButtonDisabled(true);
    try {
      if (modalState.conversationId && user?.id) {
        // Existing conversation
        const newComment: PostCommentSchema = {
          conversationId: modalState.conversationId,
          author: user.id,
          message: currentComment.message,
        };

        const response = await apiClient.comment.commentCreate(newComment);
        const { comment, conversation } = response.data;

        setComments((prevComments) => [...prevComments, comment]);
        setConversationsFunction((prevConversations) => [
          conversation,
          ...prevConversations.filter(
            (conv) => conv.conversationId !== conversation.conversationId,
          ),
        ]);
        await notifyAccountUsers(currentComment.message, modalState.conversationId);
        setCurrentComment({ message: '' });
      } else if (user?.id) {
        // New conversation
        const conversationOpener: PostCommentOnNewConversationSchema = {
          farmId,
          sectorId,
          chart,
          author: user.id,
          message: currentComment.message,
          timePoint: modalState.timePointData,
        };

        const response = await apiClient.conversation.conversationCreate(conversationOpener);
        const { conversation, comment } = response.data;

        // Store the conversationId immediately
        const newConversationId = conversation.conversationId;

        setComments((prevComments) => [...prevComments, comment]);
        setConversationsFunction((prevConversations) => [
          {
            ...conversation,
            updatedAt: comment.createdAt,
          },
          ...prevConversations,
        ]);
        setModalState((prevState) => ({
          ...prevState,
          conversationId: newConversationId,
        }));

        // Use the conversationId directly instead of waiting for state update
        await notifyAccountUsers(currentComment.message, newConversationId);
        enqueueSnackbar('Comentario publicado', { variant: 'success' });
      }
    } catch (err) {
      const { error } = err as FetchError;
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setCurrentComment({ message: '' });
      setSendCommentButtonDisabled(false);
    }
  };

  return (
    <CustomModal open={modalState.open} handleClose={handleClose}>
      <Box className={classes.conversationModalContainer}>
        <Box className={classes.conversationModalHeader}>
          <Typography
            variant="h3"
            color="textPrimary"
            className={classes.conversationModalHeaderTitle}
          >
            <span className={classes.activePageUnderlined}>
              {modalState.conversationId ? 'Comentarios' : 'Inicia una conversación'}
            </span>
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider className={classes.divider} />
        {/* Revisar que este loading se vea bien */}
        {commentsLoading ? (
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '200px',
          }}>
            <CircularProgress size={40} />
          </Box>
        ) : (
          <>
            <Box className={classes.commentsList}>
              {comments.map((comment) => (
                <Box key={comment.id} className={classes.commentContainer}>
                  <Box className={classes.commentHeader}>
                    <Typography
                      className={classes.commentAuthor}
                      style={{ color: stringToColor(comment.author || 'Usuario') }}
                    >
                      {comment.author || 'Usuario'}
                    </Typography>
                    <Typography className={classes.commentSeparator}>/</Typography>
                    <Typography className={classes.commentDate}>
                      {formatDate(comment.createdAt, false)}
                    </Typography>
                  </Box>
                  <Typography className={classes.commentMessage}>
                    {comment.message}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Box className={classes.newCommentSection}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <TextField
                  variant="standard"
                  size="medium"
                  fullWidth
                  label="Escribe tu comentario aquí"
                  InputProps={{
                    endAdornment: <IconButton
                      onClick={handleSubmit}
                      disabled={!currentComment.message.trim() || sendCommentButtonDisabled}
                      color="primary"
                    >
                      <SendIcon />
                    </IconButton>,
                  }}
                  value={currentComment.message}
                  onChange={(e) => setCurrentComment({ message: e.target.value })}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey && currentComment.message.trim() && !sendCommentButtonDisabled) {
                      handleSubmit();
                    }
                  }}
                />
              </Box>
            </Box>
          </>
        )}
      </Box>
    </CustomModal>
  );
};

export default ConversationModal;
