import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  notificationContainer: {
    padding: theme.spacing(0),
    maxWidth: '300px',
  },
  notificationItem: {
    padding: theme.spacing(2),
    transition: 'background-color 0.3s',
  },
  notificationBody: {
    fontSize: '11px',
  },
  notificationContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  notificationText: {
    flex: 1,
  },
  notificationTitle: {
    fontWeight: 'bold',
    color: theme.palette.text.primary,
  },
  notificationMenu: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  readCircle: {
    color: theme.palette.grey[500], // Color gris tenue
    marginTop: theme.spacing(0.5), // Espaciado debajo del menú
    cursor: 'pointer',
  },
  notificationMenuContainer: {
    display: 'flex',
    flexDirection: 'column', // Alinear los botones verticalmente
    alignItems: 'center',
    justifyContent: 'space-between', // Espacio entre el menú y el círculo
  },
  menuButton: {
    marginBottom: theme.spacing(1), // Espaciado entre el menú y el círculo
  },
  tab: {
    fontSize: '12px',
  },
}));

export default useStyles;
