import React, { useState } from 'react';
import {
  Box, Divider, Grid, IconButton, MenuItem, TextField, Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import useStyles from '../styles';

import { PhenologicalState, PhenologicalStateWithId } from '../../../../../requests/api/apiTypes';
import CustomButton from '../../../../../components/General/CustomButton';
import phenologicalStatesNameOptions from '../../../../Varieties/types';
import { CultivationCycle } from '../types';
import { ignoreDateTimezone } from '../../../../../utils/formatDate';
import { PhenologicalStateChecker } from '../../../../../utils/varieties';

const UpsertPhenologicalStateModalContent = ({
  handleCloseModal,
  handleUpsertFunction,
  id,
  initialData,
  cultivationCycle,
}: {
  handleCloseModal: VoidFunction,
  handleUpsertFunction: (PhenologicalState: PhenologicalStateWithId) => void,
  id?: string,
  initialData?: PhenologicalState,
  cultivationCycle: CultivationCycle
}) => {
  const classes = useStyles();

  const [newPhenologicalState, setNewPhenologicalState] = useState<PhenologicalStateWithId>({
    name: initialData?.name || '',
    startDate: initialData?.startDate || '',
    endDate: initialData?.endDate || '',
    uuid: id || '',
  });

  const [errors, setErrors] = useState({
    name: '',
    startDate: '',
    endDate: '',
  });

  const validateDates = (startDate: string, endDate: string) => {
    const dateErrors = {
      startDate: '',
      endDate: '',
    };

    if (startDate && endDate && dayjs(startDate).isAfter(dayjs(endDate))) {
      dateErrors.endDate = 'La fecha de fin debe ser posterior a la fecha de inicio';
    }

    // Verificar superposición
    if (startDate && endDate) {
      const isEditing = !!id;
      const statesToCheck = isEditing
        ? cultivationCycle.phenologicalStates.filter((state) => state.uuid !== id)
        : cultivationCycle.phenologicalStates;

      const hasOverlap = PhenologicalStateChecker.isDateOverlap([
        ...statesToCheck,
        {
          ...newPhenologicalState,
          startDate,
          endDate,
          uuid: isEditing ? id : uuidv4(),
        },
      ]);

      if (hasOverlap) {
        dateErrors.startDate = 'Fecha tiene superposición con estados anteriores';
        dateErrors.endDate = 'Fecha tiene superposición con estados anteriores';
      }
    }

    setErrors((prev) => ({ ...prev, ...dateErrors }));
    return !dateErrors.startDate && !dateErrors.endDate;
  };

  const validateName = (name: string) => {
    let nameError = '';

    const existingState = cultivationCycle.phenologicalStates.find(
      (state) => state.name === name && state.uuid !== id,
    );

    if (existingState) {
      nameError = 'Ya existe un estado fenológico con este nombre';
    }

    setErrors((prev) => ({ ...prev, name: nameError }));
    return !nameError;
  };

  const handleDataChange = (value: string, name: string) => {
    const updatedState = {
      ...newPhenologicalState,
      [name]: value,
    };
    setNewPhenologicalState(updatedState);

    if (name === 'startDate' || name === 'endDate') {
      validateDates(
        name === 'startDate' ? value : updatedState.startDate,
        name === 'endDate' ? value : updatedState.endDate,
      );
    }

    if (name === 'name') {
      validateName(value);
    }
  };

  const handleUpsertPhenologicalState = () => {
    const isValidDates = validateDates(
      newPhenologicalState.startDate, newPhenologicalState.endDate,
    );
    const isValidName = validateName(newPhenologicalState.name);

    if (!isValidDates || !isValidName) {
      return;
    }

    handleUpsertFunction({
      name: newPhenologicalState.name,
      startDate: newPhenologicalState.startDate,
      endDate: newPhenologicalState.endDate,
      uuid: id || uuidv4(),
    });
  };

  return (
    <Box className={classes.upsertCropCycleModalContainer}>
      <Box className={classes.upsertCropCycleModalHeader}>
        <Typography
          variant="h3"
          color="textPrimary"
          className={classes.upsertCropCycleModalHeaderTitle}
        >
          <span className={classes.activePageUnderlined}>
            Agregar estado fenológico
          </span>
        </Typography>
        <IconButton onClick={handleCloseModal}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider className={classes.dividerStyle} />
      <Grid container spacing={2}>
        <Grid item sm={12} md={12}>
          <TextField
            label="Nombre"
            name="name"
            variant="standard"
            value={newPhenologicalState.name ?? ''}
            onChange={(e) => handleDataChange(e.target.value, 'name')}
            select
            fullWidth
            error={!!errors.name}
            helperText={errors.name}
          >
            {phenologicalStatesNameOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item sm={6} md={6}>
          <DatePicker
            className={classes.selectField}
            label="Fecha de inicio"
            value={new Date(ignoreDateTimezone(newPhenologicalState.startDate))}
            onChange={(value) => value && handleDataChange(dayjs(value).format('YYYY-MM-DD'), 'startDate')}
            minDate={ignoreDateTimezone(cultivationCycle.startDate)}
            maxDate={ignoreDateTimezone(cultivationCycle.endDate)}
            slotProps={{
              textField: {
                helperText: errors.startDate,
                error: !!errors.startDate,
              },
            }}
          />
        </Grid>
        <Grid item sm={6} md={6}>
          <DatePicker
            className={classes.selectField}
            label="Fecha de fin"
            onChange={(value) => value && handleDataChange(dayjs(value).format('YYYY-MM-DD'), 'endDate')}
            minDate={ignoreDateTimezone(cultivationCycle.startDate)}
            maxDate={ignoreDateTimezone(cultivationCycle.endDate)}
            value={new Date(ignoreDateTimezone(newPhenologicalState.endDate))}
            slotProps={{
              textField: {
                helperText: errors.endDate,
                error: !!errors.endDate,
              },
            }}
          />
        </Grid>
      </Grid>
      <Box className={classes.upsertCropCycleModalButtonsContainer}>
        <Box width='100%' mr={2}><CustomButton color='blue' buttonText='Cancelar' onClick={handleCloseModal} /></Box>
        <Box width='100%' ml={2}><CustomButton color='green' buttonText={id ? 'Editar' : 'Agregar'} onClick={handleUpsertPhenologicalState} /></Box>
      </Box>
    </Box>
  );
};

export default UpsertPhenologicalStateModalContent;
