import dayjs from 'dayjs';
import { getET0 } from '../dataServices';
import {
  buildCategories,
  getGranularity,
  getWeeklyAverageData,
  mapDates,
} from '../utils';

import {
  ENDPOINT,
} from '../constants';

const prepareData = async (filters: {
  farmId: string;
  startDate: string;
  endDate: string;
  sectorId?: string;
  wiseconnZoneId?: string;
}) => {
  const granularity = getGranularity(filters.startDate, filters.endDate);

  const et0Data = await getET0({
    farmId: filters.farmId,
    startDate: filters.startDate,
    endDate: filters.endDate,
    granularity: granularity === 'hourly' ? ENDPOINT.hourly : ENDPOINT.et0,
  });
  let et0DataMap = mapDates(et0Data, granularity, 'et0');

  if (granularity === 'weekly') {
    et0DataMap = getWeeklyAverageData(et0DataMap as Map<string, number>);
  }

  const normalizedEt0Data = Array.from(et0DataMap.values());

  const cumulativeEt0 = normalizedEt0Data.map((_, index) => normalizedEt0Data
    .slice(0, index + 1).reduce((acc: any, curr: any) => acc + curr, 0));

  const absoluteCategories = Array.from(et0DataMap.keys()).map((date: any) => dayjs(date).format('YYYY-MM-DD HH:mm'));

  const chartCategories = buildCategories(
    granularity,
    absoluteCategories,
  );

  return {
    et0: normalizedEt0Data, // Et0 per hour
    cumulativeEt0, // Cumulative Et0
    categories: chartCategories,
    absoluteCategories,
  };
};

export default prepareData;
