/* eslint-disable @typescript-eslint/default-param-last */
/* eslint no-param-reassign: ["error", { "props": false }] */
import dayjs from 'dayjs';
import produce, { Draft } from 'immer';
import { GraphFilterState, GraphsFilterCustomAction } from '../types';
import { SET_START_DATE, SET_END_DATE, SET_SELECTED_INTERVAL } from '../actions/graphsFilterActions';

// Helper functions
const getDefaultEndDate = () => {
  const now = dayjs();
  const endOfDay = now.endOf('day');
  return endOfDay.format('YYYY-MM-DDTHH:mm:ss');
};

const getDefaultStartDate = () => {
  const now = dayjs();
  const startOfDay = now.subtract(14, 'day').startOf('day');
  return startOfDay.format('YYYY-MM-DDTHH:mm:ss');
};

const formatStartDate = (date: string) => {
  const d = dayjs(date);
  const startOfDay = d.startOf('day');
  return startOfDay.format('YYYY-MM-DDTHH:mm:ss');
};

const formatEndDate = (date: string) => {
  const now = dayjs(date);
  const endOfDay = now.endOf('day');
  return endOfDay.format('YYYY-MM-DDTHH:mm:ss');
};

const getDefaultInterval = () => '2-weeks';

const initialState: GraphFilterState = {
  startDate: getDefaultStartDate(),
  endDate: getDefaultEndDate(),
  selectedInterval: getDefaultInterval(),
};

const dateFilterReducer = (
  state = initialState,
  action: GraphsFilterCustomAction,
): GraphFilterState => {
  switch (action.type) {
    case SET_START_DATE: {
      return produce(state, (draft: Draft<GraphFilterState>) => {
        draft.startDate = formatStartDate(action.payload);
      });
    }

    case SET_END_DATE: {
      return produce(state, (draft: Draft<GraphFilterState>) => {
        draft.endDate = formatEndDate(action.payload);
      });
    }
    case SET_SELECTED_INTERVAL: {
      return produce(state, (draft: Draft<GraphFilterState>) => {
        draft.selectedInterval = action.payload;
      });
    }

    default:
      return state;
  }
};

export default dateFilterReducer;
