export const ENDPOINT = {
  hourly: '/hourly',
  daily: '/daily',
  weekly: '/weekly',
  et0: '/daily',
};

export const HIGHCHARTS_ROUTES_MAP: { [key: string]: string } = {
  'Humedad Suelo': 'humedad-suelo',
  'Humedad Suelo Apilado': 'humedad-suelo-apilada',
  Riego: 'riego',
  Kc: 'kc',
  ET0: 'et0',
  Precipitaciones: 'precipitaciones',
  'Profundidad de Riego': 'profundidad-riego',
};

export const CHART_LIST_UI = [
  {
    label: 'Fecha Actualización', key: 'updatedAt',
  },
  {
    label: 'Actualizado por', key: 'updatedBy',
  },
  {
    label: 'Último comentario', key: 'lastComment',
  },
  {
    label: 'Campo', key: 'farm',
  },
  {
    label: 'Sector', key: 'sector',
  },
  {
    label: 'Gráfico', key: 'chart',
  },
  {
    label: 'Ver Conversación', key: 'viewConversation',
  },
];
