export const SOIL_MOISTURE_NAME = 'Humedad Suelo';

export const ROOTS_NAME = 'Profundidad de Riego';

export const IRRIGATION_NAME = 'Riego';

export const STACKED_SOIL_MOISTURE_NAME = 'Humedad Suelo Apilado';

export const KC_NAME = 'Kc';

export const ET0_NAME = 'ET0';

export const PRECIPITATION_NAME = 'Precipitaciones';
