import React, { ReactNode } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import { Box, CircularProgress } from '@mui/material';

const AVAILABLE_LIBRARIES: ('drawing' | 'places')[] = ['drawing', 'places'];

interface MapWrapperProps {
  children: ReactNode;
}

const MapWrapper = ({ children }: MapWrapperProps) => {
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '';

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey,
    libraries: AVAILABLE_LIBRARIES,
  });

  if (!isLoaded) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return <>{children}</>;
};

export default MapWrapper;
