import {
  AccountCircle as AccountCircleIcon,
  Announcement as AnnouncementIcon,
  Dashboard as DashboardIcon,
  Home as HomeIcon,
  Settings as SettingsIcon,
  LocalOffer as LocalOfferIcon,
  Message as MessageIcon,
  Nature as NatureIcon,
  Person as PersonIcon,
  Sensors as SensorsIcon,
  Warning as WarningIcon,
  Water as WaterIcon,
  Work as WorkIcon,
} from '@mui/icons-material/';
import { DeviceSchema } from '../../../requests/api/apiTypes';
import { UserType } from '../../../constants/user_types';

export interface SensorTypeStatus {
  suelo: boolean;
  clima: boolean;
  riego: boolean;
  planta: boolean;
}

interface NavItem {
  title: string;
  icon?: any;
  href?: string;
  items?: NavItem[];
  sensorTypes?: SensorTypeStatus;
  userTypesAllowed: UserType[];
}

export const navConfig = [
  {
    title: 'Usuarios',
    icon: PersonIcon,
    href: '/usuarios',
    userTypesAllowed: [UserType.CDTEC],
  },
  {
    title: 'Cuentas',
    icon: AccountCircleIcon,
    href: '/cuentas',
    userTypesAllowed: [UserType.CDTEC, UserType.CONSULTANT],
  },
  {
    title: 'Empresas',
    icon: WorkIcon,
    href: '/empresas',
    userTypesAllowed: [UserType.CDTEC],
  },
  {
    title: 'Equipos',
    icon: SensorsIcon,
    href: '/equipos',
    userTypesAllowed: [UserType.CDTEC],
  },
  {
    title: 'Especies y variedades',
    icon: NatureIcon,
    href: '/especies-variedades',
    userTypesAllowed: [UserType.CDTEC],
  },
];

const specificNavConfig: NavItem[] = [
  {
    title: 'Inicio',
    icon: HomeIcon,
    href: '/inicio',
    userTypesAllowed: [
      UserType.CDTEC, UserType.CONSULTANT, UserType.CLIENT, UserType.MULTI_ACCOUNT_CLIENT],
  },
  {
    title: 'Sectores',
    icon: DashboardIcon,
    items: [],
    userTypesAllowed: [
      UserType.CDTEC, UserType.CONSULTANT, UserType.CLIENT, UserType.MULTI_ACCOUNT_CLIENT],
  },
  {
    title: 'Estado sensores',
    icon: AnnouncementIcon,
    href: '/estado-sensores',
    userTypesAllowed: [
      UserType.CDTEC, UserType.CONSULTANT, UserType.CLIENT, UserType.MULTI_ACCOUNT_CLIENT],
  },
  {
    title: 'Configuración Campo',
    icon: SettingsIcon,
    href: '/layout',
    userTypesAllowed: [
      UserType.CDTEC, UserType.CONSULTANT, UserType.CLIENT, UserType.MULTI_ACCOUNT_CLIENT],
  },
  {
    title: 'Riego',
    icon: WaterIcon,
    href: '/riego',
    userTypesAllowed: [
      UserType.CDTEC, UserType.CONSULTANT, UserType.CLIENT, UserType.MULTI_ACCOUNT_CLIENT],
  },
  {
    title: 'Conversaciones',
    icon: MessageIcon,
    href: '/conversaciones',
    userTypesAllowed: [
      UserType.CDTEC, UserType.CONSULTANT, UserType.CLIENT, UserType.MULTI_ACCOUNT_CLIENT],
  },
  {
    title: 'Alertas',
    icon: WarningIcon,
    href: '/alertas',
    userTypesAllowed: [
      UserType.CDTEC, UserType.CONSULTANT, UserType.CLIENT, UserType.MULTI_ACCOUNT_CLIENT],
  },
  {
    title: 'Órdenes de trabajo',
    icon: LocalOfferIcon,
    href: '/ordenes-de-trabajo',
    userTypesAllowed: [
      UserType.CDTEC, UserType.CONSULTANT, UserType.CLIENT, UserType.MULTI_ACCOUNT_CLIENT],
  },
  {
    title: 'Equipos',
    icon: SensorsIcon,
    href: '/cliente-equipos',
    userTypesAllowed: [
      UserType.CDTEC, UserType.CONSULTANT, UserType.CLIENT, UserType.MULTI_ACCOUNT_CLIENT],
  },
  {
    title: 'Usuarios',
    icon: PersonIcon,
    href: '/cliente-usuarios',
    userTypesAllowed: [
      UserType.CDTEC, UserType.CONSULTANT, UserType.CLIENT, UserType.MULTI_ACCOUNT_CLIENT],
  },
];

export const sensorTypes = ['suelo', 'clima', 'planta', 'riego'];

export const getSectorsBySensorType = (
  sectors: Array<any>,
  devices: Array<DeviceSchema>,
  sensorType: string,
): Array<any> => sectors?.filter((sector) => {
  const sectorDevices = devices.filter((device) => device.sector === sector.id
        && device.deviceType === 'sensor'
        && device.sensorType === sensorType);
  return sectorDevices.length > 0;
}) || [];

// const getUniqueSectorsWithSensors = (
//   sectors: Array<any>,
//   devices: Array<DeviceSchema>,
// ): Array<any> => {
//   const sectorsWithSensors = sectors.filter((sector) => {
//     const sectorDevices = devices.filter((device) => device.sector === sector.id
//       && device.deviceType === 'sensor');
//     return sectorDevices.length > 0;
//   });

//   return [...new Set(sectorsWithSensors)];
// };

const getSectorWithDevices = (sector: any, devices: Array<DeviceSchema>) => {
  const sectorDevices = devices.filter((device) => device.sector === sector.id
    && device.deviceType === 'sensor');

  return {
    suelo: sectorDevices.some((device) => device.sensorType === 'suelo'),
    clima: sectorDevices.some((device) => device.sensorType === 'clima'),
    riego: sectorDevices.some((device) => device.sensorType === 'riego'),
    planta: sectorDevices.some((device) => device.sensorType === 'planta'),
  };
};

// Definimos un mapa de emojis para cada tipo de sensor
const SENSOR_TYPE_EMOJI = {
  suelo: '🌱',
  clima: '🌤️',
  planta: '🌿',
  riego: '💧',
} as const;

export const getUpdatedSpecificNavConfig = (
  sectors: Array<any> = [],
  devices: Array<DeviceSchema> = [],
) => {
  const updatedConfig = [...specificNavConfig];
  const sectoresIndex = updatedConfig.findIndex((item) => item.title === 'Sectores');

  if (sectoresIndex !== -1 && sectors.length > 0) {
    updatedConfig[sectoresIndex].items = sectors.map((sector) => {
      const sensorTypesNames = getSectorWithDevices(sector, devices);
      // Creamos el string de emojis basado en los tipos de sensores
      const sensorEmojis = Object.entries(sensorTypesNames)
        .filter(([, hasType]) => hasType)
        .map(([type]) => SENSOR_TYPE_EMOJI[type as keyof typeof SENSOR_TYPE_EMOJI])
        .join(' ');

      return {
        title: `${sector.name} ${sensorEmojis}`,
        href: `/sectores/${sector.id}?source=inicio`,
        sensorTypes: sensorTypesNames, // Mantenemos el objeto original SensorTypeStatus
        userTypesAllowed: [
          UserType.CDTEC, UserType.CONSULTANT, UserType.CLIENT, UserType.MULTI_ACCOUNT_CLIENT],
      } as NavItem;
    });
  }
  return updatedConfig;
};
