import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useStyles from '../styles';
import GoogleMaps from '../../../../components/Layout/Google/GoogleMaps';
import { ReduxState } from '../../../../types';
import { GetDevicesByFarmIdResponse } from '../../../../requests/api/apiTypes';
import { setAllFarmDevices } from '../../../../actions/farmSectorActions';
import useFetchApi from '../../../../hooks/useFetchApi';
import CustomButton from '../../../../components/General/CustomButton';
import { setCurrentSector } from '../../../../actions/currentSectorActions';

const getSectorsBySensorTypes = (
  selectedType: string,
  sectors: Array<any> = [],
  devices: Array<any> = [],
): Array<any> => {
  // If all types are selected, return all sectors
  if (selectedType === 'todos') {
    return sectors;
  }

  // If no types selected, return empty array
  if (selectedType === '') {
    return [];
  }

  // Return sectors that have at least one sensor of selected types
  return sectors.filter((sector) => {
    const sectorDevices = devices.filter(
      (device) => device.sector === sector.id
        && device.deviceType === 'sensor'
        && device.sensorType === selectedType,
    );
    return sectorDevices.length > 0;
  });
};

const AccountHomeMap = () => {
  const classes = useStyles();
  const { selectedFarm } = useSelector((state: ReduxState) => state.accountFarm);
  const selectedSector = useSelector((state: ReduxState) => state.currentSector.selectedSector);
  const dispatch = useDispatch();
  const [selectedSensorType, setSelectedSensorType] = useState<'suelo' | 'clima' | 'planta' | 'riego' | 'todos'>('todos');

  const history = useHistory();

  const handleSectorClick = (sectorId: string) => {
    console.log('handleSectorClick', sectorId);
    if (selectedSector?.id === sectorId) {
      dispatch(setCurrentSector(null));
    } else {
      // Find the sector object in the selectedFarm
      const selectedSectorObj = selectedFarm?.sectors?.find((sector) => sector.id === sectorId);

      if (selectedSectorObj) {
        console.log('Selected Sector:', selectedSectorObj); // Log the selected sector
        dispatch(setCurrentSector(selectedSectorObj)); // Set current sector in Redux
      }
    }
  };

  const { data } = useFetchApi<GetDevicesByFarmIdResponse>({
    modelEndpoint: 'farmsDetail',
    modelName: 'devices',
    id: selectedFarm?.id || '',
    dependencies: [selectedFarm?.id ?? ''],
  });

  useEffect(() => {
    if (data?.devices) {
      dispatch(setAllFarmDevices(data.devices));
    }
  }, [data?.devices, dispatch]);

  // A lo mejor solo nos querremos mostrar los sectores que tengan un determinado tipo de sensor
  const polygonsAux = getSectorsBySensorTypes(
    selectedSensorType,
    selectedFarm?.sectors,
    data?.devices || [],
  ).map((sector) => ({
    id: sector.id,
    coordinates: sector?.coordinates ?? [],
    isSelected: selectedSector?.id === sector.id,
    onPolygonClick: () => handleSectorClick(sector.id ?? ''),
  }));

  const farmCoordinates = selectedFarm?.coordinates
    ? selectedFarm.coordinates.map(({ lat: farmLat, lng: farmLng }) => ({
      lat: typeof farmLat === 'string' ? parseFloat(farmLat) : farmLat,
      lng: typeof farmLng === 'string' ? parseFloat(farmLng) : farmLng,
    })) : null;

  const selectedSectorInfo = selectedFarm?.sectors?.find(
    (sector) => sector.id === selectedSector?.id,
  );

  const handleClick = () => {
    history.push(`/sectores/${selectedSector?.id}?source=inicio`);
  };

  return (
    <Card className={classes.cardLayoutContainer}>
      <CardContent className={classes.cardLayoutContentContainer}>
        {!selectedFarm ? (
          <>
            <Typography
              variant='h3'
              color="primary"
              gutterBottom
              display="inline"
            >
              Mapa
            </Typography>
            <Divider className={classes.divider} />
            <Typography className={classes.noFarmSelectedText}>
              Elige un campo para ver el mapa
            </Typography>
          </>
        ) : (
          <>
            <Box className={classes.cardHeaderLayoutContentContainer}>
              <Box display='flex' alignItems='center'>
                <Box display='flex' alignItems='center' marginRight='20px'>
                  <Typography
                    variant="h3"
                    color="primary"
                  >
                    Mapa
                  </Typography>
                </Box>
              </Box>
              <Box display='flex' paddingLeft='10px'>
                <Box display='flex' alignItems='center' flexWrap='wrap'>
                  <Chip
                    key='suelo'
                    color={selectedSensorType === 'suelo' ? 'primary' : undefined}
                    label="Suelo"
                    onClick={() => setSelectedSensorType('suelo')}
                    size='small'
                    className={classes.layoutMapChip}
                  />
                  <Chip
                    key='clima'
                    color={selectedSensorType === 'clima' ? 'primary' : undefined}
                    label="Clima"
                    onClick={() => setSelectedSensorType('clima')}
                    size='small'
                    className={classes.layoutMapChip}
                  />
                  <Chip
                    key='planta'
                    color={selectedSensorType === 'planta' ? 'primary' : undefined}
                    label="Planta"
                    onClick={() => setSelectedSensorType('planta')}
                    size='small'
                    className={classes.layoutMapChip}
                  />
                  <Chip
                    key='riego'
                    color={selectedSensorType === 'riego' ? 'primary' : undefined}
                    label="Riego"
                    onClick={() => setSelectedSensorType('riego')}
                    size='small'
                    className={classes.layoutMapChip}
                  />
                  <Chip
                    key='todos'
                    color={selectedSensorType === 'todos' ? 'primary' : undefined}
                    label="Todos"
                    onClick={() => setSelectedSensorType('todos')}
                    size='small'
                    className={classes.layoutMapChip}
                  />
                </Box>
              </Box>
            </Box>
            <Box className={classes.googleMapsContainer} style={{ position: 'relative', padding: '0' }}>              <GoogleMaps
              lat={selectedFarm?.center?.lat}
              lng={selectedFarm?.center?.lng}
              drawable='none'
              farm={{
                id: selectedFarm?.id ?? '',
                coordinates: farmCoordinates,
                zoom: selectedFarm?.zoom ?? 18,
              }}
              polygons={polygonsAux}
              sectorInfo={selectedSectorInfo ? {
                data: {
                  Nombre: `${selectedSectorInfo?.name || '-'}`,
                  Especie: `${selectedSectorInfo?.variety?.species || '-'}`,
                  Variedad: `${selectedSectorInfo?.variety?.name || '-'}`,
                  'Año de plantación': `${selectedSectorInfo?.plantingYear || '-'}`,
                  Hectáreas: `${selectedSectorInfo?.theoreticalSurface || '-'} HA`,
                },
                customButton: (
                  <CustomButton
                    buttonText="Dashboard"
                    onClick={handleClick}
                    color="blue"
                  />
                ),
              } : null}
            />
            </Box>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default AccountHomeMap;
