export const SET_START_DATE = 'SET_START_DATE';
export const SET_END_DATE = 'SET_END_DATE';
export const SET_SELECTED_INTERVAL = 'SET_SELECTED_INTERVAL';

export const setStartDate = (date: string) => {
  sessionStorage.setItem('chartFilterStartDate', date);
  return {
    type: SET_START_DATE,
    payload: date,
  };
};

export const setEndDate = (date: string) => {
  sessionStorage.setItem('chartFilterEndDate', date);
  return {
    type: SET_END_DATE,
    payload: date,
  };
};

export const setSelectedInterval = (interval: string | null) => {
  if (interval) {
    sessionStorage.setItem('chartFilterInterval', interval);
  } else {
    sessionStorage.removeItem('chartFilterInterval');
  }
  return {
    type: SET_SELECTED_INTERVAL,
    payload: interval,
  };
};
