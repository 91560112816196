import { SectorSchema } from '../requests/api/apiTypes';

export const SET_CURRENT_SECTOR = 'SET_CURRENT_SECTOR';

export const setCurrentSector = (sector: SectorSchema | null) => ({
  type: SET_CURRENT_SECTOR,
  payload: {
    ...sector,
  },
});
