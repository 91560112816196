import React, { Fragment, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthGuard from './components/Auth/AuthGuard';
import DashboardLayout from './components/Navigation';
import LoadingScreen from './components/Loading/LoadingScreen';

import Login from './views/Login';
import Verification from './views/Verification';
import PasswordRecovery from './views/PasswordRecovery';
import PostRegister from './views/Register/PostRegister';

import UserListView from './views/Admin/Users/UserListView';
import { ChildrenProps, RouteConfig } from './types';
import UpsertView from './views/Admin/Users/UpsertView';
import {
  UserType,
} from './constants/user_types';
import Unauthorized from './views/Unauthorized';
import CompanyListView from './views/Company';
import AccountListView from './views/Account';
import VarietiesListView from './views/Varieties';
import WorkOrderListView from './views/ClientDetail/WorkOrder';
import DevicesListView from './views/Devices';
import LayoutListView from './views/ClientDetail/Layout';
import ClientAuthGuard from './components/Auth/ClientAuthGuard';
import DevicesView from './views/ClientDetail/Layout/DevicesDetail';

import SectorDetail from './views/ClientDetail/Layout/SectorDetail';
import AccountDevicesListView from './views/ClientDetail/Devices';
import ClientUserListView from './views/ClientDetail/Users';
import AccountHome from './views/ClientDetail/AccountHome';

import StackedSoilMoistureConfig from './components/Highcharts/StackedSoilMoistureChart/StackedSoilMoistureConfig';
import SoilMoistureConfig from './components/Highcharts/SoilMoistureChart/SoilMoistureConfig';
import RainfallConfig from './components/Highcharts/RainfallChart/RainfallConfig';
import KcConfig from './components/Highcharts/KcChart/KcConfig';
import RootsConfig from './components/Highcharts/RootsChart/RootsConfig';
import IrrigationConfig from './components/Highcharts/IrrigationChart/IrrigationConfig';
import Et0Config from './components/Highcharts/Et0Chart/Et0Config';

const routesConfig = [
  {
    id: 1,
    exact: true,
    path: '/',
    component: () => <Redirect to="/login" />,
  },
  {
    id: 2,
    exact: true,
    path: '/login',
    component: () => <Login />,
  },
  {
    id: 4,
    exact: true,
    path: '/user-verification/:token',
    component: () => <Verification />,
  },
  {
    id: 5,
    exact: true,
    path: '/reset-password/:token',
    component: () => <PasswordRecovery />,
  },
  {
    id: 6,
    exact: true,
    path: '/reset-password?token=:token',
    component: () => <Redirect to="/reset-password/:token" />,
  },
  {
    id: 7,
    exact: true,
    path: '/postRegister',
    component: () => <PostRegister />,
  },
  {
    id: 8,
    exact: true,
    path: '/sin-autorizacion',
    guard: (props: ChildrenProps) => <AuthGuard {...props} />,
    component: () => <Unauthorized />,
  },
  {
    id: 10,
    path: '/usuarios',
    guard: (props: ChildrenProps) => <AuthGuard {...props} admittedUserTypes={[UserType.CDTEC]} />,
    layout: DashboardLayout,
    routes: [
      {
        id: 11,
        exact: true,
        path: '/usuarios',
        component: () => <UserListView />,
      },
      {
        id: 12,
        exact: true,
        path: '/usuarios/crear',
        component: () => <UpsertView />,
      },
      {
        id: 13,
        exact: true,
        path: '/usuarios/:id/editar',
        component: () => <UpsertView />,
      },
    ],
  },
  {
    id: 20,
    path: '/cuentas',
    guard: (props: ChildrenProps) => <AuthGuard {...props} admittedUserTypes={[
      UserType.CDTEC, UserType.CLIENT, UserType.MULTI_ACCOUNT_CLIENT, UserType.CONSULTANT,
    ]} />,
    layout: DashboardLayout,
    routes: [
      {
        id: 21,
        exact: true,
        path: '/cuentas',
        component: () => <AccountListView />,
      },
    ],
  },
  {
    id: 30,
    path: '/empresas',
    guard: (props: ChildrenProps) => <AuthGuard {...props} admittedUserTypes={[UserType.CDTEC]} />,
    layout: DashboardLayout,
    routes: [
      {
        id: 31,
        exact: true,
        path: '/empresas',
        component: () => <CompanyListView />,
      },
    ],
  },
  {
    id: 40,
    path: '/equipos',
    guard: (props: ChildrenProps) => <AuthGuard {...props} admittedUserTypes={[UserType.CDTEC]} />,
    layout: DashboardLayout,
    routes: [
      {
        id: 41,
        exact: true,
        path: '/equipos',
        component: () => <DevicesListView />,
      },
    ],
  },
  {
    id: 50,
    path: '/especies-variedades',
    guard: (props: ChildrenProps) => <AuthGuard {...props} admittedUserTypes={[UserType.CDTEC]} />,
    layout: DashboardLayout,
    routes: [
      {
        id: 51,
        exact: true,
        path: '/especies-variedades',
        component: () => <VarietiesListView />,
      },
    ],
  },
  {
    id: 60,
    path: '/inicio',
    layout: DashboardLayout,
    routes: [
      {
        id: 61,
        exact: true,
        path: '/inicio',
        component: () => <AccountHome />,
      },
    ],
  },
  {
    id: 70,
    path: '/estado-sensores',
    layout: DashboardLayout,
    routes: [
      {
        id: 71,
        exact: true,
        path: '/estado-sensores',
        component: () => <h1 style={{ padding: '24px' }}>Under development</h1>,
      },
    ],
  },
  {
    id: 80,
    path: '/layout',
    guard: (props: ChildrenProps) => <
      ClientAuthGuard {...props} admittedUserTypes={[
        UserType.CDTEC, UserType.CLIENT, UserType.MULTI_ACCOUNT_CLIENT, UserType.CONSULTANT,
      ]} />,
    layout: DashboardLayout,
    routes: [
      {
        id: 81,
        exact: true,
        path: '/layout',
        component: () => <LayoutListView />,
      },
      {
        id: 83,
        exact: true,
        path: '/layout/equipos/:id',
        component: () => <DevicesView />,
      },
    ],
  },
  {
    id: 90,
    path: '/riego',
    layout: DashboardLayout,
    routes: [
      {
        id: 91,
        exact: true,
        path: '/riego',
        component: () => <h1 style={{ padding: '24px' }}>Under development</h1>,
      },
    ],
  },
  {
    id: 100,
    path: '/conversaciones',
    layout: DashboardLayout,
    routes: [
      {
        id: 101,
        exact: true,
        path: '/conversaciones',
        component: () => <h1 style={{ padding: '24px' }}>Under development</h1>,
      },
    ],
  },
  {
    id: 110,
    path: '/alertas',
    layout: DashboardLayout,
    routes: [
      {
        id: 111,
        exact: true,
        path: '/alertas',
        component: () => <h1 style={{ padding: '24px' }}>Under development</h1>,
      },
    ],
  },
  {
    id: 120,
    path: '/ordenes-de-trabajo',
    layout: DashboardLayout,
    routes: [
      {
        id: 121,
        exact: true,
        path: '/ordenes-de-trabajo',
        component: () => <WorkOrderListView />,
      },
    ],
  },
  {
    id: 130,
    path: '/cliente-equipos',
    layout: DashboardLayout,
    routes: [
      {
        id: 131,
        exact: true,
        path: '/cliente-equipos',
        component: () => <AccountDevicesListView />,
      },
    ],
  },
  {
    id: 140,
    path: '/cliente-usuarios',
    layout: DashboardLayout,
    routes: [
      {
        id: 141,
        exact: true,
        path: '/cliente-usuarios',
        component: () => <ClientUserListView />,
      },
    ],
  },
  {
    id: 150,
    path: '/graficos',
    layout: DashboardLayout,
    guard: (props: ChildrenProps) => <ClientAuthGuard {...props} admittedUserTypes={[
      UserType.CDTEC, UserType.CLIENT, UserType.MULTI_ACCOUNT_CLIENT, UserType.CONSULTANT]} />,
    routes: [
      {
        id: 151,
        exact: true,
        path: '/graficos/humedad-suelo/',
        component: () => <SoilMoistureConfig />,
      },
      {
        id: 152,
        exact: true,
        path: '/graficos/humedad-suelo-apilada/',
        component: () => <StackedSoilMoistureConfig />,
      },
      {
        id: 153,
        exact: true,
        path: '/graficos/cambio-total-negativo/',
        component: () => <h1>In progress</h1>,
      },
      {
        id: 154,
        exact: true,
        path: '/graficos/salinidad-suelo-apilado/',
        component: () => <h1>In progress</h1>,
      },
      {
        id: 155,
        exact: true,
        path: '/graficos/temperatura-suelo-apilada/',
        component: () => <h1>In progress</h1>,
      },
      {
        id: 156,
        exact: true,
        path: '/graficos/riego/',
        component: () => <IrrigationConfig />,
      },
      {
        id: 157,
        exact: true,
        path: '/graficos/temperatura-humedad-relativa/',
        component: () => <h1>In progress</h1>,
      },
      {
        id: 158,
        exact: true,
        path: '/graficos/horas-frio/',
        component: () => <h1>In progress</h1>,
      },
      {
        id: 159,
        exact: true,
        path: '/graficos/grados-dia/',
        component: () => <h1>In progress</h1>,
      },
      {
        id: 160,
        exact: true,
        path: '/graficos/precipitaciones/',
        component: () => <RainfallConfig />,
      },
      {
        id: 161,
        exact: true,
        path: '/graficos/et0/',
        component: () => <Et0Config />,
      },
      {
        id: 162,
        exact: true,
        path: '/graficos/viento/',
        component: () => <h1>In progress</h1>,
      },
      {
        id: 163,
        exact: true,
        path: '/graficos/radiacion-solar/',
        component: () => <h1>In progress</h1>,
      },
      {
        id: 164,
        exact: true,
        path: '/graficos/planta/',
        component: () => <h1>In progress</h1>,
      },
      {
        id: 165,
        exact: true,
        path: '/graficos/kc/',
        component: () => <KcConfig />,
      },
      {
        id: 166,
        exact: true,
        path: '/graficos/profundidad-riego/',
        component: () => <RootsConfig />,
      },
    ],
  },
  {
    id: 170,
    path: '/sectores/:id',
    guard: (props: ChildrenProps) => <
      ClientAuthGuard {...props} admittedUserTypes={[
        UserType.CDTEC, UserType.CLIENT, UserType.MULTI_ACCOUNT_CLIENT, UserType.CONSULTANT]} />,
    layout: DashboardLayout,
    routes: [
      {
        id: 171,
        exact: true,
        path: '/sectores/:id',
        component: () => <SectorDetail />,
      },
    ],
  },
];

const renderRoutes = (routes: RouteConfig[]) => (routes ? (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route: RouteConfig, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component as React.ElementType;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
) : null);

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
