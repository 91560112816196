/* eslint-disable @typescript-eslint/default-param-last */
/* eslint no-param-reassign: ["error", { "props": false }] */
import produce, { Draft } from 'immer';
import { NotificationSchema } from '../requests/api/apiTypes';
import {
  SET_NOTIFICATIONS,
  ADD_NOTIFICATION,
  DELETE_NOTIFICATION,
  UPDATE_NOTIFICATION,
} from '../actions/notificationActions';

interface NotificationState {
  notifications: NotificationSchema[];
}

interface NotificationAction {
  type: string;
  payload: NotificationSchema[] | NotificationSchema | string;
}

const initialState: NotificationState = {
  notifications: [],
};

const notificationReducer = (
  state = initialState,
  action: NotificationAction,
): NotificationState => {
  switch (action.type) {
    case SET_NOTIFICATIONS: {
      return produce(state, (draft: Draft<NotificationState>) => {
        draft.notifications = [...action.payload as NotificationSchema[]];
      });
    }

    case ADD_NOTIFICATION: {
      return produce(state, (draft: Draft<NotificationState>) => {
        draft.notifications.unshift(action.payload as NotificationSchema);
      });
    }

    case DELETE_NOTIFICATION: {
      return produce(state, (draft: Draft<NotificationState>) => {
        draft.notifications = draft.notifications.filter(
          (notification) => notification.id !== action.payload,
        );
      });
    }

    case UPDATE_NOTIFICATION: {
      return produce(state, (draft: Draft<NotificationState>) => {
        const updatedNotification = action.payload as NotificationSchema;
        const index = draft.notifications.findIndex(
          (notification) => notification.id === updatedNotification.id,
        );
        if (index !== -1) {
          draft.notifications[index] = updatedNotification;
        }
      });
    }

    default: {
      return state;
    }
  }
};

export default notificationReducer;
