import dayjs from 'dayjs';
import {
  getET0,
  getReadings,
  getLastIrrigationFromDate,
  calculateKc,
} from '../dataServices';
import {
  buildCategories,
  mapDates,
  getWeeklyAverageData,
  getGranularity,
} from '../utils';

const prepareData = async (filters: {
  farmId: string;
  startDate: string;
  endDate: string;
  sectorId?: string;
  wiseconnZoneId?: string;
}) => {
  const granularity = getGranularity(filters.startDate, filters.endDate);

  const [irrigationData, lastIrrigationInfo] = await Promise.all([
    getReadings({
      ...filters,
      name: 'Irrigation Precipitation',
      granularity: 'daily',
    }),
    getLastIrrigationFromDate(filters),
  ]);

  const normalizedIrrigationData = irrigationData.map((item: any) => item.lastValue);

  const { lastNonZeroIrrigationDate } = lastIrrigationInfo;
  // Fetch ET0 data, important to have lastNonZeroIrrigationDate to use last irrigation
  const et0Data = await getET0({
    farmId: filters.farmId,
    startDate: lastNonZeroIrrigationDate,
    endDate: filters.endDate,
    granularity: 'daily', // For Kc calculation we use daily ET0
    // wiseconnZoneId: filters.wiseconnZoneId, TO DO: Acá va a depender de la estacion de clima
  });
  const normalizedEt0Data = et0Data.map((item: any) => item.et0);

  const { kcData } = await calculateKc({
    normalizedIrrigationData,
    lastNonZeroIrrigationDate,
    et0Data,
    filters,
  });
  let kcDataMap = mapDates(kcData, 'daily', 'kc');
  if (granularity === 'weekly') {
    kcDataMap = getWeeklyAverageData(kcDataMap as Map<string, number>);
  }
  const normalizedKcData2 = Array.from(kcDataMap.values());

  const absoluteCategories = Array.from(kcDataMap.keys()).map((date: any) => dayjs(date).format('YYYY-MM-DD HH:mm'));

  const chartCategories = buildCategories(
    granularity === 'hourly' ? 'daily' : granularity,
    absoluteCategories,
  );
  const result = {
    et0: normalizedEt0Data,
    kc: normalizedKcData2,
    categories: chartCategories,
    absoluteCategories,
  };
  return result;
};

export default prepareData;
