import { useLocation } from 'react-router-dom';
import React, { useMemo, useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import {
  Box, Tab, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography,
} from '@mui/material';
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import CustomHeader from '../../General/CustomHeader';
import Page from '../../Layout/Page';
import useStyles from '../styles';
import IrrigationChart from './IrrigationChart';
import { ReduxState } from '../../../types';
import ConversationsTab from '../ConversationTab';
import apiClient from '../../../requests/api/apiClient';
import { ConversationSchema, TimePointSchema } from '../../../requests/api/apiTypes';
import { FetchError } from '../../../views/types';
import { IRRIGATION_NAME } from '../../../constants/graphs';

const Irrigation = () => {
  const classes = useStyles();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  // Check if we're coming from a notification and set default tab to conversations
  const source = queryParams.get('source');
  const initialTabValue = source === 'notification' ? '3' : '1';

  const [tabValue, setTabValue] = useState(initialTabValue);
  const { selectedFarm } = useSelector((state: ReduxState) => state.accountFarm);
  const { selectedSector } = useSelector((state: ReduxState) => state.currentSector);
  const { enqueueSnackbar } = useSnackbar();
  const [conversations, setConversations] = useState<ConversationSchema[]>([]);
  const [conversationsLoading, setConversationsLoading] = useState(false);
  // Modal state for conversation interactions
  const [modalState, setModalState] = useState<{
    open: boolean;
    conversationId: string;
    timePointData: TimePointSchema;
  }>({
    open: false,
    conversationId: '',
    timePointData: {
      hour: '',
      day: '',
      month: '',
      year: '',
    },
  });

  const handleCloseModal = () => {
    setModalState({
      ...modalState,
      open: false,
    });
  };

  const params = {
    farmId: queryParams.get('farmId') || '',
    sectorId: queryParams.get('sectorId') || '',
    wiseconnZoneId: queryParams.get('wiseconnZoneId') || '',
  };

  useEffect(() => {
    const loadConversations = async () => {
      if (!params.sectorId) return;
      setConversationsLoading(true);
      try {
        const { data } = await apiClient.conversation.conversationDetail(
          params.sectorId,
          IRRIGATION_NAME,
        );
        setConversations(data.conversations);
      } catch (err) {
        const { error } = err as FetchError;
        enqueueSnackbar(error.message, { variant: 'error' });
      } finally {
        setConversationsLoading(false);
      }
    };

    loadConversations();
  }, [params.sectorId, enqueueSnackbar, selectedSector]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const breadcrumb = useMemo(() => {
    const sectorName = selectedFarm?.sectors?.find(
      (sector) => sector.id === selectedSector?.id,
    )?.name || selectedSector?.name;

    return `${selectedFarm?.name}/${sectorName}/Configuración`;
  }, [selectedFarm, selectedSector]);

  return (
    <Page
      className={classes.root}
      title={`Riego - ${selectedFarm?.name} - Sector ${selectedSector?.name}`}
    >
      <Container maxWidth={false}>
        <CustomHeader title={breadcrumb} />
        <Box
          id="irrigation-chart-container"
          width={1}
          mb={3}
        >
          <IrrigationChart
            chartView="settings"
            farmId={params.farmId}
            sectorId={params.sectorId}
            wiseconnZoneId={params.wiseconnZoneId}
            height={250}
          />
        </Box>

        <TabContext value={tabValue}>
          <Box sx={{
            borderBottom: 0,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
            <TabList onChange={handleTabChange} aria-label="sector tabs" className={classes.tabList}>
              <Tab label="Configuración" value="1" className={classes.tabLabel}/>
              <Tab label="Alertas" value="2" className={classes.tabLabel}/>
              <Tab label="Conversaciones" value="3" className={classes.tabLabel}/>
            </TabList>
          </Box>
          <TabPanel value="1" className={classes.configTabPanel}>
            In progress
          </TabPanel>
          <TabPanel value="2" className={classes.configTabPanel}>
            In progress
          </TabPanel>
          <TabPanel value="3" className={classes.configTabPanel}>
            <ConversationsTab
              setModalState={setModalState}
              conversations={conversations}
              conversationsLoading={conversationsLoading}
            />
          </TabPanel>
        </TabContext>
      </Container>

      {/* Dialog for conversation details */}
      <Dialog open={modalState.open} onClose={handleCloseModal} maxWidth="md" fullWidth>
        <DialogTitle>Detalle de Conversación</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            {`Fecha: ${modalState.timePointData.day}/${modalState.timePointData.month}/${modalState.timePointData.year} ${modalState.timePointData.hour}`}
          </Typography>
          <Typography variant="body1">ID: {modalState.conversationId}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};
export default Irrigation;
