import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Box, CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import BaseHighchart from '../BaseHighchart';
import prepareData from './builder';
import { calculateDateRangeFromNotification } from '../utils';
import {
  ChartDataType,
  ScatterPoint,
} from '../types';

import { ReduxState } from '../../../types';
import ChartFilter from '../ChartFilter';
import { navigateToChart } from '../../../utils/helpers';
import apiClient from '../../../requests/api/apiClient';
import { ConversationSchema, TimePointSchema } from '../../../requests/api/apiTypes';
import { ROOTS_NAME } from '../../../constants/graphs';
import { FetchError } from '../../../views/types';
import ConversationModal from '../ConversationTab/conversationModal';
import { saveChartData, getChartData } from '../../../services/indexDBService';
import { setStartDate, setEndDate } from '../../../actions/graphsFilterActions';

function RootsChart({
  chartView,
  sectorId,
  farmId,
  wiseconnZoneId,
  hideLegend = false,
  hideXValues = false,
  hideYValues = false,
  height = 450,
  title = 'Gráfico de Profundidad de Riego',
  yAxisTitle = 'Profundidad (cm)',
  thresholds = undefined,
  externalModalState,
  externalSetModalState,
  externalConversations,
  externalSetConversations,
}: {
  chartView: 'dashboard' | 'graphs' | 'settings'
  sectorId: string
  farmId: string
  wiseconnZoneId: string | undefined
  height?: number | string
  hideLegend?: boolean
  hideXValues?: boolean
  hideYValues?: boolean
  yAxisTitle?: string
  title?: string
  thresholds?: {
    irrigationThreshold: number;
    rootsThreshold: number;
  }
  externalModalState?: {
    open: boolean;
    conversationId: string;
    timePointData: TimePointSchema;
  };
  externalSetModalState?: React.Dispatch<React.SetStateAction<{
    open: boolean;
    conversationId: string;
    timePointData: TimePointSchema;
  }>>;
  externalConversations?: ConversationSchema[];
  externalSetConversations?: React.Dispatch<React.SetStateAction<ConversationSchema[]>>;
}) {
  const [chartData, setChartData] = useState<ChartDataType>({
    series: [],
    categories: [],
    absoluteCategories: [],
  });
  const [irrigationTimes, setIrrigationTimes] = useState<string[]>([]);
  const [internalThresholds, setInternalThresholds] = useState<{
    irrigationThreshold: number | undefined;
    rootsThreshold: number | undefined;
  }>({
    irrigationThreshold: undefined,
    rootsThreshold: undefined,
  });
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const [maxDetectionValue, setMaxDetectionValue] = useState<number>(0);
  const [internalModalState, setInternalModalState] = useState<{
    open: boolean;
    conversationId: string;
    timePointData: TimePointSchema;
  }>({
    open: false,
    conversationId: '',
    timePointData: {
      hour: '',
      day: '',
      month: '',
      year: '',
    },
  });
  const [internalConversations, setInternalConversations] = useState<ConversationSchema[]>([]);

  const { startDate, endDate } = useSelector((state: ReduxState) => state.graphsFilter);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dateParam = searchParams.get('date');

  const modalState = externalModalState || internalModalState;
  const setModalState = externalSetModalState || setInternalModalState;
  const conversations = externalConversations || internalConversations;
  const setConversations = externalSetConversations || setInternalConversations;

  const preparedFilters = useMemo(() => ({
    farmId,
    startDate,
    endDate,
    wiseconnZoneId,
  }), [farmId, startDate, endDate, wiseconnZoneId]);

  useEffect(() => {
    const loadInitialConfig = async () => {
      if (
        thresholds?.irrigationThreshold !== undefined
        && thresholds?.rootsThreshold !== undefined
      ) {
        setInternalThresholds(thresholds);
      } else if (sectorId) {
        try {
          const config = await apiClient.rootsConfig.rootsConfigDetail(sectorId);
          setInternalThresholds({
            irrigationThreshold: config.data.rootsConfig?.irrigationThreshold || 0.4,
            rootsThreshold: config.data.rootsConfig?.rootsThreshold || 0.25,
          });
        } catch (error) {
          setInternalThresholds({
            irrigationThreshold: 0.4,
            rootsThreshold: 0.25,
          });
        }
      }
    };

    loadInitialConfig();
  }, [sectorId, thresholds]);

  useEffect(() => {
    if (dateParam) {
      // Convert date from DD-MM-YYYY to YYYY-MM-DD
      const { rangeStartDate, rangeEndDate } = calculateDateRangeFromNotification(dateParam);
      // Update the date range in the store
      dispatch(setStartDate(rangeStartDate));
      dispatch(setEndDate(rangeEndDate));
    }
  }, [dateParam, dispatch]);

  const createSeriesData = useCallback((preparedData: any) => [
    {
      name: 'Profundidad de riego',
      type: 'bar',
      data: preparedData.irrigationSeries,
      threshold: 0,
      marker: {
        enabled: false,
      },
      color: '#2374BF',
    },
    {
      name: 'Actividad de raíces',
      type: 'bar',
      data: preparedData.rootsSeries,
      threshold: 0,
      marker: {
        enabled: false,
      },
      color: '#D2B48C', // Light brown color
    },
  ], []);

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    try {
      const cachedData = await getChartData('rootsChart', startDate, endDate, sectorId);

      if (cachedData !== null && cachedData !== undefined) {
        const seriesData = createSeriesData(cachedData.data);
        setChartData({
          series: seriesData,
          categories: cachedData.data.categories,
          absoluteCategories: cachedData.data.absoluteCategories,
        });
        setIrrigationTimes(cachedData.data.irrigationDetection.map(
          (item: any) => item.irrigationTime,
        )
          .filter((time: string | undefined) => time !== undefined));
        setMaxDetectionValue(Math.max(
          Math.max(...cachedData.data.rootsSeries),
          Math.max(...cachedData.data.irrigationSeries),
        ) + 10);
        setIsLoading(false);
        return;
      }

      const preparedData = await prepareData({
        ...preparedFilters,
        irrigationThreshold: internalThresholds.irrigationThreshold,
        rootsThreshold: internalThresholds.rootsThreshold,
      });

      // Calculate max detection value
      const maxDetection = Math.max(
        Math.max(...preparedData.rootsSeries),
        Math.max(...preparedData.irrigationSeries),
      );
      setMaxDetectionValue(maxDetection + 10);

      // Extract irrigation times from irrigationDetection and filter out undefined values
      const times = preparedData.irrigationDetection
        .map((item) => item.irrigationTime)
        .filter((time): time is string => time !== undefined);
      setIrrigationTimes(times);

      const seriesData = createSeriesData(preparedData);

      // Save to IndexDB
      await saveChartData('rootsChart', startDate, endDate, sectorId, preparedData);

      setChartData({
        series: seriesData,
        categories: preparedData.categories as string[],
        absoluteCategories: preparedData.absoluteCategories,
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  }, [preparedFilters, internalThresholds, createSeriesData, startDate, endDate, sectorId]);

  useEffect(() => {
    if (
      internalThresholds.irrigationThreshold !== undefined
      && internalThresholds.rootsThreshold !== undefined
    ) {
      fetchData();
    }
  }, [internalThresholds, fetchData]);

  const customTooltip = useMemo(() => ({
    shared: true,
    useHTML: true,
    formatter(
      this: Highcharts.TooltipFormatterContextObject,
    ) {
      if (this.series.name === 'Comentarios') {
        const point = this.point as ScatterPoint;
        const conversationId = point.custom?.conversationId;
        if (!conversations || !conversationId) {
          return '<b>Último comentario:</b> Cargando...<br/>';
        }
        const conversation = conversations.find((conv) => conv.conversationId === conversationId);
        if (!conversation) {
          return '<b>Último comentario:</b> No se encontró la conversación<br/>';
        }
        const lastComment = conversation.lastComment || 'Sin comentarios';
        return `<b>Último comentario:</b> ${lastComment}<br/>
                <span style="color: gray; font-size: 10px">${dayjs(conversation.updatedAt).format('MMM DD, YYYY HH:mm')}</span>`;
      }
      const pointIndex = this.points?.[0].point.index;
      const category = chartData.categories[pointIndex as number];
      const header = `<div style="font-size: 12px; margin-bottom: 8px;">${category}</br></div>`;
      const points = this.points?.map(
        (point) => {
          const displayValue = Math.round(Number(point.y));
          const irrigationTime = irrigationTimes[point.point.index] || 'N/A';
          const showIrrigationTime = point.series.name.toLowerCase() === 'profundidad de riego';
          return `
            <div style="margin-bottom: 8px;">
              <span style="color:${point.color}">\u25CF</span> 
              <span>${point.series.name}: <b>${displayValue} cm</b></span>
              ${showIrrigationTime && irrigationTime !== 'N/A' ? `<span style="font-size: 10px;"><br/>Hora de riego: ${irrigationTime}</span>` : ''}
            </div>`;
        },
      ).join('');
      return `
        <div style="
          padding: 10px;
          background: rgba(255, 255, 255, 1);
          border-radius: 5px;">
          ${header}${points}
        </div>
      `;
    },
  }), [chartData.categories, irrigationTimes, conversations]);

  const chartConfig = useMemo(() => ({
    chart: {
      height,
    },
    yAxis: {
      reversed: true,
      min: 0,
      max: maxDetectionValue,
      gridLineWidth: 1,
      title: {
        text: '',
      },
    },
    xAxis: {
      categories: hideXValues ? [] : chartData.categories,
      plotBands: chartData.categories.map((_, index) => ({
        color: index % 2 === 0 ? 'rgba(0, 0, 0, 0.02)' : 'rgba(173, 216, 230, 0.3)',
        from: index - 0.5,
        to: index + 0.5,
      })),
      labels: {
        enabled: !hideXValues,
      },
    },
  }), [height, maxDetectionValue, hideXValues, chartData.categories]);

  useEffect(() => {
    if (!externalConversations) {
      const loadConversations = async () => {
        try {
          const { data } = await apiClient.conversation.conversationDetail(sectorId, ROOTS_NAME);
          setInternalConversations(data.conversations);
        } catch (err) {
          const { error } = err as FetchError;
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      };

      loadConversations();
    }
  }, [sectorId, externalConversations]);

  const handleConfigClick = () => {
    navigateToChart('profundidad-riego', history, {
      farmId,
      sectorId,
      wiseconnZoneId: wiseconnZoneId || '',
    });
  };

  return (
    <>
      <Box>
        {isLoading && (
          <Box display="flex" justifyContent="center" my={2}>
            <CircularProgress />
          </Box>
        )}
        <div style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
          <BaseHighchart
            chartView={chartView}
            filter={chartView === 'settings' ? <ChartFilter sectorId={sectorId} /> : undefined}
            title={title}
            categories={chartData.categories}
            yAxisTitle={yAxisTitle}
            series={chartData.series as Highcharts.SeriesOptionsType[]}
            tooltip={customTooltip}
            showFullScreen={true}
            legend={{ enabled: !hideLegend }}
            chartConfig={chartConfig}
            hideXValues={hideXValues}
            onConfigClick={handleConfigClick}
            hideYValues={hideYValues}
            setModalState={setModalState}
            conversations={conversations}
            absoluteCategories={chartData.absoluteCategories}
            height={height}
            />
        </div>
      </Box>
      <ConversationModal
        modalState={modalState}
        setModalState={setModalState}
        farmId={farmId}
        sectorId={sectorId}
        chart={ROOTS_NAME}
        setConversationsFunction={setConversations}
      />
    </>
  );
}

export default RootsChart;
