import {
  getET0,
  getReadings,
  getLastIrrigationFromDate,
  calculateKc,
} from '../dataServices';
import {
  buildCategories,
  getGranularity,
  mapDates,
  getWeeklyAverageData,
} from '../utils';
import { formatISOToSimpleDateTime } from '../../../utils/formatDate';

const completeCategoriesWhenNoData = (
  absoluteCategories: string[],
  endDate: string,
  granularity: 'hourly' | 'daily' | 'weekly',
): string[] => {
  if (!absoluteCategories.length) return absoluteCategories;

  const lastCategoryDate = absoluteCategories[absoluteCategories.length - 1];
  const endDateObj = new Date(endDate);
  const lastDateObj = new Date(lastCategoryDate);

  // Reset time components for date-only comparison when using daily/weekly granularity
  if (granularity !== 'hourly') {
    endDateObj.setHours(0, 0, 0, 0);
    lastDateObj.setHours(0, 0, 0, 0);
  }

  const diffInDays = Math.floor(
    (endDateObj.getTime() - lastDateObj.getTime()) / (1000 * 60 * 60 * 24),
  );

  if (diffInDays <= 0) return absoluteCategories;

  const emptyCategories = new Array(diffInDays).fill('');
  return [...absoluteCategories, ...emptyCategories];
};

const prepareData = async (filters: {
  farmId: string;
  startDate: string;
  endDate: string;
  sectorId?: string;
  wiseconnZoneId?: string;
}) => {
  const granularity = getGranularity(filters.startDate, filters.endDate);

  // Execute API calls concurrently
  const [irrigationData, rainData, lastIrrigationInfo, et0Data] = await Promise.all([
    getReadings({
      ...filters,
      name: 'Irrigation Precipitation',
      granularity: 'daily',
    }),
    getReadings({
      farmId: filters.farmId,
      startDate: filters.startDate,
      endDate: filters.endDate,
      sensorType: 'Rain',
      granularity: granularity === 'hourly' ? 'daily' : granularity,
    }),
    getLastIrrigationFromDate(filters),
    getET0({
      farmId: filters.farmId,
      startDate: filters.startDate,
      endDate: filters.endDate,
      granularity: 'daily',
    }),
  ]);

  const { lastNonZeroIrrigationDate } = lastIrrigationInfo;

  // Fetch ET0 data, important to have lastNonZeroIrrigationDate to use last irrigation
  const et0DataForKc = await getET0({
    farmId: filters.farmId,
    startDate: lastNonZeroIrrigationDate,
    endDate: filters.endDate,
    granularity: 'daily',
  });

  const normalizedIrrigationDataForKc = irrigationData.map((item: any) => item.maxValue);

  const { kcData, accumulatedEt0ForKc } = await calculateKc({
    normalizedIrrigationData: normalizedIrrigationDataForKc,
    lastNonZeroIrrigationDate,
    et0Data: et0DataForKc,
    filters,
  });

  let kcDataMap = mapDates(kcData, 'daily', 'kc');
  let et0DataMap = mapDates(et0Data, granularity, 'et0');
  let irrigationDataMap = mapDates(irrigationData, granularity === 'hourly' ? 'daily' : granularity, 'maxValue', 'dateTime');

  if (granularity === 'weekly') {
    kcDataMap = getWeeklyAverageData(kcDataMap as Map<string, number>);
    et0DataMap = getWeeklyAverageData(et0DataMap as Map<string, number>);
    irrigationDataMap = getWeeklyAverageData(irrigationDataMap as Map<string, number>);
  }

  const rainDataMap = mapDates(
    rainData, granularity, 'cumulativeTotal', 'dateTime',
  );

  const normalizedKcData = Array.from(kcDataMap.values());
  const normalizedEt0Data = Array.from(et0DataMap.values());
  const normalizedRainData = Array.from(rainDataMap.values());
  const normalizedIrrigationData = Array.from(irrigationDataMap.values());
  const cumulativeEt0 = accumulatedEt0ForKc.map((item: any) => item.et0);

  let absoluteCategories = Array.from(et0DataMap.keys()).map(
    (date: any) => formatISOToSimpleDateTime(date),
  );

  // Complete categories with empty strings if needed
  absoluteCategories = completeCategoriesWhenNoData(
    absoluteCategories,
    filters.endDate,
    granularity,
  );

  const chartCategories = buildCategories(
    granularity,
    absoluteCategories,
  );

  const result = {
    et0: normalizedEt0Data,
    cumulativeEt0,
    rain: normalizedRainData,
    irrigation: normalizedIrrigationData,
    kc: normalizedKcData,
    categories: chartCategories,
    absoluteCategories,
    granularity,
  };

  return result;
};

export default prepareData;
