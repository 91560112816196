import dayjs from 'dayjs';
import { getReadings } from '../dataServices';
import {
  buildCategoriesFromDates,
} from '../utils';

const detectDepths = (
  series: Record<string, any[]>, // Object with dates as keys and arrays as values
  irrigationThreshold: number, // Threshold percentage to determine irrigation changes
  rootsThreshold: number, // Threshold percentage to determine roots changes
): {
  irrigation: Array<{
    date: string;
    depth: string;
    value: number;
    irrigationTime: string;
  }>;
  roots: Array<{
    date: string;
    depth: string;
    value: number;
  }>;
} => {
  // Get sorted dates to ensure chronological order
  const dates = Object.keys(series).sort();

  const irrigationResults: Array<{
    date: string;
    depth: string;
    value: number;
    irrigationTime: string;
  }> = [];

  const rootsResults: Array<{
    date: string;
    depth: string;
    value: number;
  }> = [];

  let lastIrrigationTime: dayjs.Dayjs | null = null;
  let skipRootsCheck = false;

  // Process each date's readings
  dates.forEach((date) => {
    const readings = series[date];
    // Compare consecutive readings within the same date
    for (let i = 1; i < readings.length; i += 1) {
      const currentReading = readings[i];
      const previousReading = readings[i - 1];

      if (previousReading && currentReading) {
        const percentageChange = ((currentReading.maxValue - previousReading.maxValue)
          / previousReading.maxValue) * 100;

        const currentTime = dayjs(`${date} ${currentReading.startInterval}`);

        // Check for irrigation (increase)
        if (percentageChange > irrigationThreshold) {
          irrigationResults.push({
            date,
            depth: currentReading.sensorDepth,
            value: currentReading.maxValue,
            irrigationTime: currentReading.startInterval,
          });
          lastIrrigationTime = currentTime;
          skipRootsCheck = true;
        }

        // Check for roots decrease except during 3 hours after irrigation
        if (percentageChange < -rootsThreshold && !skipRootsCheck) {
          rootsResults.push({
            date,
            depth: currentReading.sensorDepth,
            value: currentReading.maxValue,
          });
        }

        // Update skipRootsCheck based on time since last irrigation
        if (lastIrrigationTime && skipRootsCheck) {
          const hoursSinceIrrigation = currentTime.diff(lastIrrigationTime, 'hour');
          if (hoursSinceIrrigation > 3) {
            skipRootsCheck = false;
          }
        }
      }
    }
  });

  return {
    irrigation: irrigationResults,
    roots: rootsResults,
  };
};

const groupDepthByDate = (
  series: Array<{
    depth: string;
    [key: string]: any; // Allow for different detection types
  }>,
  detectionType: 'irrigationDetection' | 'rootsDetection',
): Array<{ date: string; maxDepth: number; irrigationTime?: string }> => {
  const dateToMaxDepth: Record<string, { maxDepth: number; irrigationTime?: string }> = {};

  series.forEach((item) => {
    const depth = parseInt(item.depth, 10);
    item[detectionType].forEach((detectionGroup: any[]) => {
      detectionGroup.forEach((detection) => {
        const { date, irrigationTime } = detection;

        if (!dateToMaxDepth[date]) {
          // Initialize entry for new date
          dateToMaxDepth[date] = {
            maxDepth: depth,
            ...(irrigationTime && { irrigationTime }),
          };
        } else if (depth > dateToMaxDepth[date].maxDepth) {
          // Update max depth
          dateToMaxDepth[date] = {
            maxDepth: depth,
            ...(irrigationTime && { irrigationTime: dateToMaxDepth[date].irrigationTime }),
          };
        } else if (depth === dateToMaxDepth[date].maxDepth && irrigationTime) {
          // If same depth and it's irrigation detection, take earlier irrigation time
          const currentTime = new Date(`2000/01/01 ${irrigationTime}`);
          const existingTime = new Date(`2000/01/01 ${dateToMaxDepth[date].irrigationTime}`);

          if (currentTime < existingTime) {
            dateToMaxDepth[date].irrigationTime = irrigationTime;
          }
        }
      });
    });
  });

  // Transform the result into the desired format
  return Object.entries(dateToMaxDepth).map(([date, data]) => ({
    date: dayjs(date).format('MMM DD'),
    maxDepth: data.maxDepth,
    ...(data.irrigationTime && { irrigationTime: data.irrigationTime }),
  }));
};

const normalizeSeries = (
  maxDepthPerDate: Array<{ date: string; maxDepth: number }>,
  categories: string[],
) => {
  // Map maxDepthPerDate into a dictionary for quick lookup
  const dateToDepthMap: Record<string, number> = {};
  maxDepthPerDate.forEach(({ date, maxDepth }) => {
    dateToDepthMap[date] = maxDepth;
  });

  // Normalize the series based on categories
  const normalizedSeries = categories.map(
    (category) => dateToDepthMap[category] ?? null,
  );

  return normalizedSeries;
};

const prepareData = async (filters: {
  farmId: string;
  startDate: string;
  endDate: string;
  sectorId?: string;
  wiseconnZoneId?: string;
  irrigationThreshold?: number;
  rootsThreshold?: number;
}) => {
  // Ejecutar llamada API para datos de Soil Moisture
  const granularity = 'daily';
  const hourInterval = 1;

  const absoluteCategories = buildCategoriesFromDates(
    granularity,
    filters.startDate,
    filters.endDate,
  );

  const chartCategories = absoluteCategories.map((category) => dayjs(category).format('MMM DD'));

  const soilMoistureData = await getReadings({
    farmId: filters.farmId,
    startDate: filters.startDate,
    endDate: filters.endDate,
    wiseconnZoneId: filters.wiseconnZoneId,
    granularity: 'daily',
    sensorType: 'Soil Moisture',
    hourInterval,
  });

  // Agrupar datos por profundidad (sensorDepth)
  const groupedByDepth = soilMoistureData.reduce((acc: Record<string, any[]>, item: any) => {
    if (!acc[item.sensorDepth]) acc[item.sensorDepth] = [];
    acc[item.sensorDepth].push(item);
    return acc;
  }, {});

  // Deteccion de riego por dia
  const depthByDate = Object.entries(groupedByDepth).map(([depth, data]) => {
    const typedData = data as any[]; // Ahora 'data' es correctamente inferido como un array

    // Agrupar los datos por fecha
    const groupedByDate = typedData.reduce((acc: Record<string, any[]>, item: any) => {
      if (!acc[item.date]) acc[item.date] = [];
      acc[item.date].push(item);
      return acc;
    }, {});

    const { irrigation, roots } = detectDepths(
      groupedByDate,
      filters.irrigationThreshold ?? 4.00,
      filters.rootsThreshold ?? 0.30,
    );

    return {
      depth, // Incluye la profundidad para identificar cada serie
      irrigationDetection: [irrigation],
      rootsDetection: [roots],
    };
  });

  const maxIrrigationDepthPerDate = groupDepthByDate(depthByDate, 'irrigationDetection');
  const maxRootsDepthPerDate = groupDepthByDate(depthByDate, 'rootsDetection');

  const normalizedIrrigationSeries = normalizeSeries(maxIrrigationDepthPerDate, chartCategories);
  const normalizedRootsSeries = normalizeSeries(maxRootsDepthPerDate, chartCategories);

  const allDepths = Object.keys(groupedByDepth).map(Number).sort((a, b) => a - b);

  const result = {
    irrigationDetection: maxIrrigationDepthPerDate,
    rootsDetection: maxRootsDepthPerDate,
    irrigationSeries: normalizedIrrigationSeries,
    rootsSeries: normalizedRootsSeries,
    categories: chartCategories,
    yAxisCategories: allDepths,
    absoluteCategories,
  };

  return result;
};

export default prepareData;
