import dayjs from 'dayjs';
import {
  getReadings,
} from '../dataServices';
import { buildLinearCategories } from '../utils';

// To Do: Actualizar types
const prepareData = async (filters: {
  farmId: string;
  startDate: string;
  endDate: string;
  sectorId?: string;
  wiseconnZoneId?: string;
}) => {
  // Ejecutar llamada API para datos de Soil Moisture
  const soilMoistureData = await getReadings({
    ...filters,
    sensorType: 'Soil Moisture',
    granularity: 'all',
  });

  // Agrupar datos por profundidad (sensorDepth)
  const groupedByDepth = soilMoistureData.reduce((acc: Record<string, any[]>, item: any) => {
    if (!acc[item.sensorDepth]) acc[item.sensorDepth] = [];
    acc[item.sensorDepth].push(item);
    return acc;
  }, {});

  // Get dates from just the first depth group to use as categories
  const firstDepthGroup = Object.values(groupedByDepth)[0] as any[] || [];
  const categories = firstDepthGroup
    .map((item) => dayjs(item.dateTime).utc().format('YYYY-MM-DD HH:mm'))
    .sort();

  // Create series for the chart
  const series = Object.entries(groupedByDepth || {}).map(([depth, data]) => {
    const typedData = data as any[]; // Type assertion to tell TypeScript this is an array

    // Sort data by date
    const sortedData = typedData.sort((a, b) => {
      if (!a.dateTime || !b.dateTime) {
        return 0;
      }
      return dayjs(a.dateTime).diff(dayjs(b.dateTime));
    });

    // Map the data to extract just the value
    const formattedData = sortedData.map((reading) => reading.value);

    const initialDataDate = dayjs(sortedData[0].dateTime).utc().format('YYYY-MM-DD HH:mm');
    const finalDataDate = dayjs(sortedData[sortedData.length - 1].dateTime).utc().format('YYYY-MM-DD HH:mm');

    return {
      name: `${depth} cm`,
      depth: Number(depth),
      data: formattedData,
      initialDataDate,
      finalDataDate,
    };
  });

  // Add nulls to the left based on initialDataDate position
  const normalizedSeries = series.map((serie) => {
    const initialDateIndex = categories.findIndex(
      (category) => category === serie.initialDataDate,
    );
    if (initialDateIndex > 0) {
      const nullsToAdd = new Array(initialDateIndex).fill(null);
      serie.data = [...nullsToAdd, ...serie.data];
    }
    return serie;
  });

  const chartCategories = buildLinearCategories(categories);

  return {
    series: normalizedSeries,
    categories: chartCategories,
    absoluteCategories: categories,
    initialDataDate: series[0].initialDataDate,
    finalDataDate: series[series.length - 1].finalDataDate,
  };
};

export default prepareData;
