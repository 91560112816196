import dayjs from 'dayjs';
import { getReadings } from '../dataServices';
import {
  getGranularity,
  buildCategories,
  mapDates,
} from '../utils';

const prepareData = async (filters: {
  farmId: string;
  startDate: string;
  endDate: string;
  sectorId?: string;
  wiseconnZoneId?: string;
}) => {
  const granularity = getGranularity(filters.startDate, filters.endDate);

  // Execute API calls concurrently
  const [rainData] = await Promise.all([
    getReadings({
      ...filters,
      sensorType: 'Rain',
      granularity,
    }),
  ]);
  const rainDataMap = mapDates(
    rainData, granularity, 'cumulativeTotal', 'dateTime',
  );

  const normalizedRainData = Array.from(rainDataMap.values());

  // Calculate cumulative rain by adding previous values
  const cumulativeRain = normalizedRainData.reduce<number[]>((acc, currentValue: any, index) => {
    const previousSum = index > 0 ? acc[index - 1] : 0;
    acc.push(previousSum + currentValue);
    return acc;
  }, []);

  const absoluteCategories = Array.from(rainDataMap.keys()).map((date: any) => dayjs(date).format('YYYY-MM-DD HH:mm'));

  const chartCategories = buildCategories(
    granularity,
    absoluteCategories,
  );

  return {
    rain: normalizedRainData,
    cumulativeRain,
    categories: chartCategories,
    absoluteCategories,
  };
};

export default prepareData;
