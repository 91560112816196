import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  Collapse,
  ListItem,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import useStyles from './styles';

import { setCurrentSector } from '../../../actions/currentSectorActions';
import { NavItemProps, ReduxState } from '../../../types';

function NavItem({
  title = '',
  href,
  depth = 0,
  children,
  icon: Icon,
  className,
  open: openProp,
  info: Info,
  items,
  ...rest
}: NavItemProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const selectedFarm = useSelector((state: ReduxState) => state.accountFarm.selectedFarm);

  const hasActiveChild = () => {
    if (!items) return false;
    return items.some((item) => {
      const itemPath = item.href?.split('?')[0];
      return itemPath && location.pathname === itemPath;
    });
  };

  const [open, setOpen] = useState(openProp || hasActiveChild());

  const isActive = () => {
    if (!href) return false;
    const currentPath = location.pathname;
    const itemPath = href.split('?')[0];

    // Para items del submenú (los sectores específicos)
    if (depth > 0) {
      return currentPath === itemPath;
    }

    // Para menús padre
    return currentPath === itemPath || currentPath.startsWith(`${itemPath}/`);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClick = () => {
    if (!href) return;

    // Check if path is /sectores and extract ID
    const match = href.match(/^\/sectores\/([^?]+)/);
    if (match) {
      const sectorId = match[1];
      localStorage.setItem(`sector-${sectorId}-tab`, '1');
      const selectedSectorObj = selectedFarm?.sectors?.find((sector) => sector.id === sectorId);
      if (selectedSectorObj) {
        dispatch(setCurrentSector(selectedSectorObj));
      }
    }
    history.push(href);
  };

  let paddingLeft = 8;
  if (depth > 0) {
    paddingLeft = 16 + 8 * depth;
  }
  const style = { paddingLeft };

  if (children) {
    const isParentActive = hasActiveChild() || isActive();
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button
          className={clsx(
            classes.button,
            isParentActive && classes.activeParent,
          )}
          onClick={handleToggle}
          style={style}
        >
          {Icon && (
            <Icon
              className={clsx(classes.icon)}
              size="20"
            />
          )}
          <span className={classes.title}>
            {title}
          </span>
          {Info && <Info className={classes.info} />}
          {open ? (
            <ExpandLessIcon className={classes.expandIcon} />
          ) : (
            <ExpandMoreIcon className={classes.expandIcon} />
          )}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  const isItemActive = isActive();
  return (
    <ListItem
      className={clsx(classes.itemLeaf, className)}
      disableGutters
      key={title}
      {...rest}
    >
      <Button
        className={clsx(
          classes.buttonLeaf,
          `depth-${depth}`,
          depth === 0 && isItemActive && classes.activeParent,
          depth > 0 && isItemActive && classes.active,
        )}
        component="a"
        href={href}
        onClick={handleClick}
        style={style}
      >
        {Icon && (
          <Icon
            className={classes.icon}
            size="20"
          />
        )}
        <span className={classes.title}>
          {title}
        </span>
        {Info && <Info className={classes.info} />}
      </Button>
    </ListItem>
  );
}

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  info: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  items: PropTypes.array,
};

NavItem.defaultProps = {
  open: false,
};

export default NavItem;
