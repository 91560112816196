import React from 'react';
import {
  Box,
  CardContent,
  Card,
  SvgIcon,
  IconButton,
  CircularProgress,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CustomTable from '../../General/CustomTable';
import { formatDate } from '../utils';
import { CHART_LIST_UI } from '../constants';
// import CustomButton from '../../../components/General/CustomButton';
import useStyles from '../styles';
import { ConversationSchema, TimePointSchema } from '../../../../server/schemas/apiTypes';

function ConversationsTab({
  // modalState,
  setModalState,
  conversations,
  conversationsLoading,
}: {
  // modalState: { open: boolean, conversationId: string },
  setModalState: React.Dispatch<React.SetStateAction<{
    open: boolean,
    conversationId: string,
    timePointData: TimePointSchema,
  }>>,
  conversations: ConversationSchema[],
  conversationsLoading: boolean,
}) {
  const classes = useStyles();

  const conversationData = {
    count: conversations.length,
    values: conversations.map((conversation) => ({
      id: conversation.conversationId,
      updatedAt: formatDate(conversation.updatedAt),
      updatedBy: conversation.updatedBy,
      lastComment: conversation.lastComment,
      farm: conversation.farmName,
      sector: conversation.sectorName,
      chart: conversation.chart,
      viewConversation: (
        <>
          <IconButton onClick={() => setModalState({
            open: true,
            conversationId: conversation.conversationId,
            timePointData: conversation.timePoint,
          })}>
            <SvgIcon fontSize="small">
              <VisibilityIcon />
            </SvgIcon>
          </IconButton>
        </>
      ),
    })),
  };

  return (
    <Box className={classes.conversationTabContainer}>
      <Box className={classes.addCommentButton}>
        {/* TODO: Hacerlo responsive para que el boton no se superponga */}
        {/* <CustomButton
          buttonText='Agregar comentario al gráfico'
          color='blue'
          size='small'
          onClick={() => setModalState({ open: true, conversationId: '' })}
        /> */}
      </Box>
      <Card>
        <CardContent>
          {/* Arreglar este loading para que se vea bien */}
          {conversationsLoading ? (
            <CircularProgress size={'3rem'}/>
          ) : (
            <CustomTable
              headers={CHART_LIST_UI}
              rows={conversationData}
            />
          )}
        </CardContent>
      </Card>
    </Box>
  );
}

export default ConversationsTab;
