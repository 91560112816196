import { Middleware } from 'redux';
import { io, Socket } from 'socket.io-client';
import { addNotification } from '../../actions/notificationActions';

let SERVER_URL = '';

if (process.env.NODE_ENV === 'development') {
  SERVER_URL = 'http://localhost:8080';
} else if (process.env.NODE_ENV === 'production') {
  SERVER_URL = 'https://app.cdtecif.cl';
}

const webSocketMiddleware: Middleware = (store) => {
  let socket: Socket | null = null;

  return (next) => (action) => {
    switch (action.type) {
      case 'WEBSOCKET_CONNECT': {
        if (socket) {
          socket.disconnect();
        }

        const { userId } = action.payload;
        if (!userId) return next(action);

        console.log('🔄 Conectando WebSocket con userId:', userId);
        socket = io(SERVER_URL, {
          path: '/my-socket/',
          query: { userId },
          transports: ['websocket'],
        });
        console.log('SERVER_URL', SERVER_URL);

        socket.on('connect', () => {
          console.log(`✅ WebSocket conectado con userId: ${userId}`);
        });

        socket.on('notification', (data) => {
          console.log('📩 Nueva notificación:', data);
          store.dispatch(addNotification(data));
        });

        socket.on('disconnect', () => {
          console.log('❌ WebSocket desconectado');
        });

        break;
      }

      case 'WEBSOCKET_DISCONNECT':
        if (socket) {
          console.log('🔌 Desconectando WebSocket...');
          socket.disconnect();
          socket = null;
        }
        break;

      default:
        break;
    }

    return next(action);
  };
};

export default webSocketMiddleware;
