import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, CircularProgress } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import BaseHighchart from '../BaseHighchart';
import prepareData from './builder';
import { ReduxState } from '../../../types';
import ChartFilter from '../ChartFilter';
import { navigateToChart } from '../../../utils/helpers';
import { getWeekInterval, calculateDateRangeFromNotification } from '../utils';
import { saveChartData, getChartData } from '../../../services/indexDBService';
import { TimePointSchema, ConversationSchema } from '../../../requests/api/apiTypes';
import { ScatterPoint } from '../types';
import { IRRIGATION_NAME } from '../../../constants/graphs';
import { FetchError } from '../../../views/types';
import apiClient from '../../../requests/api/apiClient';
import ConversationModal from '../ConversationTab/conversationModal';
import { setStartDate, setEndDate } from '../../../actions/graphsFilterActions';
// To Do: Actualizar types
interface ChartDataType {
  series: { type: string; name: string; data: any[] }[];
  categories: string[];
  kc: number[];
  granularity: string;
  absoluteCategories?: string[];
}

function IrrigationChart({
  chartView,
  sectorId,
  farmId,
  hideLegend = false,
  hideXValues = false,
  hideYValues = false,
  wiseconnZoneId,
  height = 100,
  title = 'Gráfico de Riego',
  yAxisTitle = 'Kc / Et0 (mm) / Riego + Precipitaciones (mm)',
  externalModalState,
  externalSetModalState,
  externalConversations,
  externalSetConversations,
}: {
  chartView: 'dashboard' | 'graphs' | 'settings'
  sectorId: string
  farmId: string
  hideLegend?: boolean
  hideXValues?: boolean
  hideYValues?: boolean
  wiseconnZoneId: string | undefined
  height?: number | string
  title?: string
  yAxisTitle?: string
  externalModalState?: {
    open: boolean;
    conversationId: string;
    timePointData: TimePointSchema;
  };
  externalSetModalState?: React.Dispatch<React.SetStateAction<{
    open: boolean;
    conversationId: string;
    timePointData: TimePointSchema;
  }>>;
  externalConversations?: ConversationSchema[];
  externalSetConversations?: React.Dispatch<React.SetStateAction<ConversationSchema[]>>;
}) {
  const [chartData, setChartData] = useState<ChartDataType>({
    series: [],
    categories: [],
    kc: [],
    granularity: '',
    absoluteCategories: [],
  });
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dateParam = searchParams.get('date');
  const { startDate, endDate } = useSelector((state: ReduxState) => state.graphsFilter);
  const { selectedSector } = useSelector((state: ReduxState) => state.currentSector);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [internalModalState, setInternalModalState] = useState<{
    open: boolean;
    conversationId: string;
    timePointData: TimePointSchema;
  }>({
    open: false,
    conversationId: '',
    timePointData: {
      hour: '',
      day: '',
      month: '',
      year: '',
    },
  });
  const [internalConversations, setInternalConversations] = useState<ConversationSchema[]>([]);

  const modalState = externalModalState || internalModalState;
  const setModalState = externalSetModalState || setInternalModalState;
  const conversations = externalConversations || internalConversations;
  const setConversations = externalSetConversations || setInternalConversations;

  // Memoize filters to prevent unnecessary re-renders
  const preparedFilters = useMemo(() => ({
    farmId,
    startDate,
    endDate,
    ...(wiseconnZoneId && { wiseconnZoneId }),
  }), [farmId, startDate, endDate, wiseconnZoneId]);

  // Memoize series data creation
  const createSeriesData = useCallback((preparedData: any) => [
    {
      type: 'column',
      name: 'Riego (mm)',
      data: preparedData.irrigation,
      yAxis: 0,
      stack: 'water',
      color: '#536e7b',
    },
    {
      type: 'column',
      name: 'Precipitaciones (mm)',
      data: preparedData.rain,
      yAxis: 0,
      stack: 'water',
      color: '#35a0d7',
    },
    {
      type: 'column',
      name: 'ETo (mm)',
      data: preparedData.et0,
      yAxis: 0,
      stack: 'et0',
      color: '#FEBF23',
    },
    {
      type: 'line',
      name: 'ETo entre riegos',
      data: preparedData.cumulativeEt0,
      yAxis: 0,
      color: 'rgb(228 107 56)',
      visible: false,
    },
  ], []);

  // Memoize fetch data function
  const fetchData = useCallback(async () => {
    setIsLoading(true);

    try {
      const cachedData = await getChartData('irrigationChart', startDate, endDate, selectedSector?.id || sectorId);

      if (cachedData !== null && cachedData !== undefined) {
        // Actualizar el estado con datos cacheados
        const seriesData = createSeriesData(cachedData.data);
        setChartData({
          series: seriesData,
          categories: cachedData.data.categories,
          kc: cachedData.data.kc as number[],
          granularity: cachedData.data.granularity,
          absoluteCategories: cachedData.data.absoluteCategories,
        });
        setIsLoading(false);
        return;
      }

      const preparedData = await prepareData(preparedFilters);
      const seriesData = createSeriesData(preparedData);

      // Guardar en IndexDB
      await saveChartData('irrigationChart', startDate, endDate, selectedSector?.id || sectorId, preparedData);

      setChartData({
        series: seriesData,
        categories: preparedData.categories,
        kc: preparedData.kc as number[],
        granularity: preparedData.granularity,
        absoluteCategories: preparedData.absoluteCategories as string[],
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  }, [preparedFilters, createSeriesData, selectedSector, sectorId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (dateParam) {
      // Convert date from DD-MM-YYYY to YYYY-MM-DD
      const { rangeStartDate, rangeEndDate } = calculateDateRangeFromNotification(dateParam);
      // Update the date range in the store
      dispatch(setStartDate(rangeStartDate));
      dispatch(setEndDate(rangeEndDate));
    }
  }, [dateParam, dispatch]);

  const customTooltip = useMemo(() => ({
    shared: true,
    useHTML: true,
    style: {
      zIndex: 9999,
      position: 'absolute',
    },
    formatter(this: Highcharts.TooltipFormatterContextObject) {
      if (this.series.name === 'Comentarios') {
        const point = this.point as ScatterPoint;
        const conversationId = point.custom?.conversationId;
        if (!conversations || !conversationId) {
          return '<b>Último comentario:</b> Cargando...<br/>';
        }
        const conversation = conversations.find((conv) => conv.conversationId === conversationId);
        if (!conversation) {
          return '<b>Último comentario:</b> No se encontró la conversación<br/>';
        }
        const lastComment = conversation.lastComment || 'Sin comentarios';
        return `<b>Último comentario:</b> ${lastComment}<br/>
                <span style="color: gray; font-size: 10px">${dayjs(conversation.updatedAt).format('MMM DD, YYYY HH:mm')}</span>`;
      }
      const pointIndex = this.points?.[0].point.index;
      const kcValue = chartData.kc[pointIndex as number];
      const category = chartData.granularity === 'weekly'
        ? getWeekInterval(chartData.categories[pointIndex as number])
        : chartData.categories[pointIndex as number];
      const subHeader = `<div style="font-size: 12px; margin-bottom: 8px;">${category}</br></div>`;
      const header = '<div style="font-size: 14px; font-weight: bold; margin-bottom: 8px;">Resumen</div>';
      const points = this.points?.map(
        (point) => `<div style="margin: 4px 0"><span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${Number(point.y).toFixed(2)}</b></div>`,
      ).join('');
      const kcLine = `<div style="margin: 4px 0"><span style="color:#6FBF23">\u25CF</span> Kc: <b>${Number(kcValue).toFixed(2)}</b></div>`;
      return `<div style="padding: 8px; position: relative">${header}${subHeader}${points}${kcLine}</div>`;
    },
  }), [chartData.kc, chartData.categories, chartData.granularity, conversations]);

  const handleConfigClick = useCallback(() => {
    navigateToChart('riego', history, {
      farmId,
      sectorId,
      wiseconnZoneId: wiseconnZoneId || '',
    });
  }, [history, farmId, sectorId, wiseconnZoneId]);

  useEffect(() => {
    if (!externalConversations) {
      const loadConversations = async () => {
        try {
          const { data } = await apiClient.conversation.conversationDetail(
            sectorId,
            IRRIGATION_NAME,
          );
          setInternalConversations(data.conversations);
        } catch (err) {
          const { error } = err as FetchError;
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      };

      loadConversations();
    }
  }, [sectorId, externalConversations]);

  const chartConfig = {
    chart: {
      height,
    },
    xAxis: {
      categories: hideXValues ? [] : chartData.categories,
      plotBands: chartData.categories.map((_, index) => ({
        color: index % 2 === 0 ? 'rgba(0, 0, 0, 0.02)' : 'rgba(173, 216, 230, 0.3)',
        from: index - 0.5,
        to: index + 0.5,
      })),
      labels: {
        enabled: true,
        formatter(this: Highcharts.AxisLabelsFormatterContextObject): string {
          const index = this.pos;
          const kcValue = chartData.kc[index];
          const numKcValue = Number(kcValue);

          let backgroundColor;
          if (numKcValue < 0.63) {
            backgroundColor = '#BF3F23';
          } else if (numKcValue >= 0.63 && numKcValue <= 0.65) {
            backgroundColor = '#FEBF23';
          } else {
            backgroundColor = '#6FBF23';
          }

          return `<span style="
            background-color: ${backgroundColor}; 
            color: white;
            padding: 2px 6px;
            border-radius: 4px;
            font-weight: bold;
            position: relative;
            z-index: 1;
          ">${numKcValue.toFixed(2)}</span>`;
        },
        useHTML: true,
      },
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: false,
        },
      },
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: false,
        },
        pointWidth: 18,
      },
    },
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={height}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box>
        <BaseHighchart
          chartView={chartView}
          filter={chartView === 'settings' ? <ChartFilter sectorId={sectorId} /> : undefined}
          title={title}
          categories={chartData.categories}
          yAxisTitle={yAxisTitle}
          series={chartData.series as Highcharts.SeriesOptionsType[]}
          tooltip={customTooltip}
          chartConfig={chartConfig}
          hideYValues={hideYValues}
          legend={{ enabled: !hideLegend }}
          setModalState={setModalState}
          conversations={conversations}
          absoluteCategories={chartData.absoluteCategories}
          onConfigClick={handleConfigClick}
          height={height}
      />
      </Box>
        <ConversationModal
        modalState={modalState}
        setModalState={setModalState}
        farmId={farmId}
        sectorId={sectorId}
        chart={IRRIGATION_NAME}
        setConversationsFunction={setConversations}
      />
    </>
  );
}

export default IrrigationChart;
